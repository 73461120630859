import React, { useState } from 'react'
import { Button, Card, CardBody, Col, FormFeedback, Input, Label, Row } from 'reactstrap'
import Styles from '../homework/assets/Form.module.css'
import { toast } from 'react-toastify'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../../axiosInterceptor'

const AddWhatsAppQuery = () => {
    const location = useLocation();
    const WhatsAppQueryData = location.state;

    const [formSubmitted, setFormSubmitted] = useState(false)
    const navigator = useNavigate();

    const [data, setData] = useState({
        mobileNo: WhatsAppQueryData?.mobileNo || '',
        queryName: WhatsAppQueryData?.queryName || '',
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name) {
            setData({ ...data, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormSubmitted(true)
        if (!data.mobileNo || !data.queryName ) {
            return;
        }
        try {
            const response = await axiosInstance.post(`admin/whatsapp/createQuery`, {
                mobileNo: data.mobileNo,
                queryName: data.queryName
            });
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/whatsapp-query");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.put(`admin/whatsapp/updateQuery/${WhatsAppQueryData?._id}`, {
                mobileNo: data.mobileNo,
                queryName: data.queryName
            });
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/whatsapp-query");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    return (
        <div>
            <div className="mt-4 mb-4">
                <div className='row'>
                    <div className='col-sm-6'>
                        <h4 className="page-title">Add WhatsApp Query</h4>
                    </div>
                </div>
                <Card className='mt-4'>
                    <CardBody>
                        <Row>
                            <Col md="8">
                                <form className="ps-5 pe-5 pt-3" onSubmit={WhatsAppQueryData?._id ? handleUpdate : handleSubmit}>
                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Mobile No</Label>
                                        <div className="col-sm-7">
                                            <Input
                                                type="number"
                                                className="form-control"
                                                id="inputPassword"
                                                placeholder="Enter your mobile number"
                                                name='mobileNo'
                                                invalid={!WhatsAppQueryData && !data.mobileNo && formSubmitted}
                                                value={data.mobileNo}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <FormFeedback>Mobile number is required</FormFeedback>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Query Name </Label>
                                        <div className="col-sm-7">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="inputPassword"
                                                name='queryName'
                                                placeholder="Enter your query name"
                                                invalid={!WhatsAppQueryData && !data.queryName && formSubmitted}
                                                value={data.queryName}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <FormFeedback>Query name is required</FormFeedback>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            <b className="ms-2"></b>
                                        </Label>
                                        <div className="col-sm-7 d-flex">
                                            <div className={`${Styles.submit_button}`}>
                                                <Button type='submit'>{WhatsAppQueryData?._id ? "Update" : "Save"}</Button>
                                            </div>
                                            <div className={`${Styles.cancel_button} me-4 mx-2`}>
                                                <Link to='/whatsapp-query'> <Button className={`${Styles.cancel_button}`}>Cancel</Button></Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default AddWhatsAppQuery