import React, { useState } from "react";
import { Button, Input, Label } from "reactstrap";
import Styles from "../homework/assets/Form.module.css";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../axiosInterceptor";

const PaymentUpdates = () => {
  const location = useLocation();
  const [editData, setEditData] = useState(location.state);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormDatapay((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [formDatapay, setFormDatapay] = useState({
    payeditingEnabled: false,
    questionId: editData?._id || "",
  });

  const fetchPaymentRefund = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      const response = await axiosInstance.post(
        `admin/wallet/getCategoryWisePaymentRefund`,
        {
          paymentSection: "AssignmentHelp",
          questionId: formDatapay.questionId,
          refundAmount: formDatapay.refundAmount,
          refundTxnId: formDatapay.refundTxnId,
        }
      );

      if (response.data.isSuccess) {
        toast.success(response.data.message);
        // resetForm();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="mt-2">
        <div className="bg-white">
          <div className="d-flex justify-content-between mt-5">
            <div className="ps-5 pt-2">
              <span className={` ${Styles.student_corner_text}`}>
                Payment Refund
              </span>
            </div>
          </div>
          <form className="pt-3" onSubmit={fetchPaymentRefund}>
            <div className="ps-5 pe-5">
              <div className="form-group row mb-4 mt-4">
                <Label for="studentAmount" className="col-sm-3 col-form-label">
                  Payment Received<b className="ms-2">:</b>
                </Label>
                <div className="col-sm-6 d-flex align-items-center">
                  <span className={`${Styles.plandoll}`}>$</span>
                  <Input
                    type="text"
                    className="form-control"
                    id="studentAmount"
                    placeholder=""
                    defaultValue={editData?.studentAmount?.toFixed(2)}
                    disabled={
                      (editData?.studentPaymentStatus !== "Paid" &&
                        editData?.studentPaymentStatus !== "Completed") ||
                      editData?.studentPaymentStatus === "Refund"
                    }
                  />
                </div>
              </div>
              <div className="form-group row mb-4 mt-4">
                <Label for="refundAmount" className="col-sm-3 col-form-label">
                  Refund<b className="ms-2">:</b>
                </Label>
                <div className="col-sm-6 d-flex align-items-center">
                  <span className={`${Styles.plandoll}`}>$</span>
                  <Input
                    type="text"
                    className="form-control"
                    id="refundAmount"
                    name="refundAmount"
                    placeholder="Enter refund amount"
                    defaultValue={editData?.refundAmount?.toFixed(2)}
                    onChange={handleInputChange}
                    disabled={
                      (editData?.studentPaymentStatus !== "Paid" &&
                        editData?.studentPaymentStatus !== "Completed") ||
                      editData?.studentPaymentStatus === "Refund"
                    }
                  />
                  {/* Proceed button */}
                </div>
              </div>
              <div className="form-group row mb-4 mt-4">
                <Label for="refundTxnId" className="col-sm-3 col-form-label">
                  Refund Transaction ID<b className="ms-2">:</b>
                </Label>
                <div className="col-sm-6">
                  <Input
                    type="text"
                    className="form-control"
                    id="refundTxnId"
                    name="refundTxnId"
                    placeholder="Transaction id"
                    defaultValue={editData?.refundTxnId}
                    onChange={handleInputChange}
                    disabled={
                      (editData?.studentPaymentStatus !== "Paid" &&
                        editData?.studentPaymentStatus !== "Completed") ||
                      editData?.studentPaymentStatus === "Refund"
                    }
                  />
                </div>
              </div>
            </div>
            <div
              className={`d-flex justify-content-around ${Styles.quesion_color} p-3 mt-4`}
            >
              <div className="d-flex">
                <div className={`${Styles.cancel_button} me-4`}>
                  <Button
                    type="button"
                    className={`${Styles.cancel_button}`}
                    onClick={() =>
                      setFormDatapay((prevState) => ({
                        ...prevState,
                        payeditingEnabled: false,
                      }))
                    }
                    disabled={
                      (editData?.studentPaymentStatus !== "Paid" &&
                        editData?.studentPaymentStatus !== "Completed") ||
                      editData?.studentPaymentStatus === "Refund"
                    }
                  >
                    Cancel
                  </Button>
                </div>
                <div className={`${Styles.submit_button}`}>
                  <Button
                    type="submit"
                    disabled={
                      (editData?.studentPaymentStatus !== "Paid" &&
                        editData?.studentPaymentStatus !== "Completed") ||
                      editData?.studentPaymentStatus === "Refund"
                    }
                  >
                    {isLoading ? "Submitting..." : "Submit"}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PaymentUpdates;
