import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, FormGroup, Input, InputGroup, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap';
import Styles from '../homework/assets/Form.module.css'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// import pluscircle from '../homework/assets/images/plus circle_.png'
import grp713 from '../homework/assets/images/Group 713.png'
import axiosInstance from '../../axiosInterceptor';
import { dateFormat } from '../../dateFormat';
import { FaSearch } from 'react-icons/fa';
import { ImageLink } from '../../constants';

const Category = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const perPage = 10;
    const [categoryData, setCategoryData] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const navigator = useNavigate();

    const fetchData = async (page = 1) => {
        try {
            setLoading(true);
            const response = await axiosInstance.post(`admin/category/getCategories`,
                {
                    search: searchTerm,
                    perPage: perPage,
                    pageNo: page,
                });
            const { data, totalPages } = response.data;
            setTotalPages(totalPages);
            setCurrentPage(page);
            setCategoryData(data);

        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handlePageClick = (page) => {
        if (page >= 1 && page <= totalPages) {
            fetchData(page);
        }
    };

    return (
        <>
            <div>
                <div className="my-2">
                    <div className='row mb-2'>
                        <div className='d-flex justify-content-between'>
                            <div>
                                <h4 className="page-title">Category</h4>
                            </div>
                            {/* <div>
                                <Link to='/addcategory'>
                                    <Button className="action-button add-from-new" >
                                        <img src={pluscircle} alt="plus" width={20} className='ADD_code' />
                                        <span className=""> Add </span>
                                    </Button>
                                </Link>
                            </div> */}
                        </div>
                    </div>

                    <Card>
                        <CardBody>
                            <Row className='Extra_padding'>
                                <InputGroup className='search_input_icon'>
                                    <Input
                                        type="text"
                                        id="fname"
                                        placeholder="Search.."
                                        name="searchTerm"
                                        className='border_none_icon'
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                fetchData();
                                            }
                                        }}
                                    />
                                    <div className="input-group-append" >
                                        <span className="input-group-text wallte" onClick={() => fetchData()} >
                                            <FaSearch />
                                        </span>
                                    </div>
                                </InputGroup>
                            </Row>
                            {loading ? (
                                <div className="text-center mt-4">
                                    <p>Loading...</p>
                                </div>
                            ) : categoryData?.length > 0 ?
                                <div className="table-responsive">
                                    <Table bordered responsive className="mt-4 text-center" style={{ fontSize: '0.8rem' }}>
                                        <thead>
                                            <tr>
                                                <th>Sr. No</th>
                                                <th>Category Name</th>
                                                <th>Icon</th>
                                                <th>Updated At</th>
                                                <th>isActive</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {categoryData?.map((code, index) => {
                                                const serialNumber = (currentPage - 1) * perPage + index + 1;
                                                return (
                                                    <tr key={index}>
                                                        <th scope="row">{serialNumber}</th>
                                                        <td>{code.name}</td>
                                                        <td style={{ width: '13%' }}>
                                                            <img src={ImageLink(code.icon)} style={{ width: '50%' }} alt='Icon' />
                                                        </td>
                                                        <td>{dateFormat(code.updatedAt)}</td>
                                                        <td>
                                                            <FormGroup switch>
                                                                <Input
                                                                    type="switch"
                                                                    className={`${Styles.form_check_input} float-none`}
                                                                    checked={code.isActive}
                                                                    onClick={() => {
                                                                        setIsActive(isActive);
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </td>

                                                        <td className='d-flex justify-content-center'>
                                                            <div className={`${Styles.edit_code}`}>
                                                                <Button className={`${Styles.edit_code}`} onClick={() => { navigator('/addcategory', { state: code }) }}>
                                                                    <img
                                                                        src={grp713}
                                                                        alt="error"
                                                                        width={20}
                                                                    />
                                                                    Edit
                                                                </Button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                                : (
                                    <div className="text-center mt-4">
                                        <p>No data found.</p>
                                    </div>
                                )}

                            <div className="float-end">
                                <Pagination>
                                    <PaginationItem disabled={currentPage === 1}>
                                        <PaginationLink previous onClick={() => handlePageClick(currentPage - 1)} />
                                    </PaginationItem>
                                    {[...Array(totalPages)].map((_, i) => (
                                        <PaginationItem key={i} active={i + 1 === currentPage}>
                                            <PaginationLink onClick={() => handlePageClick(i + 1)}>{i + 1}</PaginationLink>
                                        </PaginationItem>
                                    ))}
                                    <PaginationItem disabled={currentPage === totalPages}>
                                        <PaginationLink next onClick={() => handlePageClick(currentPage + 1)} />
                                    </PaginationItem>
                                </Pagination>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default Category