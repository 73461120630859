import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap'
import Styles from '../homework/assets/Form.module.css'
import { toast } from 'react-toastify'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../../axiosInterceptor'

const AddPostingStreak = () => {
  const location = useLocation();
  const editData = location.state;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const navigator = useNavigate();

  const [data, setData] = useState({
    categoryId: editData?.categoryId?._id || "",
    streak: editData?.streak || '',
    rewards: editData?.rewards || '',
    content: editData?.content || '',
    countPost: editData?.countPost || '',
  })

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true)
    if (!data.streak || !data.rewards) {
      return;
    }
    try {
      const response = await axiosInstance.post(`admin/postingStreak/createStreak`, {
        content:data.content,
        streak: data.streak,
        rewards: data.rewards,
        countPost: data.countPost,
        categoryId:data.categoryId
      });
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/posting-streak");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again!!');
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.put(`admin/postingStreak/updateStreak/${editData?._id}`, {
        content:data.content,
        streak: data.streak,
        rewards: data.rewards,
        countPost: data.countPost,
        categoryId:data.categoryId
      });
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/posting-streak");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again!!');
    }
  };

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`admin/category/getAllCategory`);
      const { data } = response.data;
      setCategoryData(data);
    } catch (error) {
      toast.error('Something went wrong. Please try again!!');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="mt-4 mb-4">
        <div className='row'>
          <div className='col-sm-6'>
            <h4 className="page-title">Add Posting Streak</h4>
          </div>
        </div>
        <Card className='mt-4'>
          <CardBody>
            <Row>
              <Col md="8">
                <form className="ps-5 pe-5 pt-3" onSubmit={editData?._id ? handleUpdate : handleSubmit}>
                  <div className="form-group row mb-2">
                    <Label for="inputPassword" className="col-sm-4 col-form-label">
                      Category
                    </Label>
                    <div className="col-sm-7">
                      <FormGroup>
                        <Input
                          id="exampleSelect"
                          name="categoryId"
                          type="select"
                          invalid={!editData && !data.categoryId && formSubmitted}
                          defaultValue={editData ? (editData?.categoryId._id === data.categoryId ? data.categoryId : '') : ''}
                          onChange={(e) => handleChange(e)}
                        >
                          <option value="">
                            {editData ? (editData?.categoryId._id === data.categoryId ? categoryData.find(subcat => subcat._id === data.categoryId)?.name : '--- Select category ---') : '--- Select category ---'}
                          </option>
                          {categoryData?.map(subcat => (
                            <option key={subcat._id} value={subcat._id}>{subcat.name}</option>
                          ))}
                        </Input>
                        <FormFeedback>Category is required</FormFeedback>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="form-group row mb-4">
                    <Label for="inputPassword" className="col-sm-4 col-form-label">
                      Criteria  Streak
                    </Label>
                    <div className="col-sm-7">
                      <Input
                        type="text"
                        className="form-control"
                        id="inputPassword"
                        placeholder="Enter your criteria streak"
                        invalid={!editData && !data.streak && formSubmitted}
                        name='streak'
                        value={data.streak}
                        onChange={(e) => handleChange(e)}
                      />
                      <FormFeedback>Criteria  Streak is required</FormFeedback>
                    </div>
                  </div>
                  <div className="form-group row mb-4">
                    <Label for="inputPassword1" className="col-sm-4 col-form-label">
                      Count Post
                    </Label>
                    <div className="col-sm-7">
                      <Input
                        type="number"
                        className="form-control"
                        id="inputPassword1"
                        placeholder="Enter your count post"
                        invalid={!editData && !data.countPost && formSubmitted}
                        name='countPost'
                        value={data.countPost}
                        onChange={(e) => handleChange(e)}
                      />
                      <FormFeedback>Count post is required</FormFeedback>
                    </div>
                  </div>          
                  <div className="form-group row mb-4">
                    <Label for="inputPassword" className="col-sm-4 col-form-label">
                      Rewards
                    </Label>
                    <div className="col-sm-7">
                      <Input
                        type="text"
                        className="form-control"
                        id="inputPassword"
                        placeholder="Enter your rewards "
                        invalid={!editData && !data.rewards && formSubmitted}
                        name='rewards'
                        value={data.rewards}
                        onChange={(e) => handleChange(e)}
                      />
                      <FormFeedback>Rewards is required</FormFeedback>
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <Label for="inputPassword" className="col-sm-4 col-form-label">
                      Content
                    </Label>
                    <div className="col-sm-7">
                      <Input
                        type="textarea"
                        className="form-control"
                        id="inputPassword"
                        placeholder="Enter your content "
                        invalid={!editData && !data.content && formSubmitted}
                        name='content'
                        value={data.content}
                        onChange={(e) => handleChange(e)}
                        rows={5}
                      />
                      <FormFeedback>Content is required</FormFeedback>
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <Label for="inputPassword" className="col-sm-4 col-form-label">
                      <b className="ms-2"></b>
                    </Label>
                    <div className="col-sm-7 d-flex">
                      <div className={`${Styles.submit_button}`}>
                        <Button type='submit'>{editData?._id ? "Update" : "Save"}</Button>
                      </div>
                      <div className={`${Styles.cancel_button} me-4 mx-2`}>
                        <Link to='/posting-streak'><Button className={`${Styles.cancel_button}`}>Cancel</Button></Link>
                      </div>
                    </div>
                  </div>
                </form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default AddPostingStreak;