import React, { useEffect, useState } from "react";
import Styles from "./assets/Form.module.css";
import { Button, Input, Label } from "reactstrap";
import { useLocation } from "react-router-dom";
import axiosInstance from "../../axiosInterceptor";
import { toast } from "react-toastify";
import CommonEditor from "../masters/CommonEditor";

const ForAdmin = () => {
  const location = useLocation();
  const editData = location.state;

  const adminId = localStorage.getItem("_id");
  const [isLoading, setIsLoading] = useState(false);

  const [formState, setFormState] = useState({
    editingEnabled: false,
    anseditingEnabled: false,
    payeditingEnabled: false,
    question: editData?.question || "",
    answer: editData?.answer || "",
    subjects: [],
    selectedSubject: editData?.subjectId._id || "",
    questionTypes: [],
    selectedQuestionType: editData?.subCategoryId._id || "",
    questionId: editData?._id || "",
    rating: editData?.rating || "",
    estimatedPrice: editData?.estimatedPrice || "",
    subjectId: editData?.subjectId._id || "",
    subCategoryId: editData?.subCategoryId._id || "",
    categoryId: editData?.categoryId._id || "",
    remark: editData?.remark || "",
    questionStatus: editData?.questionStatus || "",
    studentPaymentStatus: editData?.studentPaymentStatus || "",
  });

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const response = await axiosInstance.get(`admin/subject/getAllSubject`);
        if (response.data.isSuccess) {
          setFormState((prevState) => ({
            ...prevState,
            subjects: response.data.data,
          }));
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again!!");
      }
    };

    fetchSubjects();
  }, []);

  useEffect(() => {
    const fetchQuestionTypes = async () => {
      try {
        const response = await axiosInstance.get(
          `admin/category/getAllCategory`
        );
        if (response.data.isSuccess) {
          const categories = response.data.data;
          const homeCategory = categories.find(
            (category) => category.name === "Homework Help"
          );
          if (homeCategory) {
            const categoryId = homeCategory._id;
            await fetchSubcategories(categoryId);
          } else {
            toast.error("No category named 'home' found.");
          }
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again!!");
      }
    };

    const fetchSubcategories = async (categoryId) => {
      try {
        const response = await axiosInstance.get(
          `admin/subcategory/getCategoryWiseSubcategory/${categoryId}`
        );
        if (response.data.isSuccess) {
          setFormState((prevState) => ({
            ...prevState,
            questionTypes: response.data.subCategory,
          }));
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again!!");
      }
    };

    fetchQuestionTypes();
  }, []);

  const handleUpdate = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      const response = await axiosInstance.put(
        `admin/homeworkhelp/updateAnswer/${editData._id}`,
        {
          answer: formState.answer,
          adminId: adminId,
        }
      );

      if (response.data.isSuccess) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubjectChange = (event) => {
    const value = event.target.value;
    setFormState((prevState) => ({
      ...prevState,
      selectedSubject: value,
    }));
  };

  const handleRating = (e) => {
    const { value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      rating: value,
    }));
  };

  const handleQuestionTypeChange = (event) => {
    const value = event.target.value;
    setFormState((prevState) => ({
      ...prevState,
      selectedQuestionType: value,
    }));
  };

  return (
    <div>
      <div className="d-flex justify-content-between mt-4">
        <div className="ps-5 pt-2">
          <span className={`${Styles.student_corner_text}`}>Admin Corner</span>
        </div>
      </div>
      <form className="pt-3" onSubmit={handleUpdate}>
        <div className="ps-5 pe-5">
          <div className="form-group row mb-4">
            <Label for="inputPassword" className="col-sm-3 col-form-label">
              Question ID<b className="ms-2">:</b>
            </Label>
            <div className="col-sm-6">
              <Input
                type="text"
                className="form-control"
                id="inputPassword"
                placeholder=""
                defaultValue={editData?.displayId}
                disabled
              />
            </div>
          </div>
          <div className="form-group row mb-4">
            <Label for="inputPassword" className="col-sm-3 col-form-label">
              Admin Name<b className="ms-2">:</b>
            </Label>
            <div className="col-sm-6">
              <Input
                type="text"
                className="form-control"
                id="inputPassword"
                placeholder=""
                defaultValue={editData?.answerUserName}
                disabled
              />
            </div>
          </div>
          <div className="form-group row mb-4">
            <Label for="inputPassword" className="col-sm-3 col-form-label">
              Admin Answer<b className="ms-2">:</b>
            </Label>
            <div className="col-sm-9">
              <CommonEditor
                value={formState.answer}
                placeholder="Enter content ..."
                onChange={(value) =>
                  setFormState((prevState) => ({ ...prevState, answer: value }))
                }
                readOnly={editData.studentPaymentStatus === "Refund"}
                className={`${Styles.h_200}`}
              />
            </div>
          </div>
          <div className="form-group row mb-4 mt-5 pt-3">
            <Label for="inputPassword" className="col-sm-3 col-form-label">
              Subject<b className="ms-2">:</b>
            </Label>
            <div className="col-sm-6">
              <Input
                id="exampleSelect"
                name="select"
                type="select"
                onChange={handleSubjectChange}
                value={formState.selectedSubject}
                disabled={
                  editData.studentPaymentStatus === "Completed" ||
                  editData.studentPaymentStatus === "Refund"
                }
              >
                <option value="">
                  {editData
                    ? editData?.subjectId
                      ? editData?.subjectId.questionSubject
                      : "Please select subject"
                    : "Please select subject"}
                </option>
                {formState.subjects?.map((subject) => (
                  <option key={subject._id} value={subject._id}>
                    {subject.questionSubject}
                  </option>
                ))}
              </Input>
            </div>
          </div>
          <div className="form-group row mb-4">
            <Label for="inputPassword" className="col-sm-3 col-form-label">
              Question Type<b className="ms-2">:</b>
            </Label>
            <div className="col-sm-6">
              <Input
                id="exampleSelect"
                name="select"
                type="select"
                onChange={handleQuestionTypeChange}
                value={formState.selectedQuestionType}
                disabled={
                  editData.studentPaymentStatus === "Completed" ||
                  editData.studentPaymentStatus === "Refund"
                }
              >
                <option value="">
                  {editData
                    ? editData?.subCategoryId
                      ? editData?.subCategoryId.name
                      : "Please select type"
                    : "Please select type"}
                </option>
                {formState.questionTypes?.map((questionType) => (
                  <option key={questionType._id} value={questionType._id}>
                    {questionType.name}
                  </option>
                ))}
              </Input>
            </div>
          </div>
          <div className="form-group row mb-4">
            <Label for="inputPassword" className="col-sm-3 col-form-label">
              Payment<b className="ms-2">:</b>
            </Label>
            <div className="col-sm-6">
              <Input
                type="text"
                className="form-control"
                id="inputPassword"
                placeholder=""
                defaultValue={editData?.studentAmount?.toFixed(2)}
                disabled
              />
            </div>
          </div>
          <div className="form-group row mb-4">
            <Label for="inputPassword" className="col-sm-3 col-form-label">
              Payment Status<b className="ms-2">:</b>
            </Label>
            <div className="col-sm-6">
              <Input
                type="text"
                className="form-control"
                id="inputPassword"
                placeholder=""
                defaultValue={editData?.studentPaymentStatus}
                disabled
              />
            </div>
          </div>
          <div className="form-group row mb-4">
            <Label for="inputPassword" className="col-sm-3 col-form-label">
              Rating<b className="ms-2">:</b>
            </Label>
            <div className="col-sm-6">
              <Input
                type="select"
                className="form-control"
                id="inputPassword"
                placeholder=""
                value={formState.rating}
                onChange={handleRating}
                disabled={editData.studentPaymentStatus === "Refund"}
              >
                <option value="Upvote">Upvote</option>
                <option value="Downvote">Downvote</option>
              </Input>
            </div>
          </div>
        </div>
        <div
          className={`d-flex justify-content-around ${Styles.quesion_color} p-3`}
        >
          <div className="d-flex">
            <div className={`${Styles.cancel_button} me-4`}>
              <Button
                type="button"
                className={`${Styles.cancel_button}`}
                onClick={() =>
                  setFormState((prevState) => ({
                    ...prevState,
                    anseditingEnabled: false,
                  }))
                }
                disabled={editData.studentPaymentStatus === "Refund"}
              >
                Cancel
              </Button>
            </div>
            <div className={`${Styles.submit_button}`}>
              <Button
                type="submit"
                disabled={editData.studentPaymentStatus === "Refund"}
              >
                {isLoading ? "Submitting..." : "Submit"}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForAdmin;
