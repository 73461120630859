import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap'
import Styles from '../homework/assets/Form.module.css'
import { toast } from 'react-toastify'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../../axiosInterceptor'

const AddQuestionPrice = () => {
    const location = useLocation();
    const editData = location.state;
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [categoryData, setCategoryData] = useState([]);
    const [subCategory, setSubcategory] = useState([]);
    const navigator = useNavigate();

    const [data, setData] = useState({
        categoryId: editData?.categoryId._id || '',
        subCategoryId: editData?.subCategoryId._id || '',
        tutorPrice: editData?.tutorPrice || '',
        studentPrice: editData?.studentPrice || '',
    })

    const handleChange = async (e) => {
        const { name, value } = e.target;
        if (name === 'categoryId') {
            setData({ ...data, [name]: value, subCategoryId: '' }); // Reset subcategory when category changes
            if (value) {
                try {
                    const response = await axiosInstance.get(`admin/subcategory/getCategoryWiseSubcategory/${value}`);
                    if (response.data.isSuccess) {
                        setSubcategory(response.data.subCategory);
                    } else {
                        toast.error(response.data.message);
                    }
                } catch (error) {
                    toast.error('Something went wrong. Please try again!!');
                }
            }
        } else {
            setData({ ...data, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormSubmitted(true)
        if (!data.tutorPrice) {
            return;
        }
        try {
            const response = await axiosInstance.post(`admin/homeworkPrice/addPrice`, {
                categoryId: data.categoryId,
                subCategoryId: data.subCategoryId,
                tutorPrice: data.tutorPrice,
                studentPrice: data.studentPrice
            });
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/question-price");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.put(`admin/homeworkPrice/updatePrice/${editData?._id}`, {
                categoryId: data.categoryId,
                subCategoryId: data.subCategoryId,
                tutorPrice: data.tutorPrice,
                studentPrice: data.studentPrice
            });
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/question-price");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    const fetchCategoryData = async (page = 1) => {
        try {
            const response = await axiosInstance.get(
                `admin/category/getAllCategory`,
            );
            const { data } = response.data;
            setCategoryData(data);
        } catch (error) {
            console.error(error);
            toast.error('Something went wrong. Please try again!!');
        }
    };

    useEffect(() => {
        fetchCategoryData();
    }, []);

    return (
        <div>
            <div className="mt-4 mb-4">
                <div className='row'>
                    <div className='col-sm-6'>
                        <h4 className="page-title">Add Question Price</h4>
                    </div>
                </div>
                <Card className='mt-4'>
                    <CardBody>
                        <Row>
                            <Col md="8">
                                <form className="ps-5 pe-5 pt-3" onSubmit={editData?._id ? handleUpdate : handleSubmit}>
                                    <div className="form-group row mb-2">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Category
                                        </Label>
                                        <div className="col-sm-8">
                                            <FormGroup>
                                                <Input
                                                    id="exampleSelect"
                                                    name="categoryId"
                                                    type="select"
                                                    invalid={!editData && !data.categoryId && formSubmitted}
                                                    defaultValue={editData ? (editData?.categoryId._id === data.categoryId ? data.categoryId : '') : ''}
                                                    onChange={(e) => handleChange(e)}
                                                >
                                                    <option value="">
                                                        {editData ? (editData?.categoryId._id === data.categoryId ? categoryData.find(subcat => subcat._id === data.categoryId)?.name : '--- Select category ---') : '--- Select category ---'}
                                                    </option>
                                                    {categoryData?.map(subcat => (
                                                        <option key={subcat._id} value={subcat._id}>{subcat.name}</option>
                                                    ))}
                                                </Input>
                                                <FormFeedback>Category is required</FormFeedback>
                                            </FormGroup>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-2">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Sub Category
                                        </Label>
                                        <div className="col-sm-8">
                                            <FormGroup>
                                                <Input
                                                    id="exampleSelect"
                                                    name="subCategoryId"
                                                    type="select"
                                                    invalid={!data.subCategoryId && formSubmitted}
                                                    defaultValue={editData ? editData?.subCategoryId._id : ''}
                                                    onChange={(e) => handleChange(e)}
                                                >
                                                    <option value="">
                                                        {
                                                            data.subCategoryId ? editData?.subCategoryId.name : '--- Select subcategory ---'
                                                        }
                                                    </option>
                                                    {subCategory?.map(subcat => (
                                                        <option key={subcat._id} value={subcat._id}>{subcat.name}</option>
                                                    ))}
                                                </Input>
                                                <FormFeedback>Sub category is required</FormFeedback>
                                            </FormGroup>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Student Price
                                        </Label>
                                        <div className="col-sm-8">
                                        <div className="input-group from-control">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">$</span>
                                                </div>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="inputPassword"
                                                placeholder="Enter your student price  "
                                                invalid={!editData && !data.studentPrice && formSubmitted}
                                                name='studentPrice'
                                                value={data.studentPrice}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <FormFeedback><p>Student price is required</p></FormFeedback>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Tutor Price
                                        </Label>
                                        <div className="col-sm-8 d-flex">
                                        <div className="input-group from-control">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">$</span>
                                                </div>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="inputPassword"
                                                placeholder="Enter your tutor price  "
                                                invalid={!editData && !data.tutorPrice && formSubmitted}
                                                name='tutorPrice'
                                                value={data.tutorPrice}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <FormFeedback>Tutor price is required</FormFeedback>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            <b className="ms-2"></b>
                                        </Label>
                                        <div className="col-sm-7 d-flex">
                                            <div className={`${Styles.submit_button}`}>
                                                <Button type='submit'>{editData?._id ? "Update" : "Save"}</Button>
                                            </div>
                                            <div className={`${Styles.cancel_button} me-4 mx-2`}>
                                                <Link to='/question-price'> <Button className={`${Styles.cancel_button}`}>Cancel</Button></Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default AddQuestionPrice;