import React, { useEffect, useState } from "react";
import Styles from "./assets/Form.module.css";

import { Button, Input, Label } from "reactstrap";
import { useLocation } from "react-router-dom";
import axiosInstance from "../../axiosInterceptor";
import { toast } from "react-toastify";
import { dateFormat } from "../../dateFormat";
import CancelModal from "../CancelModal";
import ForPayment from "./ForPayment";
import ForAdmin from "./ForAdmin";
import CommonEditor from "../masters/CommonEditor";

export default function Form() {
  const [paymentStatus, setPaymentStatus] = useState([]);

  const location = useLocation();
  const editData = location.state;

  const [isLoading, setIsLoading] = useState(false);

  const [formState, setFormState] = useState({
    editingEnabled: false,
    anseditingEnabled: false,
    payeditingEnabled: false,
    question: editData?.question || "",
    answer: editData?.answer || "",
    subjects: [],
    selectedSubject: editData?.subjectId._id || "",
    questionTypes: [],
    selectedQuestionType: editData?.subCategoryId._id || "",
    questionId: editData?._id || "",
    rating: editData?.rating || "",
    estimatedPrice: editData?.estimatedPrice || "",
    discountPrice: editData?.discountPrice || 0,
    subjectId: editData?.subjectId._id || "",
    subCategoryId: editData?.subCategoryId._id || "",
    categoryId: editData?.categoryId._id || "",
    remark: editData?.remark || "",
    questionStatus: editData?.questionStatus || "",
    studentPaymentStatus: editData?.studentPaymentStatus || "",
    paymentId: editData?.paymentId || "",
    paymentMethod: editData?.paymentMethod || "",
  });

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const response = await axiosInstance.get(`admin/subject/getAllSubject`);
        if (response.data.isSuccess) {
          setFormState((prevState) => ({
            ...prevState,
            subjects: response.data.data,
          }));
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again!!");
      }
    };

    fetchSubjects();
  }, []);

  useEffect(() => {
    const fetchQuestionTypes = async () => {
      try {
        const response = await axiosInstance.get(
          `admin/category/getAllCategory`
        );
        if (response.data.isSuccess) {
          const categories = response.data.data;
          const homeCategory = categories.find(
            (category) => category.name === "Homework Help"
          );
          if (homeCategory) {
            const categoryId = homeCategory._id;
            await fetchSubcategories(categoryId);
          } else {
            toast.error("No category named 'home' found.");
          }
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again!!");
      }
    };

    const fetchSubcategories = async (categoryId) => {
      try {
        const response = await axiosInstance.get(
          `admin/subcategory/getCategoryWiseSubcategory/${categoryId}`
        );
        if (response.data.isSuccess) {
          setFormState((prevState) => ({
            ...prevState,
            questionTypes: response.data.subCategory,
          }));
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again!!");
      }
    };

    fetchQuestionTypes();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      const response = await axiosInstance.put(
        `admin/homeworkhelp/updateQuestion/${editData._id}`,
        {
          question: formState.question,
          rating: formState.rating,
          estimatedPrice: formState.estimatedPrice,
          discountPrice: formState.discountPrice,
          subjectId: formState.subjectId,
          subCategoryId: formState.subCategoryId,
          categoryId: formState.categoryId,
          remark: formState.remark,
          questionStatus: formState.questionStatus,
          studentPaymentStatus: formState.studentPaymentStatus,
          paymentId: formState.paymentId,
          paymentMethod: formState.paymentMethod,
        }
      );

      if (response.data.isSuccess) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      // Reset isLoading to false whether submission succeeds or fails
      setIsLoading(false);
    }
  };

  const handleQuillChange = (value) => {
    setFormState((prevState) => ({
      ...prevState,
      question: value,
    }));
  };

  const handlePaymentStatusChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRating = (e) => {
    const { value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      rating: value,
    }));
  };

  const handleSubjectChange = (event) => {
    const value = event.target.value;
    setFormState((prevState) => ({
      ...prevState,
      selectedSubject: value,
    }));
  };

  const handleQuestionTypeChange = (event) => {
    const value = event.target.value;
    setFormState((prevState) => ({
      ...prevState,
      selectedQuestionType: value,
    }));
  };

  const getCategoryWiseCancelOrder = async () => {
    const response = await axiosInstance.post(
      `/admin/wallet/getCategoryWiseCancelOrder`,
      {
        questionId: editData._id,
        paymentSection: editData.paymentSection,
      }
    );
    setPaymentStatus(response.data);
    if (response.status === 200) {
      toast.success(response.data.message);
    } else {
      toast.error(response.data.error);
    }
  };

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleCancelOrder = () => {
    setShowModal(true);
  };

  const handleResume = () => {
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
    getCategoryWiseCancelOrder();
  };

  return (
    <>
      <div className={`${Styles.second_container}`}>
        <div className="d-flex justify-content-between">
          <div className={`${Styles.order_text}`}>
            <p className={`${Styles.order_id}`}>
              Order ID: {editData?.displayId}
            </p>
            <p className={`${Styles.assignment_help_text}`}>
              Homework Help - {editData?.subCategoryId?.name}
            </p>
          </div>
          {editData?.studentPaymentStatus === "Refund" &&
            editData?.questionStatus !== "Close" && (
              <div className={`${Styles.button_color}`}>
                <Button
                  type="cancel"
                  onClick={handleCancelOrder}
                  disabled={
                    paymentStatus.message === "Order close successfully."
                  }
                >
                  Cancel Order
                </Button>
              </div>
            )}
        </div>
        <div className={`bg-white ${Styles.assignment_content}`}>
          <div>
            <div className="d-flex justify-content-between ">
              <div className="d-flex ps-5 pt-5 pb-1 pe-5">
                <p className={`${Styles.time_stamp_heading}`}>Time Stamp</p>
                <p className={`ps-3 ${Styles.date_and_time}`}>
                  {dateFormat(editData?.updatedAt)}
                </p>
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between ">
              <div className="ps-5 pt-2">
                <span className={`${Styles.student_corner_text}`}>
                  Student Corner
                </span>
              </div>
            </div>
            <form className="pt-3" onSubmit={handleSubmit}>
              <div className="ps-5 pe-5">
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Email ID<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      type="text"
                      className="form-control"
                      id="emailid"
                      placeholder=""
                      defaultValue={editData?.studentId?.email}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Question <b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-9">
                    <CommonEditor
                      value={formState.question}
                      placeholder="Enter content ..."
                      onChange={handleQuillChange}
                      defaultValue={editData?.question}
                      readOnly={
                        editData.studentPaymentStatus === "Completed" ||
                        editData.studentPaymentStatus === "Refund"
                      }
                      className={`${Styles.h_200}`}
                    />
                  </div>
                </div>
                <br />
                <div className="form-group row pt-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Transcript Text <b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-9">
                    <textarea
                      type="text"
                      className="form-control"
                      id="inputPassword"
                      placeholder=""
                      rows="3"
                      defaultValue={editData?.transcriptText}
                      disabled
                    ></textarea>
                  </div>
                </div>
                <div className="form-group row mb-4 mt-5 pt-3">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Subject<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      id="exampleSelect"
                      name="select"
                      type="select"
                      onChange={handleSubjectChange}
                      value={formState.selectedSubject}
                      disabled={
                        editData.studentPaymentStatus === "Completed" ||
                        editData.studentPaymentStatus === "Refund"
                      }
                    >
                      <option value="">
                        {editData
                          ? editData?.subjectId
                            ? editData?.subjectId.questionSubject
                            : "Please select subject"
                          : "Please select subject"}
                      </option>
                      {formState.subjects?.map((subject) => (
                        <option key={subject._id} value={subject._id}>
                          {subject.questionSubject}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Question Type<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      id="exampleSelect"
                      name="select"
                      type="select"
                      onChange={handleQuestionTypeChange}
                      value={formState.selectedQuestionType}
                      disabled={
                        editData.studentPaymentStatus === "Completed" ||
                        editData.studentPaymentStatus === "Refund"
                      }
                    >
                      <option value="">
                        {editData
                          ? editData?.subCategoryId
                            ? editData?.subCategoryId.name
                            : "Please select type"
                          : "Please select type"}
                      </option>
                      {formState.questionTypes?.map((questionType) => (
                        <option key={questionType._id} value={questionType._id}>
                          {questionType.name}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Payment Status<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      type="select"
                      className="form-control"
                      id="inputPassword"
                      placeholder=""
                      value={formState.studentPaymentStatus}
                      onChange={handlePaymentStatusChange}
                      disabled={
                        editData.studentPaymentStatus === "Completed" ||
                        editData.studentPaymentStatus === "Refund"
                      }
                      name="studentPaymentStatus"
                    >
                      <option value="Paid">Paid</option>
                      <option value="Unpaid">Unpaid</option>
                      <option value="Refund">Refund</option>
                    </Input>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Payment Method<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      type="select"
                      className="form-control"
                      id="paymentMethod"
                      placeholder=""
                      value={formState.paymentMethod}
                      onChange={handlePaymentStatusChange}
                      disabled={
                        editData.studentPaymentStatus === "Completed" ||
                        editData.studentPaymentStatus === "Refund"
                      }
                      name="paymentMethod"
                    >
                      <option value="Stripe">Stripe</option>
                      <option value="Razorpay">Razorpay</option>
                    </Input>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <label
                    htmlFor="paymentId"
                    className="col-sm-3 col-form-label"
                  >
                    Payment Id <b className="ms-2">:</b>
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      id="paymentId"
                      defaultValue={editData?.paymentId}
                      disabled={
                        editData?.studentPaymentStatus === "Refund" ||
                        editData?.studentPaymentStatus === "Unpaid" ||
                        editData?.studentPaymentStatus === "Completed"
                      }
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          paymentId: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Estimated Price<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6 d-flex align-items-center">
                    <span className={`${Styles.plandoll}`}>$</span>
                    <Input
                      type="text"
                      className="form-control"
                      id="estimatedPrice"
                      name="estimatedPrice"
                      placeholder=""
                      onChange={(event) => {
                        const value = event.target.value;
                        if (
                          /^\d*\.?\d*$/.test(value) ||
                          value === "" ||
                          value === "."
                        ) {
                          setFormState((prevState) => ({
                            ...prevState,
                            estimatedPrice: value,
                          }));
                        }
                      }}
                      defaultValue={editData?.estimatedPrice?.toFixed(2)}
                      disabled={
                        editData.studentPaymentStatus === "Paid" ||
                        editData.studentPaymentStatus === "Completed" ||
                        editData.studentPaymentStatus === "Refund"
                      }
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Discounted Price<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6 d-flex align-items-center">
                    <span className={`${Styles.plandoll}`}>$</span>
                    <Input
                      type="text"
                      className="form-control"
                      id="discountPrice"
                      name="discountPrice"
                      placeholder=""
                      onChange={(event) => {
                        const value = event.target.value;
                        if (
                          /^\d*\.?\d*$/.test(value) ||
                          value === "" ||
                          value === "."
                        ) {
                          setFormState((prevState) => ({
                            ...prevState,
                            discountPrice: value,
                          }));
                        }
                      }}
                      defaultValue={editData?.discountPrice?.toFixed(2)}
                      disabled={
                        editData.studentPaymentStatus === "Paid" ||
                        editData.studentPaymentStatus === "Completed" ||
                        editData.studentPaymentStatus === "Refund"
                      }
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Promocode Price<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6 d-flex align-items-center">
                    <span className={`${Styles.plandoll}`}>$</span>
                    <Input
                      type="text"
                      className="form-control"
                      id="inputPassword"
                      placeholder=""
                      defaultValue={editData?.promoPrice?.toFixed(2)}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Promocode <b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      type="text"
                      className="form-control"
                      id="inputPassword"
                      placeholder=""
                      defaultValue={editData?.promoCode}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Total Price<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6 d-flex align-items-center">
                    <span className={`${Styles.plandoll}`}>$</span>
                    <Input
                      type="text"
                      className="form-control"
                      id="inputPassword"
                      placeholder=""
                      defaultValue={editData?.totalPrice?.toFixed(2)}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Remark <b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <textarea
                      type="text"
                      className="form-control"
                      id="inputPassword"
                      placeholder=""
                      rows="3"
                      defaultValue={editData?.remark}
                      onChange={(e) => setFormState(e.target.value)}
                      disabled={
                        editData.studentPaymentStatus === "Completed" ||
                        editData.studentPaymentStatus === "Refund"
                      }
                    ></textarea>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Rating<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      type="select"
                      className="form-control"
                      id="inputPassword"
                      placeholder=""
                      value={formState.rating}
                      onChange={handleRating}
                      disabled={
                        editData.studentPaymentStatus === "Completed" ||
                        editData.studentPaymentStatus === "Refund"
                      }
                    >
                      <option value="Upvote">Upvote</option>
                      <option value="Downvote">Downvote</option>
                    </Input>
                  </div>
                </div>
              </div>
              <div
                className={`d-flex justify-content-around ${Styles.quesion_color} p-3`}
              >
                <div className="d-flex">
                  <div className={`${Styles.cancel_button} me-4`}>
                    <Button
                      type="button"
                      className={`${Styles.cancel_button}`}
                      onClick={() =>
                        setFormState((prevState) => ({
                          ...prevState,
                          editingEnabled: false,
                        }))
                      }
                      disabled={
                        editData.studentPaymentStatus === "Completed" ||
                        editData.studentPaymentStatus === "Refund"
                      }
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={`${Styles.submit_button}`}>
                    <Button
                      type="submit"
                      disabled={
                        editData.studentPaymentStatus === "Completed" ||
                        editData.studentPaymentStatus === "Refund"
                      }
                    >
                      {isLoading ? "Submitting..." : "Submit"}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
            {/* <hr /> */}
            <div className="mt-2">
              <ForPayment />
              <ForAdmin />
            </div>
          </div>
        </div>
      </div>
      <CancelModal
        handleClose={handleClose}
        handleResume={handleResume}
        showModal={showModal}
        toggleModal={toggleModal}
      />
    </>
  );
}
