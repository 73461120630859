import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { kebabCase } from 'lodash';
import Styles from './assets/SidebarItems.module.css';

export default function SidebarItems({ submenu, children }) {

    const [expandMenu, setExpandMenu] = useState(false);

    if (!submenu) {
        return (
            <li className={`${Styles.sidebar_menu} sidebar_menu mb-2 rounded`}>
                <NavLink to={kebabCase(children)} className="btn border-0" style={{fontSize: '14px'}}>
                    {children}
                </NavLink>
            </li>
        )
    }

    return (
        <li className={`mb-2 rounded`}>
            <div className={`d-flex align-items-center justify-content-between rounded ${Styles.sidebar_menu}`} onClick={() => setExpandMenu(oldState => !oldState)}>
                <button className={`btn border-0 `} style={{fontSize: '14px'}}>
                    {children}
                </button>
                <i className="bx bx-chevron-right arrow-left" />
            </div>

            <div className={!expandMenu ? "collapse" : ""}>
                <ul className={Styles.menu}>
                    {submenu.map((e, index) => (
                        <li key={index} className={`mb-2 rounded d-flex align-items-center flex-row submenu ${Styles.submenu}`}>
                            <i className={`bx bxs-circle`} />
                            <NavLink to={kebabCase(e)} className="btn border-0" style={{fontSize: '14px'}}>{e}</NavLink>
                        </li>
                    ))}
                </ul>
            </div>

        </li>
    )
}
