import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Styles from "../homework/assets/Form.module.css";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInterceptor";
import CommonEditor from "./CommonEditor";

const AddBanners = () => {
  const location = useLocation();
  const editData = location.state;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [subCategory, setSubcategory] = useState([]);
  const [titleError, setTitleError] = useState(false);

  const [data, setData] = useState({
    title: editData?.title || "",
    description: editData?.description || "",
    subCategoryId: editData?.subCategoryId?._id || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name) {
      setData({ ...data, [name]: value });
    }
  };
  const navigator = useNavigate();

  // const handleTitleChange = (value) => {
  //   setData({ ...data, data: value });
  //   setTitleError(!value.trim()); // Assuming you want to show an error if the title is empty
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!data.subCategoryId || !data.description || !data.title) {
      setTitleError(true);
      return;
    }
    try {
      const response = await axiosInstance.post(`admin/banners/createBanner`, {
        subCategoryId: data.subCategoryId,
        title: data.title,
        description: data.description,
      });

      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/banner");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.put(
        `admin/banners/updateBanner/${editData?._id}`,
        {
          subCategoryId: data.subCategoryId,
          title: data.title,
          description: data.description,
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/banner");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  const fetchSubjects = async () => {
    try {
      const response = await axiosInstance.get(
        `admin/subcategory/getAllSubcategory`
      );
      if (response.data.isSuccess) {
        setSubcategory(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  useEffect(() => {
    fetchSubjects();
  }, []);

  return (
    <div>
      <div className="mt-4 mb-4">
        <div className="row">
          <div className="col-sm-6">
            <h4 className="page-title">Add Banner</h4>
          </div>
        </div>
        <Card className="mt-4">
          <CardBody>
            <Row>
              <Col md="8">
                <form
                  className="ps-5 pe-5 pt-3"
                  onSubmit={editData?._id ? handleUpdate : handleSubmit}
                >
                  <div className="form-group row mb-2">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      Sub Category
                    </Label>
                    <div className="col-sm-8">
                      <FormGroup>
                        <Input
                          id="exampleSelect"
                          name="subCategoryId"
                          type="select"
                          invalid={!data.subCategoryId && formSubmitted}
                          defaultValue={
                            editData
                              ? editData?.subCategoryId?._id ===
                                data.subCategoryId
                                ? data.subCategoryId
                                : ""
                              : ""
                          }
                          onChange={(e) => handleChange(e)}
                        >
                          <option value="">
                            {editData
                              ? editData?.subCategoryId?._id ===
                                data.subCategoryId
                                ? subCategory.find(
                                    (subcat) =>
                                      subcat._id === data.subCategoryId
                                  )?.name
                                : "--- Select sub category ---"
                              : "--- Select sub category ---"}
                          </option>
                          {subCategory?.map((subcat) => (
                            <option key={subcat._id} value={subcat._id}>
                              {subcat.name}
                            </option>
                          ))}
                        </Input>
                        <FormFeedback>Sub category is required</FormFeedback>
                      </FormGroup>
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      Banner Title
                    </Label>
                    <div className="col-sm-8">
                      <CommonEditor
                        value={data.title}
                        placeholder="Enter content ..."
                        onChange={(value) =>
                          handleChange({ target: { name: "title", value } })
                        }
                        className={`${Styles.h_200}`}
                      />
                      {titleError && !data?.title?.trim() && (
                        <div className="text-danger">
                          Banner title is required
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      Description{" "}
                    </Label>
                    <div className="col-sm-8">
                      <Input
                        type="textarea"
                        className="form-control"
                        id="inputPassword"
                        placeholder="Enter your banner description"
                        name="description"
                        invalid={
                          !editData && !data?.description && formSubmitted
                        }
                        value={data.description}
                        onChange={(e) => handleChange(e)}
                        rows={5}
                      />
                      <FormFeedback>
                        Banner description is required
                      </FormFeedback>
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      <b className="ms-2"></b>
                    </Label>
                    <div className="col-sm-7 d-flex">
                      <div className={`${Styles.submit_button}`}>
                        <Button type="submit">
                          {editData?._id ? "Update" : "Save"}
                        </Button>
                      </div>
                      <div className={`${Styles.cancel_button} me-4 mx-2`}>
                        <Link to="/banner">
                          {" "}
                          <Button className={`${Styles.cancel_button}`}>
                            Cancel
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AddBanners;
