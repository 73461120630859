import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import Styles from '../homework/assets/Form.module.css';
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../../axiosInterceptor';

const AddHelpStep = () => {
  const location = useLocation();
  const editData = location.state;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [subCategory, setSubcategory] = useState([]);
  const navigator = useNavigate();

  const updatedHelpSteps = editData?.helpSteps.map(step => {
        if (!editData?.helpSteps) {
          return {
            _id: step._id || '', 
            title: step.title,
            description: step.description,
          };
        } else {
          return {
            title: step.title,
            description: step.description,
          };
        }
      });

  const [formData, setFormData] = useState({
    subCategoryId: editData?.subCategoryId || "",
    mainTitle: editData?.mainTitle || "",
    helpSteps: editData?.helpSteps ? updatedHelpSteps : [{ title: "", description: "" }]
  });

  const handleStepChange = (index, field, value) => {
    const updatedHelpSteps = [...formData.helpSteps];
    updatedHelpSteps[index][field] = value;
    setFormData({
      ...formData,
      helpSteps: updatedHelpSteps
    });
  };

  const handleAddStep = () => {
    setFormData({
      ...formData,
      helpSteps: [...formData.helpSteps, { title: "", description: "" }]
    });
  };

  const handleDeleteStep = (index) => {
    const updatedHelpSteps = [...formData.helpSteps];
    updatedHelpSteps.splice(index, 1);
    setFormData({
      ...formData,
      helpSteps: updatedHelpSteps
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    if (!formData.subCategoryId) {
      return;
    }

    try {
      const response = await axiosInstance.post('admin/completeHelpStep/createHelpStep', formData);

      if (response.status === 200) {
        toast.success(response.data.message);
        navigator('/complete-help-step');
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again!!');
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.put(`admin/completeHelpStep/updateHelpStep/${editData?._id}`, formData);

      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/complete-help-step");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again!!');
    }
  };

  const fetchSubCategory = async () => {
    try {
      const response = await axiosInstance.get(`admin/subcategory/getAllSubcategory`);
      if (response.data.isSuccess) {
        setSubcategory(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again!!');
    }
  };

  useEffect(() => {
    fetchSubCategory();
  }, []);

  return (
    <div>
      <div className="mt-4 mb-4">
        <div className='row'>
          <div className='col-sm-6'>
            <h4 className="page-title">Add Complete Help Step</h4>
          </div>
        </div>
        <Card className='mt-4'>
          <CardBody>
            <Row>
              <form className="ps-5 pe-5 pt-3" onSubmit={editData?._id ? handleUpdate : handleSubmit}>
                <Col md={8}>
                  <div className="form-group row mb-2">
                    <Label for="inputPassword" className="col-sm-4 col-form-label">
                      Sub Category 
                    </Label>
                    <div className="col-sm-8">
                      <FormGroup>
                        <Input
                          id="exampleSelect"
                          name="subCategoryId"
                          type="select"
                          invalid={!formData.subCategoryId && formSubmitted}
                          value={formData.subCategoryId}
                          onChange={(e) => setFormData({ ...formData, subCategoryId: e.target.value })}
                        >
                          <option value="">--- Select sub category ---</option>
                          {subCategory.map(subcat => (
                            <option key={subcat._id} value={subcat._id}>{subcat.name}</option>
                          ))}
                        </Input>
                        <FormFeedback>Sub category is required</FormFeedback>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="form-group row mb-4">
                    <Label for="inputPassword" className="col-sm-4 col-form-label">
                      Main Title
                    </Label>
                    <div className="col-sm-8">
                      <Input
                        type="text"
                        className="form-control"
                        id="inputPassword"
                        placeholder="Enter your main title "
                        invalid={!editData && !formData.mainTitle && formSubmitted}
                        name='mainTitle'
                        value={formData.mainTitle}
                        onChange={(e) => setFormData({ ...formData, mainTitle: e.target.value })}
                      />
                      <FormFeedback>Main title is required</FormFeedback>
                    </div>
                  </div>
                </Col>
                {formData.helpSteps.map((step, index) => (
                  <Row key={index}>
                    <Col md="8">
                      <div className="form-group row mb-4" >
                        <Label for={`title-${index}`} className="col-sm-4 col-form-label">
                          Title
                        </Label>
                        <div className="col-sm-8">
                          <Input
                            type="text"
                            name={`title-${index}`}
                            id={`title-${index}`}
                            value={step.title}
                            placeholder='Enter your title'
                            onChange={(e) => handleStepChange(index, 'title', e.target.value)}
                          />
                          <FormFeedback>Title is required</FormFeedback>
                        </div>
                      </div>
                      <div className="form-group row mb-4" key={index}>
                        <Label for={`description-${index}`} className="col-sm-4 col-form-label">
                          Description
                        </Label>
                        <div className="col-sm-8">
                          <input
                            className='form-control'
                            type="textarea"
                            placeholder='Enter your description'
                            name={`description-${index}`}
                            id={`description-${index}`}
                            value={step.description}
                            onChange={(e) => handleStepChange(index, 'description', e.target.value)}
                            rows={5}
                          />
                          <FormFeedback>Description is required</FormFeedback>
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      {index !== 0 && <Button
                        type="button"
                        color="danger"
                        onClick={() => handleDeleteStep(index)}
                      >
                        Delete Step
                      </Button>}
                    </Col>
                  </Row>
                ))}
                <Button type="button" onClick={handleAddStep}>
                  Add Step
                </Button>
                <div className="form-group row mb-4">
                  <Label for="inputPassword" className="col-sm-4 col-form-label">
                    <b className="ms-2"></b>
                  </Label>
                  <div className="col-sm-7 d-flex">
                    <div className={`${Styles.submit_button}`}>
                      <Button type='submit'>{editData?._id ? "Update" : "Save"}</Button>
                    </div>
                    <div className={`${Styles.cancel_button} me-4 mx-2`}>
                      <Link to='/complete-help-step'> <Button className={`${Styles.cancel_button}`}>Cancel</Button></Link>
                    </div>
                  </div>
                </div>
              </form>
            </Row>
          </CardBody>
        </Card>
      </div >
    </div >
  )
}

export default AddHelpStep;


