export const dateFormat = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);

    // Get day, month, year
    const day = dateTime.getDate().toString().padStart(2, '0');
    const month = (dateTime.getMonth() + 1).toString().padStart(2, '0'); // Note: January is 0
    const year = dateTime.getFullYear();

    // Get hours, minutes, AM/PM
    const hours = dateTime.getHours().toString().padStart(2, '0');
    const minutes = dateTime.getMinutes().toString().padStart(2, '0');
    const amPM = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    const hours12 = (hours % 12) || 12;

    // Construct the formatted date string
    const formattedDate = `${day}-${month}-${year} | ${hours12}:${minutes} ${amPM}`;

    return formattedDate;
}