import React, { useCallback, useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
} from "reactstrap";
import Styles from "../homework/assets/Form.module.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import pluscircle from "../homework/assets/images/plus circle_.png";
import grp713 from "../homework/assets/images/Group 713.png";
import delete1 from "../homework/assets/images/delete.png";
import axiosInstance from "../../axiosInterceptor";
import ReactPlayer from "react-player";

const Testimonial = () => {
    const [loading, setLoading] = useState(false);
    const [socialMediaData, setSocialMediaData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const navigator = useNavigate();
    const perPage = 10;

    const fetchData = useCallback(async (page = 1) => {
        try {
            setLoading(true);
            const response = await axiosInstance.post(
                'admin/testimonial/getTestimonials',
                {
                    perPage: perPage,
                    pageNo: page,
                }
            );
            const { data, totalPages } = response.data;
            setSocialMediaData(data);
            setTotalPages(totalPages);
            setCurrentPage(page);
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        } finally {
            setLoading(false);
        }
    }, [perPage]);

    const handlePageClick = (page) => {
        if (page >= 1 && page <= totalPages) {
            fetchData(page);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleDelete = async (id) => {
        setDeleteItemId(id);
        setModalOpen(true);
    };

    const confirmDelete = async () => {
        try {
            const response = await axiosInstance.delete(
                `admin/testimonial/deleteTestimonial/${deleteItemId}`
            );
            if (response.status === 200) {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Something went wrong. Please try again!!");
        } finally {
            setDeleteItemId(null);
            setModalOpen(false);
        }
    };

    return (
        <>
            <div>
                <div className="my-2">
                    <div className="row mb-2">
                        <div className="d-flex justify-content-between">
                            <div>
                                <h4 className="page-title">Testimonials</h4>
                            </div>
                            <div>
                                <Link to="/addtestimonials">
                                    <Button className="action-button add-from-new">
                                        <img
                                            src={pluscircle}
                                            alt="plus"
                                            width={20}
                                            className="ADD_code"
                                        />
                                        <span className=""> Add </span>
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <Card className="mt-4 ">
                        <CardBody>
                            {loading ? (
                                <div className="text-center mt-4">
                                    <p>Loading...</p>
                                </div>
                            ) : socialMediaData?.length > 0 ? (
                                <div className="table-responsive">
                                    <Table bordered responsive className="mt-4 text-center" style={{ fontSize: '0.8rem' }}>
                                        <thead>
                                            <tr>
                                                <th>Sr. No</th>
                                                <th>Videos</th>
                                                <th>isActive</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {socialMediaData?.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>
                                                            <ReactPlayer
                                                                url={item.videoLink}
                                                                controls
                                                                width="100%"
                                                                height="300px"
                                                                className="react-player px-4"
                                                            />
                                                            {/* {item.videoLink ? item.videoLink : "-"} */}
                                                        </td>

                                                        <td>
                                                            <FormGroup switch>
                                                                <Input
                                                                    className={`${Styles.form_check_input} float-none`}
                                                                    type="switch"
                                                                    checked={item.isActive}
                                                                />
                                                            </FormGroup>
                                                        </td>
                                                        <td className="d-flex justify-content-center">
                                                            <div className={`${Styles.edit_code}`}>
                                                                <Button
                                                                    className={`${Styles.edit_code}`}
                                                                    onClick={() => {
                                                                        navigator("/addtestimonials", {
                                                                            state: item,
                                                                        });
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={grp713}
                                                                        alt="error"
                                                                        className=""
                                                                        srcSet=""
                                                                        width={20}
                                                                    />
                                                                    Edit
                                                                </Button>
                                                            </div>
                                                            <div
                                                                className={`${Styles.delete_button_code} me-2 mx-2`}
                                                            >
                                                                <Button
                                                                    className={`${Styles.delete_button_code}`}
                                                                    onClick={() => handleDelete(item._id)}
                                                                >
                                                                    <img
                                                                        src={delete1}
                                                                        alt="error"
                                                                        className={`${Styles.delete_icon}`}
                                                                        srcSet=""
                                                                    />
                                                                    Delete
                                                                </Button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            ) : (
                                <div className="text-center mt-4">
                                    <p>No data found.</p>
                                </div>
                            )}
                            <div className="float-end">
                                <Pagination>
                                    <PaginationItem disabled={currentPage === 1}>
                                        <PaginationLink previous onClick={() => handlePageClick(currentPage - 1)} />
                                    </PaginationItem>
                                    {[...Array(totalPages)].map((_, i) => (
                                        <PaginationItem key={i} active={i + 1 === currentPage}>
                                            <PaginationLink onClick={() => handlePageClick(i + 1)}>{i + 1}</PaginationLink>
                                        </PaginationItem>
                                    ))}
                                    <PaginationItem disabled={currentPage === totalPages}>
                                        <PaginationLink next onClick={() => handlePageClick(currentPage + 1)} />
                                    </PaginationItem>
                                </Pagination>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>
            <Modal
                isOpen={modalOpen}
                toggle={() => setModalOpen(!modalOpen)}
                style={{ top: "33%" }}
            >
                <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
                    Confirm Delete
                </ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this link?
                </ModalBody>
                <ModalFooter>
                    <div className={`${Styles.button_color}`}>
                        <Button type="cancel" onClick={() => setModalOpen(!modalOpen)}>
                            No
                        </Button>
                    </div>
                    <div className={`${Styles.cancel_button} me-4`}>
                        <Button
                            type="cancel"
                            className={`${Styles.cancel_button}`}
                            onClick={confirmDelete}
                        >
                            Yes
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default Testimonial;
