import React from 'react'

const Welcome = () => {
    return (
        <div>
            <div className="wallte mt-4 mb-4">
                <h4 className="page-title">Welcome DoubtQ Admin!</h4>
            </div>
        </div>
    )
}

export default Welcome