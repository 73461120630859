import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from '../assets/logo/logo.png';
import "./Login.scss"
import { Button, Form, FormGroup, Label, Input, Container, Row, Col, FormFeedback } from 'reactstrap';
import axios from "axios";
import { toast } from "react-toastify";

const Resetpassword = () => {
    
    const location = useLocation();
    const navigator = useNavigate();
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState(location.state || '');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [submitAttempted, setSubmitAttempted] = useState(false);
   
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitAttempted(true)
        if (!email || !password || !confirmPassword) {
            return;
        }

        if (password !== confirmPassword) {
            return toast.error("Passwords don't match");
        }
        try {
            let headersList = {
                "Content-Type": "application/json"
            }

            let bodyContent = JSON.stringify({
                email: email,
                password: password
            });

            let reqOptions = {
                url: `admin/resetPassword`,
                method: "POST",
                headers: headersList,
                data: bodyContent,
            }

            let response = await axios.request(reqOptions);
            if (response.status === 200) {
                toast.success(response.data.message)
                navigator("/")
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    }

    return (
        <div className="login-register-bg">
            <Container>
                <Row className="d-lg-flex align-items-center justify-content-center d-block h-100">
                    <Col lg="7" className="mx-auto mt-6">
                        <div className="logo text-center">
                            <Link to="#">
                                <img src={Logo} alt="DoubtQ Logo" />
                            </Link>
                        </div>
                        <div className="rbt-contact-form contact-form-style-1 rbt-shadow-box mb--50 mt-2">
                            <Row className="align-items-center">
                                <Col lg="12" className="pl--30 pl_sm--0">
                                    <div>
                                        <h4 className="title text-center mb--10"><b>Reset Password</b></h4>
                                        <Form className="rbt-profile-row rbt-default-form row row--15"
                                            onSubmit={handleSubmit}>
                                            <Col lg="12" md="12" xs="12">
                                                <FormGroup>
                                                    <Label>Email</Label>
                                                    <Input invalid={!email && submitAttempted} type="email" placeholder="Enter your email" value={email} name="email" onChange={(e) => setEmail(e.target.value)} readOnly />
                                                    <FormFeedback>Email is required.</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12" md="12" xs="12">
                                                <FormGroup>
                                                    <Label>New Password</Label>
                                                    <Input invalid={!password && submitAttempted} type="password" placeholder="Enter your new password" value={password} name="password" onChange={(e) => setPassword(e.target.value)} />
                                                    <FormFeedback>New password is required.</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12" md="12" xs="12">
                                                <FormGroup>
                                                    <Label>Confirm Password</Label>
                                                    <Input invalid={!confirmPassword && submitAttempted} type="password" placeholder="Enter your confirm password" value={confirmPassword} name="confirmpassword" onChange={(e) => setConfirmPassword(e.target.value)} />
                                                    <FormFeedback>Confirm password is required.</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12" md="12" xs="12" className="text-end">
                                                <div className="text-center">
                                                    <Button type="submit" className="px-4 login-button">Save</Button>
                                                </div>
                                            </Col>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Resetpassword