import React, { useEffect, useState } from "react";
import "./Wallte.scss";
import {
  Col,
  Row,
  Card,
  CardBody,
  Input,
  InputGroup,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
} from "reactstrap";
import { FaSearch } from "react-icons/fa";
import axiosInstance from "../../axiosInterceptor";
import { toast } from "react-toastify";
import { dateFormat } from "../../dateFormat";
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import "rsuite/DateRangePicker/styles/index.css";
import { CSVLink } from "react-csv";
import Doubtq from "./doubt-q.png";
import Email from "./email.png";
import YouTube from "./youtube.png";
import Instagram from "./instagram.png";
import Linkedin from "./linkedin.png";
import Facebook from "./facebook.png";
import jsPDF from 'jspdf';

const Wallet = () => {
  const navigator = useNavigate();

  const [apiData, setApiData] = useState({});
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterOption, setFilterOption] = useState("All");

  useEffect(() => {
    const fetchCategory = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.post(
          `admin/wallet/getCategoryWisePayment`,
          {
            paymentSection: "All",
            paymentStatus: filterOption,
            fromDate: fromDate,
            toDate: toDate,
            perPage: 10,
            pageNo: currentPage,
          }
        );

        setApiData(response.data);
        setLoading(false);
      } catch (error) {
        toast.error("Something went wrong. Please try again!!");
      } finally {
        setLoading(false);
      }
    };
    fetchCategory();
  }, [fromDate, toDate, currentPage, filterOption]);

  const handleFilterChange = (event) => {
    setFilterOption(event.target.value);
  };

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const columns = [
    { label: "Date & Time", key: "dateAndTime" },
    { label: "Order ID", key: "orderId" },
    { label: "Type", key: "type" },
    { label: "Earning", key: "earning" },
    { label: "Refund", key: "refund" },
    { label: "Tutor", key: "tutor" },
    { label: "Status", key: "status" },
    // Action column is excluded from export
  ];


  const formatDate = (dateString) => {
    const options = { month: "short", day: "numeric", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const yourInvoiceHTMLContent = `
<!DOCTYPE html>
<html>
<head>
  <style>
    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f8f8f8;
    }

    #invoice-content {
      width: 650px;
      margin: 0 auto;
      padding: 20px;
      background-color: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      margin-top: 1%;
    }

    .header, .footer {
      text-align: center;
      margin-bottom: 30px;
    }

    .header img {
      max-width: 150px;
    }

    .header h1 {
      margin: 0;
      font-size: 24px;
      color: #333;
    }

    .invoice-details, .invoice-summary {
      margin-bottom: 20px;
    }

    .invoice-details p, .invoice-summary p {
      margin: 5px 0;
      font-size: 14px;
    }

    .invoice-summary {
      border-top: 2px solid #333;
      padding-top: 10px;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
    }

    table, th, td {
      border: 1px solid #ccc;
    }

    th, td {
      padding: 10px;
      text-align: left;
      font-size: 14px;
    }

    th {
      background-color: #f2f2f2;
    }

    .footer p {
      font-size: 12px;
      color: #777;
    }
    .footer{
      margin-top: 4%;
    }
    .contact-icons img {
      max-width: 24px;
      margin: 0 5px;
      padding-top: 20px;
    }
      .terms-conditions {
        margin-top: 20px;
        font-size: 10px;
      }
      .terms-conditions .p{
        padding: 0px;
      }
      .copyright{
        margin-top: 20px;
      }
  </style>
</head>
<body>
  <div id="invoice-content">
    <div class="header">
      <img src=${Doubtq} alt="Logo" />
      <h1>VAIDIK EDUCATION</h1>
      <p>B-603, Sky View Heights, Behind Midas Square, Surat, Gujarat, 395010, India</p>
    </div>
    <div class="invoice-details">
      <p><strong>BILL TO :</strong> ${invoice?.studentId?.email}</p>
      <p><strong>INVOICE ID :</strong> ${invoice?.displayId}</p>
      <p><strong>DATE :</strong> ${formatDate(invoice?.updatedAt)}</p>
      <p><strong>PAYMENT ID :</strong> ${invoice?.paymentId}</p>
      <p><strong>TYPE :</strong> ${invoice?.paymentSection}</p>
      <p><strong>CATEGORY :</strong> ${invoice?.subCategoryId?.name}</p>
      <p><strong>SUBJECT :</strong> ${invoice?.subjectId?.questionSubject}</p>
    </div>
    <table>
      <thead>
        <tr>
          <th>Description</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Estimated Price</td>
          <td> ${invoice?.estimatedPrice?.toFixed(2)}</td>
        </tr>
        <tr>
          <td>Promo Price</td>
          <td> ${invoice?.promoPrice?.toFixed(2)}</td>
        </tr>
        <tr>
          <td>Discount Price</td>
            <td> ${invoice?.discountPrice?.toFixed(2)}</td>
        </tr>
        <tr>
          <td>Total</td>
            <td> ${invoice?.totalPrice?.toFixed(2)}</td>
        </tr>
      </tbody>
    </table>
    <div class="invoice-summary">
      <p><strong>Total Amount Paid:</strong> ${invoice?.totalPrice?.toFixed(2)}</p>
    </div>
     <div class="terms-conditions">
      <h4>Terms and Conditions</h4>
                 <p>1. <strong>Project Completion</strong>: Payment is contingent upon the successful completion of the project milestones.</p>
            <p>2. <strong>Dispute Resolution</strong>: Any disputes shall be resolved through info@doubtq.com or  arbitration in Surat, India.</p>
  </div>
    <div class="footer">
      <div class="contact-icons">
        <a href="https://www.facebook.com"><img src=${Facebook} alt="Facebook"></a>
        <a href="https://www.linkedin.com"><img src=${Linkedin} alt="LinkedIn"></a>
        <a href="https://www.instagram.com"><img src=${Instagram} alt="Instagram"></a>
        <a href="https://www.youtube.com"><img src=${YouTube} alt="YouTube"></a>
        <a href="mailto:info@doubtq.com"><img src=${Email} alt="Email"></a>
      </div>
      <div class="copyright">
        <p>&copy; 2024 Doubtq.com. All rights reserved. | Get in touch: info@doubtq.com</p>
      </div>
    </div>
  </div>
</body>
</html>
`;

  const generatePdf = () => {
    const doc = new jsPDF("p", "pt", "letter");

    const content = document.getElementById("invoice-content").innerHTML;

    doc.html(content, {
      callback: function (doc) {
        doc.save("invoice.pdf");
      },
      x: 20,
      y: 20,
      width: 572,
      windowWidth: 700,
    });
  };
  useEffect(() => {
    if (invoice) {
      generatePdf();
    }
  }, [invoice, yourInvoiceHTMLContent]);

  const handleInvoiceButtonClick = (transaction) => {
    setInvoice(transaction);
  };

  return (
    <div className="wallte my-2">
      <h4 className="page-title">Orders</h4>
      <Row>
        <Col xs="12" className="grid-margin stretch-card">
          <Card>
            <CardBody>
              <Row>
                <Col md="4">
                  <DateRangePicker
                    className="w-100"
                    // value={[new Date(fromDate), new Date(toDate)]}
                    onChange={(value) => {
                      if (value && value[0]) {
                        setFromDate(value[0].toISOString().split("T")[0]);
                        setToDate(
                          value[1] ? value[1].toISOString().split("T")[0] : ""
                        );
                      }
                    }}
                    onClean={() => {
                      setFromDate(null);
                      setToDate(null);
                    }}
                  />
                </Col>
                <Col md="4">
                  <InputGroup className="search_input_icon">
                    <Input
                      type="text"
                      id="displayId"
                      placeholder="Order ID"
                      className="border_none_icon"
                      name="displayId"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text wallte">
                        <FaSearch />
                      </span>
                    </div>
                  </InputGroup>
                </Col>
                <Col md="4">
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    value={filterOption}
                    onChange={handleFilterChange}
                  >
                    <option value="All">All Payment Status</option>
                    <option value="Paid">Paid</option>
                    <option value="Unpaid">Unpaid</option>
                    <option value="Refund">Refund</option>
                  </Input>
                </Col>
              </Row>
              <div className="mt-4">
                <Row>
                  <Col md="4">
                    <h5 className="page-title">Transaction History</h5>
                  </Col>
                  <Col md="8">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6 className="mt-2">
                        Total Amount :{" "}
                        <span className="studentamount">
                          ${apiData.totalAmount?.toFixed(2)}
                        </span>
                      </h6>
                      <h6 className="mt-2">
                        Student :{" "}
                        <span className="studentamount">
                          ${apiData.studentAmount?.toFixed(2)}
                        </span>
                      </h6>
                      <h6 className="mt-2">
                        Refund :{" "}
                        <span className="tutoramount">
                          {apiData.refundAmount && apiData.refundAmount !== 0
                            ? `$${apiData.refundAmount?.toFixed(2)}`
                            : "$0.00"}
                        </span>
                      </h6>
                      <div>
                        <CSVLink
                          data={
                            apiData.transactionHistory
                              ? apiData.transactionHistory.map(
                                (transaction) => ({
                                  dateAndTime: dateFormat(
                                    transaction.updatedAt
                                  ),
                                  orderId: transaction.displayId,
                                  type: transaction.subCategoryId.name,
                                  earning: `$${transaction.studentAmount?.toFixed(
                                    2
                                  )}`,
                                  refund:
                                    transaction.refundAmount &&
                                      transaction.refundAmount !== 0
                                      ? `$${transaction.refundAmount.toFixed(
                                        2
                                      )}`
                                      : "$0.00",
                                  tutor: `${transaction.tutorAmount?.toFixed(
                                    2
                                  )}`,
                                  status: transaction.studentPaymentStatus,
                                })
                              )
                              : []
                          }
                          filename={"transaction_history.csv"}
                          className="btn btn-primary"
                          target="_blank"
                          headers={columns}
                          style={{ backgroundColor: "#E541ED", border: "none" }}
                        >
                          Export CSV
                        </CSVLink>
                      </div>
                    </div>
                  </Col>
                </Row>

                <div className="table-responsive">
                  <Table
                    bordered
                    responsive
                    className="mt-4"
                    style={{ fontSize: "0.8rem" }}
                  >
                    <thead>
                      <tr>
                        <th>Date & Time</th>
                        <th className="text-center">Order ID</th>
                        <th className="text-center">Type</th>
                        <th className="text-center">Earning</th>
                        <th className="text-center">Refund</th>
                        {/* <th className="text-center">Tutor</th> */}
                        <th className="text-center">Status</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan="8" className="text-center">
                            Loading...
                          </td>
                        </tr>
                      ) : (
                        <>
                          {apiData.transactionHistory &&
                            apiData.transactionHistory?.length > 0 ? (
                            apiData.transactionHistory
                              .filter(
                                (transaction) =>
                                  transaction._id.includes(searchTerm) ||
                                  transaction.displayId.includes(searchTerm)
                              )
                              .map((transaction) => (
                                <tr key={transaction._id}>
                                  <td>
                                    {dateFormat(transaction.updatedAt)}
                                    <br />
                                    {transaction?.studentId?.email}
                                  </td>
                                  <td className="text-center">
                                    {transaction.displayId}
                                    <br />
                                    {transaction._id}
                                  </td>
                                  <td className="text-center">
                                    {transaction.subCategoryId.name}
                                  </td>
                                  <td className="text-center">
                                    ${transaction.studentAmount?.toFixed(2)}
                                  </td>
                                  <td className="text-center">
                                    {transaction.refundAmount &&
                                      transaction.refundAmount !== 0
                                      ? `$${transaction.refundAmount.toFixed(
                                        2
                                      )}`
                                      : "$0.00"}
                                  </td>

                                  {/* <td className="text-center">
                                  {transaction.tutorAmount?.toFixed(2)}
                                </td> */}
                                  <td className="text-center">
                                    {transaction.studentPaymentStatus}
                                  </td>
                                  <td className="text-center">
                                    <Button
                                      className="action-button"
                                      onClick={() => {
                                        if (
                                          transaction.paymentSection ===
                                          "HomeworkHelp"
                                        ) {
                                          navigator("/homework-form", {
                                            state: transaction,
                                          });
                                        } else if (
                                          transaction.paymentSection ===
                                          "AssignmentHelp"
                                        ) {
                                          navigator("/assignment-form", {
                                            state: transaction,
                                          });
                                        } else if (
                                          transaction.paymentSection ===
                                          "LiveSessionHelp"
                                        ) {
                                          navigator("/live-session-chat", {
                                            state: transaction,
                                          });
                                        }
                                      }}
                                    >
                                      View
                                    </Button>
                                    {transaction.studentPaymentStatus ===
                                      "Completed" ||
                                      transaction.studentPaymentStatus ===
                                      "Paid" ? (
                                      <>
                                        <Button
                                          className="invoice"
                                          onClick={() => handleInvoiceButtonClick(transaction)}
                                        >
                                          Invoice
                                        </Button>
                                        <div id="invoice-content" style={{ display: "none" }} dangerouslySetInnerHTML={{ __html: yourInvoiceHTMLContent }}></div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              ))
                          ) : (
                            <tr>
                              <td colSpan="8" className="text-center">
                                No data found.
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
                <div className="float-end">
                  <Pagination className="mt-4">
                    <PaginationItem disabled={currentPage === 1}>
                      <PaginationLink
                        previous
                        onClick={() => handlePaginationClick(currentPage - 1)}
                      />
                    </PaginationItem>
                    {Array.from({ length: apiData.totalPages || 0 }, (_, i) => (
                      <PaginationItem
                        key={i + 1}
                        active={i + 1 === currentPage}
                      >
                        <PaginationLink
                          onClick={() => handlePaginationClick(i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem
                      disabled={currentPage === (apiData.totalPages || 1)}
                    >
                      <PaginationLink
                        next
                        onClick={() => handlePaginationClick(currentPage + 1)}
                      />
                    </PaginationItem>
                  </Pagination>
                </div>
              </div>
              <br />
              <br />

              <div className="mt-4">
                <Row className="w-100">
                  <Col md="4">
                    <div className="mt-4">
                      {/* <h4 className="page-title">Homework Help</h4> */}
                      <Table
                        bordered
                        className="mt-4"
                        responsive
                        style={{ fontSize: "0.9rem" }}
                      >
                        <thead>
                          <tr>
                            <th className="assignment-title">Homework Type</th>
                            <th className="assignment-title-student text-center">
                              Student
                            </th>
                            {/* <th className="assignment-title-tutor text-center">
                              Tutor
                            </th> */}
                            <th className="assignment-title-tutor text-center">
                              Refund
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <tr>
                              <td colSpan="6" className="text-center">
                                Loading...
                              </td>
                            </tr>
                          ) : (
                            <>
                              {apiData.sectionPayments &&
                                apiData.sectionPayments[0]?.categoryName ===
                                "Homework Help" ? (
                                <>
                                  {apiData.sectionPayments.map((category) => (
                                    <React.Fragment key={category._id}>
                                      {category.categoryName ===
                                        "Homework Help" &&
                                        category.subCategories.map(
                                          (subCategory) => (
                                            <tr key={subCategory.subCategoryId}>
                                              <th
                                                scope="row"
                                                className="table-data"
                                              >
                                                {subCategory.subCategoryName}
                                              </th>
                                              <td className="text-center studentamount">
                                                $
                                                {subCategory.studentAmount?.toFixed(
                                                  2
                                                )}
                                              </td>
                                              {/* <td className="text-center tutoramount">
                                                {subCategory.tutorAmount?.toFixed(
                                                  2
                                                )}
                                              </td> */}
                                              <td className="text-center tutoramount">
                                                $
                                                {subCategory.refundAmount?.toFixed(
                                                  2
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                    </React.Fragment>
                                  ))}
                                  <tr className="font-weight-bold">
                                    <th
                                      scope="row"
                                      className="assignment-title"
                                    >
                                      Total
                                    </th>
                                    <td className="assignment-title-student text-center studentamount">
                                      $
                                      {apiData.sectionPayments[0]?.studentAmount?.toFixed(
                                        2
                                      )}
                                    </td>
                                    {/* <td className="assignment-title-tutor text-center tutoramount">
                                      {apiData.sectionPayments[0]?.tutorAmount?.toFixed(
                                        2
                                      )}
                                    </td> */}
                                    <td className="assignment-title-tutor text-center tutoramount">
                                      $
                                      {apiData.sectionPayments[0]?.refundAmount?.toFixed(
                                        2
                                      )}
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <tr>
                                  <td colSpan="3" className="text-center">
                                    No data found.
                                  </td>
                                </tr>
                              )}
                            </>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mt-4">
                      {/* <h4 className="page-title">Assignment Help</h4> */}
                      <Table
                        bordered
                        className="mt-4"
                        responsive
                        style={{ fontSize: "0.8rem" }}
                      >
                        <thead>
                          <tr>
                            <th className="assignment-title">
                              Assignment Type
                            </th>
                            <th className="assignment-title-student text-center">
                              Student
                            </th>
                            {/* <th className="assignment-title-tutor text-center">
                              Tutor
                            </th> */}
                            <th className="assignment-title-tutor text-center">
                              Refund
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <tr>
                              <td colSpan="6" className="text-center">
                                Loading...
                              </td>
                            </tr>
                          ) : (
                            <>
                              {apiData.sectionPayments &&
                                apiData.sectionPayments.find(
                                  (category) =>
                                    category.categoryName === "Assignment Help"
                                ) ? (
                                <>
                                  {apiData.sectionPayments.map((category) => (
                                    <React.Fragment key={category._id}>
                                      {category.categoryName ===
                                        "Assignment Help" &&
                                        category.subCategories.map(
                                          (subCategory) => (
                                            <tr key={subCategory.subCategoryId}>
                                              <th
                                                scope="row"
                                                className="table-data"
                                              >
                                                {subCategory.subCategoryName}
                                              </th>
                                              <td className="text-center studentamount">
                                                $
                                                {subCategory.studentAmount?.toFixed(
                                                  2
                                                )}
                                              </td>
                                              {/* <td className="text-center tutoramount">
                                                {subCategory.tutorAmount?.toFixed(
                                                  2
                                                )}
                                              </td> */}
                                              <td className="text-center tutoramount">
                                                $
                                                {subCategory.refundAmount?.toFixed(
                                                  2
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                    </React.Fragment>
                                  ))}
                                  <tr className="font-weight-bold">
                                    <th
                                      scope="row"
                                      className="assignment-title"
                                    >
                                      Total
                                    </th>
                                    <td className="assignment-title-student text-center studentamount">
                                      $
                                      {apiData.sectionPayments
                                        .find(
                                          (category) =>
                                            category.categoryName ===
                                            "Assignment Help"
                                        )
                                        .studentAmount?.toFixed(2)}
                                    </td>
                                    {/* <td className="assignment-title-tutor text-center tutoramount">
                                      {apiData.sectionPayments
                                        .find(
                                          (category) =>
                                            category.categoryName ===
                                            "Assignment Help"
                                        )
                                        .tutorAmount?.toFixed(2)}
                                    </td> */}
                                    <td className="assignment-title-tutor text-center tutoramount">
                                      $
                                      {apiData.sectionPayments
                                        .find(
                                          (category) =>
                                            category.categoryName ===
                                            "Assignment Help"
                                        )
                                        .refundAmount?.toFixed(2)}
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <tr>
                                  <td colSpan="3" className="text-center">
                                    No data found.
                                  </td>
                                </tr>
                              )}
                            </>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mt-4">
                      {/* <h4 className="page-title">Live Session Help</h4> */}
                      <Table
                        bordered
                        className="mt-4"
                        responsive
                        style={{ fontSize: "0.8rem" }}
                      >
                        <thead>
                          <tr>
                            <th className="assignment-title">
                              Live Session Type
                            </th>
                            <th className="assignment-title-student text-center">
                              Student
                            </th>
                            {/* <th className="assignment-title-tutor text-center">
                              Tutor
                            </th> */}
                            <th className="assignment-title-tutor text-center">
                              Refund
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <tr>
                              <td colSpan="6" className="text-center">
                                Loading...
                              </td>
                            </tr>
                          ) : (
                            <>
                              {apiData.sectionPayments &&
                                apiData.sectionPayments.find(
                                  (category) =>
                                    category.categoryName === "Live Session Help"
                                ) ? (
                                <>
                                  {apiData.sectionPayments.map((category) => (
                                    <React.Fragment key={category._id}>
                                      {category.categoryName ===
                                        "Live Session Help" &&
                                        category.subCategories.map(
                                          (subCategory) => (
                                            <tr key={subCategory.subCategoryId}>
                                              <th
                                                scope="row"
                                                className="table-data"
                                              >
                                                {subCategory.subCategoryName}
                                              </th>
                                              <td className="text-center studentamount">
                                                $
                                                {subCategory.studentAmount?.toFixed(
                                                  2
                                                )}
                                              </td>
                                              {/* <td className="text-center tutoramount">
                                                {subCategory.tutorAmount?.toFixed(
                                                  2
                                                )}
                                              </td> */}
                                              <td className="text-center tutoramount">
                                                $
                                                {subCategory.refundAmount?.toFixed(
                                                  2
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                    </React.Fragment>
                                  ))}
                                  <tr className="font-weight-bold">
                                    <th
                                      scope="row"
                                      className="assignment-title"
                                    >
                                      Total
                                    </th>
                                    <td className="assignment-title-student text-center studentamount">
                                      $
                                      {apiData.sectionPayments
                                        .find(
                                          (category) =>
                                            category.categoryName ===
                                            "Live Session Help"
                                        )
                                        .studentAmount?.toFixed(2)}
                                    </td>
                                    {/* <td className="assignment-title-tutor text-center tutoramount">
                                      {apiData.sectionPayments
                                        .find(
                                          (category) =>
                                            category.categoryName ===
                                            "Live Session Help"
                                        )
                                        .tutorAmount?.toFixed(2)}
                                    </td> */}
                                    <td className="assignment-title-tutor text-center tutoramount">
                                      $
                                      {apiData.sectionPayments
                                        .find(
                                          (category) =>
                                            category.categoryName ===
                                            "Live Session Help"
                                        )
                                        .refundAmount?.toFixed(2)}
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <tr>
                                  <td colSpan="3" className="text-center">
                                    No data found.
                                  </td>
                                </tr>
                              )}
                            </>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </div>
              {/* <div className="mt-4">
                <Row className='w-100'>

                  <Col md="6">
                    <Table bordered className="mt-4">
                      <thead>
                        <tr>
                          <th className="total-amount">
                            Total Amount : ${apiData.totalAmount?.toFixed(2)}
                          </th>
                          <th className="total-amount"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row" className="table-data">
                            Student Amount (Received)
                          </th>
                          <td className="text-center font-weight-bold">
                            ${apiData.studentAmount?.toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="table-data">
                            Tutor Amount (Sent)
                          </th>
                          <td className="text-center font-weight-bold">
                            ${apiData.tutorAmount?.toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="table-data">
                            Refunded Amount (Sent)
                          </th>
                          <td className="text-center font-weight-bold">
                            Refunded -- 0.00
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div> */}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Wallet;
