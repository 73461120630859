import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Styles from "../homework/assets/Form.module.css";
import { Button, Input, Label } from "reactstrap";
import axiosInstance from "../../axiosInterceptor";
import grp713 from "../homework/assets/images/Group 713.png";
import vector132 from "../homework/assets/images/Vector 132.png";
import pluscircle from "../homework/assets/images/plus circle_.png";
import { toast } from "react-toastify";
import CommonEditor from "../masters/CommonEditor";

const Viewsubmitquery = () => {
  const location = useLocation();
  const editData = location.state;
  const navigator = useNavigate();

  const [question, setQuestion] = useState(editData?.question || "");
  const [answer, setAnswer] = useState(editData?.answer || "");
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(
    editData?.subjectId._id || ""
  );
  const [questionTypes, setQuestionTypes] = useState([]);
  const [selectedQuestionType, setSelectedQuestionType] = useState(
    editData?.subCategoryId._id || ""
  );
  const [isQuestionEditMode, setIsQuestionEditMode] = useState(false);
  const [isAnswerEditMode, setIsAnswerEditMode] = useState(false);

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const response = await axiosInstance.get(`admin/subject/getAllSubject`);
        if (response.data.isSuccess) {
          setSubjects(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again!!");
      }
    };

    fetchSubjects();
  }, []);

  useEffect(() => {
    const fetchQuestionTypes = async () => {
      try {
        const response = await axiosInstance.get(
          `admin/category/getAllCategory`
        );
        if (response.data.isSuccess) {
          const categories = response.data.data;
          const homeCategory = categories.find(
            (category) => category.name === "Homework Help"
          );
          if (homeCategory) {
            const categoryId = homeCategory._id;
            await fetchSubcategories(categoryId);
          } else {
            toast.error("No category named 'home' found.");
          }
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again!!");
      }
    };

    const fetchSubcategories = async (categoryId) => {
      try {
        const response = await axiosInstance.get(
          `admin/subcategory/getCategoryWiseSubcategory/${categoryId}`
        );
        if (response.data.isSuccess) {
          setQuestionTypes(response.data.subCategory);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again!!");
      }
    };

    fetchQuestionTypes();
  }, []);

  const toggleQuestionEditMode = () => {
    setIsQuestionEditMode(!isQuestionEditMode);
  };

  const toggleAnswerEditMode = () => {
    setIsAnswerEditMode(!isAnswerEditMode);
  };

  const handleSubmit = async () => {
    try {
      // setIsSubmitting(true);
      let response;
      if (editData) {
        response = await axiosInstance.put(
          `admin/submitQuery/updateQuery/${editData._id}`,
          {
            question,
            answer,
            subjectId: selectedSubject,
            subCategoryId: selectedQuestionType,
          }
        );
      }
      if (response.data.isSuccess) {
        toast.success(response.data.message);
        handleReset();
        navigator("/submit-query");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      // setIsSubmitting(false);
    }
  };

  const handleAddClick = () => {
    handleSubmit();
  };

  const handleSubjectChange = (event) => {
    setSelectedSubject(event.target.value);
  };
  const handleQuestionTypeChange = (event) => {
    setSelectedQuestionType(event.target.value);
  };

  const handleReset = () => {
    setQuestion("");
    setAnswer("");
    setSelectedSubject("");
    setSelectedQuestionType("");
  };

  return (
    <div>
      <div className={`${Styles.second_container}`}>
        <div className="mt-4 mb-4">
          <div className="row">
            <div className="col-sm-6">
              <h4 className="page-title">Q & A - Submit Query </h4>
            </div>
            <div className="col-sm-6 text-end">
              <Link to="/submit-query">
                <Button>Back</Button>
              </Link>
            </div>
          </div>
        </div>
        <div className={`bg-white ${Styles.assignment_content}`}>
          <div>
            <div className="d-flex justify-content-between ">
              <div className="ps-5 pt-5">
                <span className={`${Styles.student_corner_text}`}>
                  Question
                </span>
              </div>
              <div className={`${Styles.button_edit} pe-5`}>
                <Button
                  className={`${Styles.button_edit}`}
                  onClick={toggleQuestionEditMode}
                >
                  {isQuestionEditMode ? (
                    <>
                      <span>Edit</span>
                      <img
                        src={grp713}
                        alt="error"
                        className="ps-2"
                        srcSet=""
                      />
                    </>
                  ) : (
                    "Edit"
                  )}
                </Button>
                <span className={`${Styles.drop_down_arrow}`}>
                  <img src={vector132} alt="error" className="ps-2" srcSet="" />
                </span>
              </div>
            </div>
            <form className="ps-5 pe-5 pt-3">
              <div className="form-group row mb-4">
                <div className="col-sm-12">
                  <CommonEditor
                    value={question}
                    placeholder="Enter content ..."
                    onChange={setQuestion}
                    readOnly={editData && !isQuestionEditMode}
                    className={`${Styles.h_200}`}
                  />
                </div>
              </div>
              <div className="form-group row mb-4 mt-5 pt-3">
                <Label for="inputPassword" className="col-sm-2 col-form-label">
                  Subject<b className="ms-2">:</b>
                </Label>
                <div className="col-sm-6">
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    onChange={handleSubjectChange}
                    value={selectedSubject}
                    disabled={editData && !isQuestionEditMode}
                  >
                    <option value="">
                      {editData
                        ? editData?.subjectId
                          ? editData?.subjectId.questionSubject
                          : "Please select subject"
                        : "Please select subject"}
                    </option>
                    {subjects
                      ?.sort((a, b) =>
                        a.questionSubject.localeCompare(b.questionSubject)
                      )
                      .map((subject) => (
                        <option key={subject._id} value={subject._id}>
                          {subject.questionSubject}
                        </option>
                      ))}
                  </Input>

                  {/* const handleSubjectChange = (selectedOption) => {
                    setSelectedSubject(selectedOption.value);
    
  }; */}
                  {/* <Select
                    id="exampleSelect"
                    name="select"
                    onChange={handleSubjectChange}
                    value={selectedSubject ? { value: selectedSubject, label: selectedSubject } : null}
                    options={subjects.map(subject => ({
                      value: subject._id,
                      label: subject.questionSubject
                    }))}
                    isDisabled={!isQuestionEditMode}
                    placeholder="Please select subject"
                  /> */}
                </div>
              </div>
              <div className="form-group row mb-4">
                <Label for="inputPassword" className="col-sm-2 col-form-label">
                  Question Type<b className="ms-2">:</b>
                </Label>
                <div className="col-sm-6">
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    onChange={handleQuestionTypeChange}
                    value={selectedQuestionType}
                    disabled={editData && !isQuestionEditMode}
                  >
                    <option value="">
                      {editData
                        ? editData?.subCategoryId
                          ? editData?.subCategoryId.name
                          : "Please select question type"
                        : "Please select question type"}
                    </option>
                    {questionTypes?.map((questionType) => (
                      <option key={questionType._id} value={questionType._id}>
                        {questionType.name}
                      </option>
                    ))}
                  </Input>
                </div>
              </div>
            </form>
            {/* <div
              className={`d-flex justify-content-around ${Styles.quesion_color} p-3`}
            >
              <div className="d-flex">
                <div
                  className={`d-flex justify-content-around align-items-center ${Styles.question_right
                    } me-4 ${selectedQuestionOption === "dontShow"
                      ? Styles.selectedOption
                      : ""
                    }`}
                  onClick={() => handleQuestionOptionClick("dontShow")}
                >
                  <p className="pt-3">Don’t Show Question</p>
                  <img src={iconaction} alt="" />
                </div>
                <div
                  className={`d-flex justify-content-around align-items-center ${Styles.question_right
                    } ${selectedQuestionOption === "show"
                      ? Styles.selectedOption
                      : ""
                    }`}
                  onClick={() => handleQuestionOptionClick("show")}
                >
                  <p className="pt-3">Show Question</p>
                  <img src={grp663} alt="" />
                </div>
              </div>
            </div> */}
            <div className="d-flex justify-content-between mt-4">
              <div className="ps-5 pt-2">
                <span className={`${Styles.student_corner_text}`}>Answer</span>
              </div>
              <div className={`${Styles.button_edit} pe-5`}>
                <Button
                  className={`${Styles.button_edit}`}
                  onClick={toggleAnswerEditMode}
                >
                  {isAnswerEditMode ? (
                    <>
                      <span>Edit</span>
                      <img
                        src={grp713}
                        alt="error"
                        className="ps-2"
                        srcSet=""
                      />
                    </>
                  ) : (
                    "Edit"
                  )}
                </Button>
                <span className={`${Styles.drop_down_arrow}`}>
                  <img src={vector132} alt="error" className="ps-2" srcSet="" />
                </span>
              </div>
            </div>
            <form className="ps-5 pe-5 pt-3">
              <div className="form-group row mb-4">
                <div className="col-sm-12">
                  <CommonEditor
                    value={answer}
                    placeholder="Enter content ..."
                    onChange={setAnswer}
                    readOnly={editData && !isQuestionEditMode}
                    className={`${Styles.h_200}`}
                  />
                </div>
              </div>
            </form>
            <br />
            <br />
            <div className="mb-4 text-center mt-4">
              <Button
                className="action-button add-from-new"
                onClick={handleAddClick}
              >
                {/* <img src={pluscircle} alt="plus" width={30} /> */}
                <span className="mx-2"> Update</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Viewsubmitquery;
