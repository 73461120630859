import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Styles from "../homework/assets/Form.module.css";
import delete1 from "../homework/assets/images/delete.png";
import "./Assignment.scss";
import axiosInstance from "../../axiosInterceptor";
import { toast } from "react-toastify";
import { dateFormat } from "../../dateFormat";
import { DateRangePicker } from "rsuite";
import "rsuite/DateRangePicker/styles/index.css";

const Assignmentlist = () => {
  const navigator = useNavigate();

  const [filters, setFilters] = useState({
    searchTerm: "",
    activeButton: "Unpaid",
    loading: false,
    // fromDate: new Date().toISOString().split('T')[0],
    // toDate: new Date().toISOString().split('T')[0],
    fromDate: null,
    toDate: null,
    listData: [],
    totalPages: 0,
    currentPage: 1,
    remainingTime: {},
  });
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const {
    searchTerm,
    activeButton,
    loading,
    fromDate,
    toDate,
    listData,
    totalPages,
    currentPage,
    remainingTime,
  } = filters;

  const statusData = listData?.filter(
    (item) => item.studentPaymentStatus === activeButton
  );

  const handleButtonClick = (buttonType) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      activeButton: buttonType,
    }));
  };

  const fetchData = async (page = 1) => {
    try {
      setFilters((prevFilters) => ({
        ...prevFilters,
        loading: true,
      }));

      const response = await axiosInstance.post(
        `admin/assignmentHelp/getQuestion?page=${page}`,
        {
          search: searchTerm,
          fromDate: fromDate, // Changed fromDate to use state
          toDate: toDate, // Changed toDate to use state
          status: activeButton,
          perPage: 10,
          pageNo: page,
        }
      );

      const { data, totalPages } = response.data;

      setFilters((prevFilters) => ({
        ...prevFilters,
        listData: data,
        totalPages: totalPages,
        currentPage: page,
      }));
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      setFilters((prevFilters) => ({
        ...prevFilters,
        loading: false,
      }));
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchTerm, fromDate, toDate, activeButton]);

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      fetchData(page);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      const updatedPageData = listData?.map((order) => {
        const remainingTime = calculateRemainingTime(
          new Date(order.deadlineDate).getTime()
        );
        return { ...order, remainingTime };
      });
      setFilters((prevFilters) => ({
        ...prevFilters,
        listData: updatedPageData,
      }));
    }, 1000);

    return () => clearInterval(interval);
  }, [listData]);

  const formatTime = (time) => {
    return time < 10 ? "0" + time : time;
  };

  const calculateRemainingTime = (deadlineDate) => {
    const now = new Date().getTime();
    const difference = deadlineDate - now;

    // Calculate the remaining hours, minutes, and seconds
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return {
      hours,
      minutes,
      seconds,
    };
  };

  const handleDelete = async (id) => {
    setDeleteItemId(id);
    setModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await axiosInstance.delete(
        `admin/Assignmenthelp/deleteQuestion/${deleteItemId}`
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      setDeleteItemId(null);
      setModalOpen(false);
    }
  };

  return (
    <div>
      <div className="my-2">
        <div className="row">
          <div className="col-sm-6">
            <h4 className="page-title">Assignment List</h4>
          </div>
          <div className="col-sm-6 text-end">
            <Button
              className={`mx-2 ${activeButton === "Unpaid" ? "active" : ""}`}
              style={{
                backgroundColor: activeButton === "Unpaid" ? "#5956E9" : "#fff",
                color: activeButton === "Unpaid" ? "#fff" : "#000",
              }}
              onClick={() => handleButtonClick("Unpaid")}
            >
              Unpaid
            </Button>
            <Button
              className={`mx-2 ${activeButton === "Paid" ? "active" : ""}`}
              style={{
                backgroundColor: activeButton === "Paid" ? "#5956E9" : "#fff",
                color: activeButton === "Paid" ? "#fff" : "#000",
              }}
              onClick={() => handleButtonClick("Paid")}
            >
              Paid
            </Button>
            <Button
              className={`mx-2 ${activeButton === "Completed" ? "active" : ""}`}
              style={{
                backgroundColor:
                  activeButton === "Completed" ? "#5956E9" : "#fff",
                color: activeButton === "Completed" ? "#fff" : "#000",
              }}
              onClick={() => handleButtonClick("Completed")}
            >
              Completed
            </Button>
            <Button
              className={`mx-2 ${activeButton === "Refund" ? "active" : ""}`}
              style={{
                backgroundColor: activeButton === "Refund" ? "#5956E9" : "#fff",
                color: activeButton === "Refund" ? "#fff" : "#000",
              }}
              onClick={() => handleButtonClick("Refund")}
            >
              Refund
            </Button>
            {/* <Button
                            className={`mx-2 ${activeButton === 'Close' ? 'active' : ''}`}
                            style={{
                                backgroundColor: activeButton === 'Close' ? '#5956E9' : '#fff',
                                color: activeButton === 'Close' ? '#fff' : '#000',
                            }}
                            onClick={() => handleButtonClick('Close')}
                        >
                            Close
                        </Button> */}
          </div>
        </div>
        <Card className="mt-2">
          <CardBody>
            <Row>
              <Col md="6">
                <DateRangePicker
                  className="w-100"
                  onChange={(value) => {
                    if (value && value[0]) {
                      const newFromDate = value[0].toISOString().split("T")[0];
                      const newToDate = value[1]
                        ? value[1].toISOString().split("T")[0]
                        : newFromDate;
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        fromDate: newFromDate,
                        toDate: newToDate,
                      }));
                    }
                  }}
                  onClean={() => {
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      fromDate: null,
                      toDate: null,
                    }));
                  }}
                />
              </Col>
              <Col md="6">
                <InputGroup className="search_input_icon">
                  <Input
                    type="text"
                    id="fname"
                    placeholder="Order ID"
                    className="border_none_icon"
                    name="fname"
                    value={searchTerm}
                    onChange={(e) =>
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        searchTerm: e.target.value,
                      }))
                    }
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        fetchData();
                      }
                    }}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text wallte">
                      <FaSearch />
                    </span>
                  </div>
                </InputGroup>
              </Col>
              {/* <Col md="2">
                                <Label className='date_padding float-end'>Filter by:</Label>
                            </Col>
                            <Col md="3">
                                <Input
                                    id="exampleSelect"
                                    name="categoryId"
                                    type="select"
                                    className='date_padding'
                                >
                                    <option value="">--- Select category ---</option>
                                    <option value="1">todays's orders</option>
                                    <option value="2">last 7 days orders</option>
                                    <option value="3">this month orders</option>
                                    <option value="4">last month orders</option>
                                    <option value="5">life time orders</option>
                                </Input>

                            </Col> */}
              {/* <Col md="4">
                                <Input
                                    type="date"
                                    id="inputDate"
                                    className="form-control date_padding"
                                    value={dateFilter}
                                    onChange={(e) => setFilters(prevFilters => ({ ...prevFilters, dateFilter: e.target.value }))}
                                />
                            </Col> */}
            </Row>
            {loading ? (
              <div className="text-center mt-4">
                <p>Loading...</p>
              </div>
            ) : statusData?.length > 0 ? (
              <div className="table-responsive">
                <Table
                  bordered
                  responsive
                  className="mt-4"
                  style={{ fontSize: "0.8rem" }}
                >
                  <thead>
                    <tr className="text-center font-small-head">
                      <th>Date & Time</th>
                      {/* <th>Email</th> */}
                      <th>Order ID</th>
                      <th>Type</th>
                      <th>Timer</th>
                      <th>Deadline</th>
                      <th>Payment</th>
                      <th>Status</th>
                      <th>Earning</th>
                      <th>Refund</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: "0.8rem" }}>
                    {statusData.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {dateFormat(item.updatedAt)}
                          <br />
                          {item?.studentId?.email}
                        </td>
                        {/* <td>{item.dateAndTime}</td> */}
                        <td className="text-center">{item.displayId}</td>
                        <td className="text-center">
                          {item.subCategoryId.name}
                        </td>
                        {/* <td>
                                                <div className="d-flex justify-content-around">
                                                    <div className="border">
                                                        <p className="pt-2">{formatTime(item.remainingTime?.hours)}</p>
                                                        <p className="timer">HRS</p>
                                                    </div>
                                                    <div className="border">
                                                        <p className="pt-2">{formatTime(item.remainingTime?.minutes)}</p>
                                                        <p className="timer">MIN</p>
                                                    </div>
                                                    <div className="border">
                                                        <p className="pt-2">{formatTime(item.remainingTime?.seconds)}</p>
                                                        <p className="timer">SEC</p>
                                                    </div>
                                                </div>
                                            </td> */}
                        <td>
                          {item.questionStatus !== "Solved" &&
                            item.studentPaymentStatus !== "Refund" && (
                              <div className="d-flex justify-content-around">
                                {item?.remainingTime?.hours >= 0 && (
                                  <div className="border">
                                    <p className="pt-2">
                                      {formatTime(item.remainingTime?.hours)}
                                    </p>
                                    <p className="timer">HRS</p>
                                  </div>
                                )}
                                {item?.remainingTime?.minutes >= 0 && (
                                  <div className="border">
                                    <p className="pt-2">
                                      {formatTime(item.remainingTime?.minutes)}
                                    </p>
                                    <p className="timer">MIN</p>
                                  </div>
                                )}
                                {item?.remainingTime?.seconds >= 0 && (
                                  <div className="border">
                                    <p className="pt-2">
                                      {formatTime(item.remainingTime?.seconds)}
                                    </p>
                                    <p className="timer">SEC</p>
                                  </div>
                                )}
                                {item?.remainingTime?.hours <= 0 &&
                                  item?.remainingTime?.minutes <= 0 &&
                                  item?.remainingTime?.seconds <= 0 && (
                                    <td className="deadline d-flex justify-content-center">
                                      Deadline Over
                                    </td>
                                  )}
                              </div>
                            )}
                          {(item.questionStatus === "Solved" ||
                            item.studentPaymentStatus === "Refund") && (
                            <td className="deadline d-flex justify-content-center">
                              Deadline Over
                            </td>
                          )}
                        </td>

                        <td className="text-center">
                          {item.deadlineHours} Hrs
                        </td>
                        <td className="text-center">{item.paymentMethod}</td>
                        <td className="text-center">
                          {item.studentPaymentStatus}
                        </td>
                        <td className="studentamoun text-center">
                          ${item.studentAmount?.toFixed(2)}
                        </td>
                        <td className="studentamoun text-center">
                          ${item.refundAmount?.toFixed(2)}
                        </td>
                        <td className="d-flex justify-content-center">
                          <Button
                            className="action-button"
                            onClick={() => {
                              navigator("/assignment-form", { state: item });
                            }}
                          >
                            View
                          </Button>
                          {/* <div className='me-2 mx-2'>
                                                    <Button className='delete_ques' onClick={() => handleDelete(item._id)}>
                                                        Delete
                                                    </Button>
                                                </div> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : (
              <div className="text-center mt-4">
                <p>No data found.</p>
              </div>
            )}
            <div className="float-end">
              <Pagination>
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink
                    previous
                    onClick={() => handlePageClick(currentPage - 1)}
                  />
                </PaginationItem>
                {[...Array(totalPages)].map((_, i) => (
                  <PaginationItem key={i} active={i + 1 === currentPage}>
                    <PaginationLink onClick={() => handlePageClick(i + 1)}>
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink
                    next
                    onClick={() => handlePageClick(currentPage + 1)}
                  />
                </PaginationItem>
              </Pagination>
            </div>
          </CardBody>
        </Card>
      </div>
      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        style={{ top: "33%" }}
      >
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
          Confirm Delete
        </ModalHeader>
        <ModalBody>Are you sure you want to delete this order?</ModalBody>
        <ModalFooter>
          <div className={`${Styles.button_color}`}>
            <Button type="cancel" onClick={() => setModalOpen(!modalOpen)}>
              No
            </Button>
          </div>
          <div className={`${Styles.cancel_button} me-4`}>
            <Button
              type="cancel"
              className={`${Styles.cancel_button}`}
              onClick={confirmDelete}
            >
              Yes
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Assignmentlist;
