import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Col, Input, Label, Row } from 'reactstrap';
import Styles from '../homework/assets/Form.module.css';
import grp713 from '../homework/assets/images/Group 713.png'
import vector132 from '../homework/assets/images/Vector 132.png'
import axiosInstance from "../../axiosInterceptor";
import { toast } from 'react-toastify';

const Librarysubscriptionplan = () => {

    // const [planData, setPlanData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [editedPlanData, setEditedPlanData] = useState(null);
    const [editMode, setEditMode] = useState(false);

    const fetchPlanData = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`admin/library/getPlan`);
            if (response.data.isSuccess) {
                // setPlanData(response.data.data);
                setEditedPlanData(response.data.data);
            } else {
                toast.error('Something went wrong. Please try again!!');
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (event, field) => {
        setEditedPlanData({ ...editedPlanData, [field]: event.target.value });
    };

    const handleSubmit = async () => {
        try {
            const { _id, createdAt, updatedAt, __v, ...updatedPlanData } = editedPlanData;
            const response = await axiosInstance.put(`admin/library/updatePlan`, updatedPlanData);
            if (response.data.isSuccess) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    useEffect(() => {
        fetchPlanData();
    }, []);

    return (
        <div>
            <div className="mt-4 mb-4">
                <div className='row'>
                    <div className='col-sm-6'>
                        <h4 className="page-title">Library-Subscription Plan </h4>
                    </div>
                    {/* <div className='col-sm-6 text-end'>
                        <Button>Back</Button>
                    </div> */}
                </div>
                <Row className='mt-4'>
                    <Col xs="12" className="grid-margin stretch-card">
                        <Card>
                            {loading ? (
                                <div className="text-center mt-4">
                                    <p>Loading...</p>
                                </div>
                            ) : (
                                <CardBody>
                                    <div className="d-flex justify-content-between ">
                                        <div className="ps-5 pt-2">
                                            <span className={`${Styles.student_corner_text}`}>Plans</span>
                                        </div>
                                        <div className={`${Styles.button_edit} pe-5`}>
                                            <Button className={`${Styles.button_edit}`} onClick={() => setEditMode(!editMode)}>
                                                {editMode ? "Cancel" : "Edit"}
                                                <img
                                                    src={grp713}
                                                    alt="error"
                                                    className="ps-2"
                                                    srcSet=""
                                                />
                                            </Button>
                                            <span className={`${Styles.drop_down_arrow}`}>

                                                <img
                                                    src={vector132}
                                                    alt="error"
                                                    className="ps-2"
                                                    srcSet=""
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <form className="ps-5 pe-5 pt-3">
                                        <div className="form-group row mb-4 mt-4">
                                            <Label for="inputPassword" className="col-sm-2 col-form-label">
                                                Weekly<b className="ms-2">:</b>
                                            </Label>
                                            <div className="col-sm-6 d-flex align-items-center">
                                                <span className={`${Styles.plandoll} me-1`}>$</span>
                                                <Input
                                                    type="text"
                                                    className={`${Styles.plandoll_input} form-control`}
                                                    id="inputWeekly"
                                                    value={editedPlanData ? `${editedPlanData.weekly?.toFixed(2)}` : ''}
                                                    onChange={(e) => handleInputChange(e, 'weekly')}
                                                    disabled={!editMode}
                                                />
                                            </div>

                                        </div>
                                        <div className="form-group row mb-4 mt-4">
                                            <Label for="inputPassword" className="col-sm-2 col-form-label">
                                                Monthly<b className="ms-2">:</b>
                                            </Label>
                                            <div className="col-sm-6 d-flex align-items-center">
                                                <span className={`${Styles.plandoll} me-1`}>$</span>
                                                <Input
                                                    type="text"
                                                    className={`${Styles.plandoll_input} form-control`}
                                                    id="inputPassword"
                                                    value={editedPlanData ? `${editedPlanData.monthly?.toFixed(2)}` : ''}
                                                    onChange={(e) => handleInputChange(e, 'monthly')}
                                                    disabled={!editMode}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 mt-4">
                                            <Label for="inputPassword" className="col-sm-2 col-form-label">
                                                3 Months<b className="ms-2">:</b>
                                            </Label>
                                            <div className="col-sm-6 d-flex align-items-center">
                                                <span className={`${Styles.plandoll} me-1`}>$</span>
                                                <Input
                                                    type="text"
                                                    className={`${Styles.plandoll_input} form-control`}
                                                    id="inputPassword"
                                                    value={editedPlanData ? `${editedPlanData.threeMonths?.toFixed(2)}` : ''}
                                                    onChange={(e) => handleInputChange(e, 'threeMonths')}
                                                    disabled={!editMode}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 mt-4">
                                            <Label for="inputPassword" className="col-sm-2 col-form-label">
                                                6 Months<b className="ms-2">:</b>
                                            </Label>
                                            <div className="col-sm-6 d-flex align-items-center">
                                                <span className={`${Styles.plandoll} me-1`}>$</span>
                                                <Input
                                                    type="text"
                                                    className={`${Styles.plandoll_input} form-control`}
                                                    id="inputPassword"
                                                    value={editedPlanData ? `${editedPlanData.sixMonths?.toFixed(2)}` : ''}
                                                    onChange={(e) => handleInputChange(e, 'sixMonths')}
                                                    disabled={!editMode}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 mt-4">
                                            <Label for="inputPassword" className="col-sm-2 col-form-label">
                                                9 Months<b className="ms-2">:</b>
                                            </Label>
                                            <div className="col-sm-6 d-flex align-items-center">
                                                <span className={`${Styles.plandoll} me-1`}>$</span>
                                                <Input
                                                    type="text"
                                                    className={`${Styles.plandoll_input} form-control`}
                                                    id="inputPassword"
                                                    value={editedPlanData ? `${editedPlanData.nineMonths?.toFixed(2)}` : ''}
                                                    onChange={(e) => handleInputChange(e, 'nineMonths')}
                                                    disabled={!editMode}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 mt-4">
                                            <Label for="inputPassword" className="col-sm-2 col-form-label">
                                                12  Months<b className="ms-2">:</b>
                                            </Label>
                                            <div className="col-sm-6 d-flex align-items-center">
                                                <span className={`${Styles.plandoll} me-1`}>$</span>
                                                <Input
                                                    type="text"
                                                    className={`${Styles.plandoll_input} form-control`}
                                                    id="inputPassword"
                                                    value={editedPlanData ? `${editedPlanData.twelveMonths?.toFixed(2)}` : ''}
                                                    onChange={(e) => handleInputChange(e, 'twelveMonths')}
                                                    disabled={!editMode}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 mt-4">
                                            <Label for="inputPassword" className="col-sm-2 col-form-label">
                                                2 Year<b className="ms-2">:</b>
                                            </Label>
                                            <div className="col-sm-6 d-flex align-items-center">
                                                <span className={`${Styles.plandoll} me-1`}>$</span>
                                                <Input
                                                    type="text"
                                                    className={`${Styles.plandoll_input} form-control`}
                                                    id="inputPassword"
                                                    value={editedPlanData ? `${editedPlanData.twoYear?.toFixed(2)}` : ''}
                                                    onChange={(e) => handleInputChange(e, 'twoYear')}
                                                    disabled={!editMode}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4">
                                            <Label for="inputPassword" className="col-sm-2 col-form-label">
                                                <b className="ms-2"></b>
                                            </Label>
                                            <div className="col-sm-10 d-flex">
                                                <div className={`${Styles.cancel_button} me-4`}>
                                                    <Button onClick={() => setEditMode(false)} disabled={!editMode} className={`${Styles.cancel_button}`}>Cancel</Button>
                                                </div>
                                                <div className={`${Styles.submit_button}`}>
                                                    <Button onClick={handleSubmit} disabled={!editMode || loading}>
                                                        {loading ? 'Submitting...' : 'Submit'}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </CardBody>
                            )}
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Librarysubscriptionplan 