import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Styles from "../homework/assets/Form.module.css";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInterceptor";

const AddPageAction = () => {
  const location = useLocation();
  const editData = location.state;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [page, setPage] = useState([]);
  const navigator = useNavigate();

  const [data, setData] = useState({
    actionType: editData?.actionType || "",
    pageId: editData?.pageId._id || "",
  });

  const handleChange = (e) => {
    const { name, value, checked, files } = e.target;
    if (name !== "isActive") {
      setData({ ...data, [name]: name === "image" ? files[0] : value });
    } else {
      setData({ ...data, [name]: checked });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!data.pageId || !data.actionType) {
      return;
    }
    try {
      const response = await axiosInstance.post(
        `admin/pageAction/createPageAction`,
        {
          pageId: data.pageId,
          actionType: data.actionType,
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/page-action");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.put(
        `admin/pageAction/updatePageAction/${editData?._id}`,
        {
          pageId: data.pageId,
          actionType: data.actionType,
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/page-action");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  const fetchPage = async () => {
    try {
      const response = await axiosInstance.get(
        `admin/page/listAllPageWithoutPagination`
      );
      if (response.data.isSuccess) {
        setPage(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  useEffect(() => {
    fetchPage();
  }, []);

  return (
    <div>
      <div className="mt-4 mb-4">
        <div className="row">
          <div className="col-sm-6">
            <h4 className="page-title">Add Page Action</h4>
          </div>
        </div>
        <Card className="mt-4">
          <CardBody>
            <Row>
              <Col md="8">
                <form
                  className="ps-5 pe-5 pt-3"
                  onSubmit={editData?._id ? handleUpdate : handleSubmit}
                >
                  <div className="form-group row mb-2">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      Page
                    </Label>
                    <div className="col-sm-8">
                      <FormGroup>
                        <Input
                          id="exampleSelect"
                          name="pageId"
                          type="select"
                          invalid={!data.pageId && formSubmitted}
                          defaultValue={
                            editData
                              ? editData?.pageId === data.pageId
                                ? data.pageId
                                : ""
                              : ""
                          }
                          onChange={(e) => handleChange(e)}
                        >
                          <option value="">
                            {editData
                              ? editData?.pageId._id === data.pageId
                                ? page.find(
                                    (subcat) => subcat._id === data.pageId
                                  )?.pageName
                                : "--- Select page ---"
                              : "--- Select page ---"}
                          </option>
                          {page?.map((subcat) => (
                            <option key={subcat._id} value={subcat._id}>
                              {subcat.pageName}
                            </option>
                          ))}
                        </Input>
                        <FormFeedback>Page is required</FormFeedback>
                      </FormGroup>
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      Action Type
                    </Label>
                    <div className="col-sm-8">
                      <Input
                        type="text"
                        className="form-control"
                        id="inputPassword"
                        name="actionType"
                        placeholder="Enter your action type"
                        invalid={!data.actionType && formSubmitted}
                        value={data.actionType}
                        onChange={(e) => handleChange(e)}
                      />
                      <FormFeedback>Action type is required</FormFeedback>
                    </div>
                  </div>
                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      <b className="ms-2"></b>
                    </Label>
                    <div className="col-sm-7 d-flex">
                      <div className={`${Styles.submit_button}`}>
                        <Button type="submit">
                          {editData?._id ? "Update" : "Save"}
                        </Button>
                      </div>
                      <div className={`${Styles.cancel_button} me-4 mx-2`}>
                        <Link to="/page-action">
                          {" "}
                          <Button className={`${Styles.cancel_button}`}>
                            Cancel
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AddPageAction;
