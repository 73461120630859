import React, { useState, useEffect, useRef } from "react";
import Styles from "./assets/Navbar.module.css";
import Logo from "../assets/Group 670.png";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../axiosInterceptor";

export default function Navbar() {
  const location = useLocation().pathname;
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const token = localStorage.getItem("authToken")
  const dropdownRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  const [profileData, setProfileData] = useState(null);
  useEffect(() => {
    axiosInstance.get('admin/getProfile')
      .then(response => {
        if (response.data.isSuccess) {
          setProfileData(response.data.profile);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch(error => {
        toast.error('Something went wrong. Please try again!!');
      });
  }, []);


  return (
    <>
      <nav
        className={Styles.navbar}
        style={{
          position: "sticky",
          top: 0,
          backgroundColor: "#ffff",
          zIndex: "555",
          borderBottom: "1px solid #c5c5c5"
        }}
      >
        <div className={Styles.box} ref={dropdownRef}>
          <button className={Styles.dropdownToggle} onClick={handleDropdownToggle}>
            <img src={Logo} alt="admin" width={35} />
            <span className="mx-2">{profileData?.fullName || 'Admin'}</span>
          </button>
          {isDropdownOpen && (
            <ul className={Styles.dropdownContent}>
              {/* Add your dropdown content here */}
              <Link to='/change-password' className={Styles.dropdownLink}>
                <li className={Styles.list} >Change Password</li>
              </Link>
              <Link to="/profile" className={Styles.dropdownLink}>
                <li className={Styles.list} >Profile</li>
              </Link>
              <Link to='#' className={Styles.dropdownLink}>
                <li className={Styles.list} onClick={handleLogout}>Logout</li>
              </Link>
            </ul>
          )}
        </div>
      </nav>
    </>
  );
}
