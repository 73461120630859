import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap'
import Styles from '../homework/assets/Form.module.css'
import { toast } from 'react-toastify'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../../axiosInterceptor'
import { ImageLink } from '../../constants'

const AddBenefit = () => {
    const location = useLocation();
    const editData = location.state;
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [subCategoryData, setSubCategoryData] = useState([]);
    const navigator = useNavigate();

    const [data, setData] = useState({
        subCategoryId: editData?.subCategoryId._id || '',
        mainTitle: editData?.mainTitle || '',
        title: editData?.title || '',
        description: editData?.description || '',
        image: editData?.image || null,
    })

    const handleChange = (e) => {
        const { name, files } = e.target;
        if (name === 'image') {
            setData({ ...data, [name]: files.length > 0 ? files[0] : null, });
        } else {
            setData({
                ...data, [name]: e.target.value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        if (!data.subCategoryId || !data.description || !data.title || !data.mainTitle || !data.image) {
            return;
        }
        try {
            const formData = new FormData();
            formData.append('subCategoryId', data.subCategoryId);
            formData.append('title', data.title);
            formData.append('description', data.description);
            formData.append('mainTitle', data.mainTitle);
            formData.append('image', data.image);
            const response = await axiosInstance.post(`admin/benefits/createBenefit`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/benefits");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
            toast.error('Something went wrong. Please try again!!');
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('subCategoryId', data.subCategoryId);
            formData.append('title', data.title);
            formData.append('description', data.description);
            formData.append('mainTitle', data.mainTitle);
            if (data.image instanceof File) {
                formData.append('image', data.image);
            }

            const response = await axiosInstance.put(`admin/benefits/updateBenefit/${editData._id}`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/benefits");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
            toast.error('Something went wrong. Please try again!!');
        }
    };

    const fetchData = async (page = 1) => {
        try {
            const response = await axiosInstance.get(
                `admin/subcategory/getAllSubcategory`,
            );
            const { data } = response.data;
            setSubCategoryData(data);
        } catch (error) {
            console.error(error);
            toast.error('Something went wrong. Please try again!!');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <div className="mt-4 mb-4">
                <div className='row'>
                    <div className='col-sm-6'>
                        <h4 className="page-title">Add Benefits</h4>
                    </div>
                </div>
                <Card className='mt-4'>
                    <CardBody>
                        <Row>
                            <Col md="8">
                                <form className="ps-5 pe-5 pt-3" onSubmit={editData?._id ? handleUpdate : handleSubmit}>
                                    <div className="form-group row mb-2">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Sub Category
                                        </Label>
                                        <div className="col-sm-8">
                                            <FormGroup>
                                                <Input
                                                    id="exampleSelect"
                                                    name="subCategoryId"
                                                    type="select"
                                                    invalid={!editData && !data.subCategoryId && formSubmitted}
                                                    defaultValue={editData ? (editData?.subCategoryId._id === data.subCategoryId ? data.subCategoryId : '') : ''}
                                                    onChange={(e) => handleChange(e)}
                                                >
                                                    <option value="">
                                                        {editData ? (editData?.subCategoryId._id === data.subCategoryId ? subCategoryData.find(subcat => subcat._id === data.subCategoryId)?.name : '--- Select sub category ---') : '--- Select sub category ---'}
                                                    </option>
                                                    {subCategoryData?.map(subcat => (
                                                        <option key={subcat._id} value={subcat._id}>{subcat.name}</option>
                                                    ))}
                                                </Input>
                                                <FormFeedback>Sub category is required</FormFeedback>
                                            </FormGroup>
                                        </div>
                                    </div>


                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Main Title</Label>
                                        <div className="col-sm-8">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="inputPassword"
                                                placeholder="Enter your main title"
                                                name='mainTitle'
                                                invalid={!editData && !data.mainTitle && formSubmitted}
                                                defaultValue={data.mainTitle}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <FormFeedback>Main title is required</FormFeedback>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Title</Label>
                                        <div className="col-sm-8">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="inputPassword"
                                                placeholder="Enter your title"
                                                name='title'
                                                invalid={!editData && !data.title && formSubmitted}
                                                defaultValue={data.title}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <FormFeedback>Title name is required</FormFeedback>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Description </Label>
                                        <div className="col-sm-8">
                                            <Input
                                                type="textarea"
                                                className="form-control"
                                                id="inputPassword"
                                                placeholder="Enter your description"
                                                name='description'
                                                invalid={!editData && !data.description && formSubmitted}
                                                value={data.description}
                                                onChange={(e) => handleChange(e)}
                                                rows={5}
                                            />
                                            <FormFeedback>Subcategory description is required</FormFeedback>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Image </Label>
                                        <div className="col-sm-8">
                                            <Input
                                                type="file"
                                                className="form-control"
                                                id="inputPassword"
                                                name='image'
                                                invalid={!editData && !data.image && formSubmitted}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <FormFeedback>Banner image is required</FormFeedback>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            <b className="ms-2"></b>
                                        </Label>
                                        <div className="col-sm-7 d-flex">
                                            <div className={`${Styles.submit_button}`}>
                                                <Button type='submit'>{editData?._id ? "Update" : "Save"}</Button>
                                            </div>
                                            <div className={`${Styles.cancel_button} me-4 mx-2`}>
                                                <Link to='/benefits'> <Button className={`${Styles.cancel_button}`}>Cancel</Button></Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Col>

                            {data.image &&
                                <Col md="4">
                                    <div className='border w-100 h-75 text-center'>
                                        <p className='mt-3'>Image Preview</p>
                                        <br />
                                        {data.image instanceof File ? (
                                            <img
                                                src={URL.createObjectURL(data.image)}
                                                alt="Preview"
                                                className="w-50"
                                            />
                                        ) : (
                                            <img src={ImageLink(data.image)} className="w-50" alt='data_img'/>
                                        )
                                        }

                                    </div>
                                </Col>}
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default AddBenefit;