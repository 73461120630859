import React, { useEffect, useRef, useState } from "react";
import Styles from "../homework/assets/Form.module.css";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { dateFormat } from "../../dateFormat";
import ExportFile from "../assignmenthelp/Helper";
import axiosInstance from "../../axiosInterceptor";
import { toast } from "react-toastify";
import CancelModal from "../CancelModal";
import ForPayment from "./ForPayment";
import CommonEditor from "../masters/CommonEditor";

const Chatform = () => {
  const location = useLocation();

  const [editData, setEditData] = useState(location.state);
  const formRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [paymentStatus, setPaymentStatus] = useState([]);
  const [editingEnabled, setEditingEnabled] = useState(false);
  const [question, setQuestion] = useState(editData?.question || "");
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(
    editData?.subjectId._id || ""
  );
  const [remark, setRemark] = useState(editData?.remark || "");
  const [studentPayStatus, setStudentPayStatus] = useState(
    editData?.studentPayStatus || ""
  );
  const [paymentMethod, setPaymentMethod] = useState(
    editData?.paymentMethod || ""
  );
  const [questionStatus, setQuestionStatus] = useState(
    editData?.questionStatus || ""
  );
  const [date, setDate] = useState(editData?.date || "");
  const [time, setTime] = useState(editData?.time || "");
  const [timeZone, setTimeZone] = useState(editData?.timeZone || "");
  const [durationMinutes, setDurationMinutes] = useState(
    editData?.durationMinutes || ""
  );
  const [studentPaymentId, setStudentPaymentId] = useState(
    editData?.paymentId || ""
  );

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const response = await axiosInstance.get(`admin/subject/getAllSubject`);
        if (response.data.isSuccess) {
          setSubjects(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again!!");
      }
    };

    fetchSubjects();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    if (question) {
      formData.append("question", question);
    }
    if (formRef.current?.livesession_reference_file?.files) {
      for (
        let i = 0;
        i < formRef.current.livesession_reference_file.files.length;
        i++
      ) {
        formData.append(
          "questionPDF",
          formRef.current.livesession_reference_file.files[i]
        );
      }
    }
    for (
      let i = 0;
      i < formRef.current.livesession_sample_file.files.length;
      i++
    ) {
      formData.append(
        "referencePDF",
        formRef.current.livesession_sample_file.files[i]
      );
    }

    if (selectedSubject) {
      formData.append("subjectId", selectedSubject);
    }
    if (remark) {
      formData.append("remark", remark);
    }
    if (studentPayStatus) {
      formData.append("studentPaymentStatus", studentPayStatus);
    }
    if (editData?.paymentMethod) {
      formData.append("paymentMethod", paymentMethod);
    }
    formData.append("paymentId", studentPaymentId);
    if (editData?.questionStatus) {
      formData.append("questionStatus", editData.questionStatus);
    }
    if (date) {
      formData.append("date", date);
    }
    if (time) {
      formData.append("time", time);
    }
    if (timeZone) {
      formData.append("timeZone", timeZone);
    }
    if (durationMinutes) {
      formData.append("durationMintues", durationMinutes);
    }
    formData.append("estimatedPrice", editData?.estimatedPrice?.toFixed(2));
    formData.append("discountPrice", editData?.discountPrice?.toFixed(2));

    try {
      setIsLoading(true);
      const response = await axiosInstance.put(
        `admin/liveSessionHelp/updateQuestion/${editData._id}`,
        formData
      );
      if (response.data.isSuccess) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      setIsLoading(false);
    }
  };
  const initialQuestionFiles = editData
    ? editData.questionPDF.map((file) => file.split("/").pop())
    : [];
  const initialReferenceFiles = editData
    ? editData.referencePDF.map((file) => file.split("/").pop())
    : [];
  const baseURL = process.env.REACT_APP_FILE_BASE_URL;

  const [selectedQuestionFiles, setSelectedQuestionFiles] =
    useState(initialQuestionFiles);
  const [selectedReferenceFiles, setSelectedReferenceFiles] = useState(
    initialReferenceFiles
  );

  const handleQuestionFileChange = (event) => {
    const files = event.target.files;
    const fileNames = Array.from(files).map((file) => file.name);
    setSelectedQuestionFiles([...selectedQuestionFiles, ...fileNames]);
  };

  const handleReferenceFileChange = (event) => {
    const files = event.target.files;
    const fileNames = Array.from(files).map((file) => file.name);
    setSelectedReferenceFiles([...selectedReferenceFiles, ...fileNames]);
  };

  const handleRemoveQuestionFile = async (index) => {
    const fileToRemove = editData?.questionPDF[index];
    const newFiles = selectedQuestionFiles.filter((_, i) => i !== index);
    newFiles.splice(index, 1);
    setSelectedQuestionFiles(newFiles);
    const requestData = {
      paymentSection: editData?.paymentSection,
      fieldName: "questionPDF",
      questionId: editData?._id,
      fileName: fileToRemove,
    };
    try {
      const response = await axiosInstance.post(
        "admin/deleteFile",
        requestData
      );
      if (response.data.isSuccess) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  const handleRemoveReferenceFile = async (index) => {
    const fileToRemove = editData?.referencePDF[index];
    const newFiles = selectedReferenceFiles.filter((_, i) => i !== index);
    newFiles.splice(index, 1);
    setSelectedReferenceFiles(newFiles);
    const requestData = {
      paymentSection: editData?.paymentSection,
      fieldName: "referencePDF",
      questionId: editData?._id,
      fileName: fileToRemove,
    };
    try {
      const response = await axiosInstance.post(
        "admin/deleteFile",
        requestData
      );
      if (response.data.isSuccess) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  const handleDiscountPriceChange = (value) => {
    setEditData((prevEditData) => ({
      ...prevEditData,
      discountPrice: parseFloat(value), // Parse the value to a float
    }));
  };

  const handleRadioClick = (status) => {
    setEditData((prevEditData) => ({
      ...prevEditData,
      questionStatus: status,
    }));
  };

  const handleSubjectChange = (event) => {
    setSelectedSubject(event.target.value);
  };
  const getCategoryWiseCancelOrder = async () => {
    const response = await axiosInstance.post(
      `/admin/wallet/getCategoryWiseCancelOrder`,
      {
        questionId: editData._id,
        paymentSection: editData.paymentSection,
      }
    );
    setPaymentStatus(response.data);
    if (response.status === 200) {
      toast.success(response.data.message);
    } else {
      toast.error(response.data.error);
    }
  };

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleCancelOrder = () => {
    setShowModal(true);
  };

  const handleResume = () => {
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
    getCategoryWiseCancelOrder();
  };


  const getCurrentDateTime = () => {
    const now = new Date();

    // Get current date components
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
    const day = String(now.getDate()).padStart(2, "0");

    // Get current time components
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    // Combine date and time components into the desired format
    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

    return formattedDateTime;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (editData) {
        const dateTime1 = getCurrentDateTime();
        const inputDate = new Date(editData.deadlineDate);
        const dateTime2 = inputDate.toISOString().slice(0, 19);
        if (dateTime2 > dateTime1) {
          const updatedData = {
            ...editData,
            remainingTime: calculateRemainingTime(
              new Date(editData.deadlineDate).getTime()
            ),
          };
          setEditData(updatedData);
        } else {
          const updatedData = {
            ...editData,
            remainingTime: {
              hours: 0,
              minutes: 0,
              seconds: 0,
            },
          };
          setEditData(updatedData);
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [editData]);

  const formatTime = (time) => {
    return time < 10 ? "0" + time : time;
  };

  const calculateRemainingTime = (deadlineDate) => {
    const now = new Date().getTime();
    const difference = deadlineDate - now;
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return {
      hours,
      minutes,
      seconds,
    };
  };

  return (
    <div>
      <div className={`${Styles.second_container}`}>
        <div className="d-flex justify-content-between">
          <div className={`${Styles.order_text}`}>
            <p className={`${Styles.order_id}`}>
              Order ID: {editData?.displayId}
            </p>
            <p className={`${Styles.assignment_help_text}`}>
              Live Session Help - {editData?.subCategoryId?.name}
            </p>
          </div>
          {editData?.studentPaymentStatus === "Refund" &&
            editData?.questionStatus !== "Close" && (
              <div className={`${Styles.button_color}`}>
                <Button
                  type="cancel"
                  onClick={handleCancelOrder}
                  disabled={
                    paymentStatus.message === "Order close successfully."
                  }
                >
                  Cancel Order
                </Button>
              </div>
            )}
        </div>
        <div className={`bg-white ${Styles.assignment_content}`}>
          <div>
            <div className="d-flex justify-content-between ">
              <div className="d-flex ps-5 pt-5 pb-1 pe-5">
                <p className={`${Styles.time_stamp_heading}`}>Time Stamp</p>
                <p className={`ps-3 ${Styles.date_and_time}`}>
                  {dateFormat(editData?.updatedAt)}
                </p>
              </div>
              <div className="ps-5 pt-5 pb-1 pe-5">
                <div className="d-flex justify-content-around">
                  {editData &&
                    editData.studentPaymentStatus !== "Refund" &&
                    editData.questionStatus !== "Solved" &&
                    editData.remainingTime?.hours !== 0 &&
                    editData.remainingTime?.minutes !== 0 &&
                    editData.remainingTime?.seconds !== 0 && (
                      <div className="d-flex justify-content-around">
                        <div className={`${Styles.border}`}>
                          <p className="pt-2">
                            {formatTime(editData?.remainingTime?.hours)}
                          </p>
                          <p className={`${Styles.timer}`}>HOURS</p>
                        </div>
                        <div className={`${Styles.border} ms-5 me-5`}>
                          <p className="pt-2">
                            {formatTime(editData?.remainingTime?.minutes)}
                          </p>
                          <p className={`${Styles.timer}`}>MINUTES</p>
                        </div>
                        <div className={`${Styles.border}`}>
                          <p className="pt-2">
                            {formatTime(editData?.remainingTime?.seconds)}
                          </p>
                          <p className={`${Styles.timer}`}>SECONDS</p>
                        </div>
                      </div>
                    )}
                </div>
                {editData &&
                  (editData.questionStatus === "Solved" ||
                    (editData.remainingTime?.hours <= 0 &&
                      editData.remainingTime?.minutes <= 0 &&
                      editData.remainingTime?.seconds <= 0)) && (
                    <div className={`${Styles.deadline} ps-5`}>
                      {editData.questionStatus === "Solved"
                        ? "Deadline Over"
                        : "Deadline Over"}
                    </div>
                  )}
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between ">
              <div className="ps-5 pt-2">
                <span className={`${Styles.student_corner_text}`}>
                  Student Corner
                </span>
              </div>
            </div>
            <form ref={formRef} className="pt-3" onSubmit={handleSubmit}>
              <div className="ps-5 pe-5">
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Email ID<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      type="text"
                      className="form-control"
                      id="email"
                      placeholder=""
                      defaultValue={editData?.studentId?.email}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group row mb-4 ">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Question <b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-9">
                    <CommonEditor
                      value={question}
                      placeholder="Enter content ..."
                      onChange={setQuestion}
                      readOnly={
                        editData.studentPaymentStatus === "Completed" ||
                        editData.studentPaymentStatus === "Refund"
                      }
                      className={`${Styles.h_200}`}
                      defaultValue={editData?.question}
                    />
                  </div>
                </div>
                <div className="form-group row mb-4 mt-5 pt-3">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Reference File<b className="ms-2">:</b>
                  </Label>
                  <div
                    className="col-sm-9"
                    style={{
                      backgroundColor: "#F5F5F5",
                      height: "auto",
                      padding: "0px",
                    }}
                  >
                    <Input
                      type="file"
                      name="livesession_reference_file"
                      disabled={
                        editData.studentPaymentStatus === "Completed" ||
                        editData.studentPaymentStatus === "Refund"
                      }
                      multiple
                      onChange={handleQuestionFileChange}
                    />
                    <div className="d-flex align-items-center mt-4">
                      {/* {ExportFile(editData.questionPDF)} */}
                      {selectedQuestionFiles?.length > 0 && (
                        <ol>
                          {selectedQuestionFiles.map((file, index) => (
                            <li className="mb-2 answer-pdf" key={index}>
                              <Link
                                className="text-decoration-none"
                                to={`${baseURL}${editData?.questionPDF[index]}`}
                                target="_blank"
                              >
                                {file}
                              </Link>
                              <button
                                className="mx-2 answer-pdf-box"
                                onClick={() => handleRemoveQuestionFile(index)}
                                disabled={
                                  editData?.studentPaymentStatus ===
                                    "Completed" ||
                                  editData?.studentPaymentStatus === "Refund"
                                }
                              >
                                X
                              </button>
                            </li>
                          ))}
                        </ol>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Sample File<b className="ms-2">:</b>
                  </Label>
                  <div
                    className="col-sm-9"
                    style={{
                      backgroundColor: "#F5F5F5",
                      height: "auto",
                      padding: "0px",
                    }}
                  >
                    <Input
                      type="file"
                      name="livesession_sample_file"
                      disabled={
                        editData.studentPaymentStatus === "Completed" ||
                        editData.studentPaymentStatus === "Refund"
                      }
                      multiple
                      onChange={handleReferenceFileChange}
                    />
                    <div className="d-flex align-items-center mt-4">
                      {/* {ExportFile(editData.referencePDF)} */}
                      {selectedReferenceFiles?.length > 0 && (
                        <ol>
                          {selectedReferenceFiles.map((file, index) => (
                            <li className="mb-2 answer-pdf" key={index}>
                              <Link
                                className="text-decoration-none"
                                to={`${baseURL}${editData?.referencePDF[index]}`}
                                target="_blank"
                              >
                                {file}
                              </Link>
                              <button
                                className="mx-2 answer-pdf-box"
                                onClick={() => handleRemoveReferenceFile(index)}
                                disabled={
                                  editData?.studentPaymentStatus ===
                                    "Completed" ||
                                  editData?.studentPaymentStatus === "Refund"
                                }
                              >
                                X
                              </button>
                            </li>
                          ))}
                        </ol>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-4 mt-5 pt-3">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Subject<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      id="exampleSelect"
                      name="select"
                      type="select"
                      onChange={handleSubjectChange}
                      value={selectedSubject}
                      disabled={
                        editData.studentPaymentStatus === "Completed" ||
                        editData.studentPaymentStatus === "Refund"
                      }
                    >
                      <option value="">
                        {editData
                          ? editData?.subjectId
                            ? editData?.subjectId.questionSubject
                            : "Please select subject"
                          : "Please select subject"}
                      </option>
                      {subjects?.map((subject) => (
                        <option key={subject._id} value={subject._id}>
                          {subject.questionSubject}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>

                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Date<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      type="text"
                      className="form-control"
                      id="date"
                      name="date"
                      onChange={(e) => setDate(e.target.value)}
                      placeholder=""
                      defaultValue={editData?.date}
                      disabled={
                        editData.studentPaymentStatus === "Completed" ||
                        editData.studentPaymentStatus === "Refund"
                      }
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Time<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      type="text"
                      className="form-control"
                      id="time"
                      name="time"
                      placeholder=""
                      onChange={(e) => setTime(e.target.value)}
                      defaultValue={editData?.time}
                      disabled={
                        editData.studentPaymentStatus === "Completed" ||
                        editData.studentPaymentStatus === "Refund"
                      }
                    />
                  </div>
                </div>

                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Time Zone<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      type="text"
                      className="form-control"
                      id="timeZone"
                      name="timeZone"
                      placeholder=""
                      onChange={(e) => setTimeZone(e.target.value)}
                      defaultValue={editData?.timeZone}
                      disabled={
                        editData.studentPaymentStatus === "Completed" ||
                        editData.studentPaymentStatus === "Refund"
                      }
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Duration (Min)<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      type="text"
                      className="form-control"
                      id="durationMinutes"
                      name="durationMinutes"
                      placeholder=""
                      onChange={(e) => setDurationMinutes(e.target.value)}
                      defaultValue={editData?.durationMintues}
                      disabled={
                        editData.studentPaymentStatus === "Completed" ||
                        editData.studentPaymentStatus === "Refund"
                      }
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Remark<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <textarea
                      type="text"
                      className="form-control"
                      id="remark"
                      name="remark"
                      placeholder=""
                      onChange={(e) => setRemark(e.target.value)}
                      defaultValue={editData?.remark}
                      disabled={
                        editData.studentPaymentStatus === "Completed" ||
                        editData.studentPaymentStatus === "Refund"
                      }
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Estimated Price<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6 d-flex align-items-center">
                    <span className={`${Styles.plandoll}`}>$</span>
                    <Input
                      type="text"
                      className="form-control"
                      id="estimatedPrice"
                      name="estimatedPrice"
                      placeholder=""
                      defaultValue={editData?.estimatedPrice?.toFixed(2)}
                      disabled={
                        editData.studentPaymentStatus === "Paid" ||
                        editData.studentPaymentStatus === "Completed" ||
                        editData.studentPaymentStatus === "Refunded"
                      }
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Discounted Price<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6 d-flex align-items-center">
                    <span className={`${Styles.plandoll}`}>$</span>
                    <Input
                      type="text"
                      className="form-control"
                      id="discountPrice"
                      name="discountPrice"
                      placeholder=""
                      defaultValue={editData?.discountPrice?.toFixed(2)}
                      onChange={(e) =>
                        handleDiscountPriceChange(e.target.value)
                      }
                      disabled={
                        editData.studentPaymentStatus === "Paid" ||
                        editData.studentPaymentStatus === "Completed" ||
                        editData.studentPaymentStatus === "Refunded"
                      }
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Promocode Price<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6 d-flex align-items-center">
                    <span className={`${Styles.plandoll}`}>$</span>
                    <Input
                      type="text"
                      className="form-control"
                      id="inputPassword"
                      placeholder=""
                      defaultValue={editData?.promoPrice?.toFixed(2)}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Promocode <b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      type="text"
                      className="form-control"
                      id="inputPassword"
                      placeholder=""
                      defaultValue={editData?.promoCode}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Total Price<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6 d-flex align-items-center">
                    <span className={`${Styles.plandoll}`}>$</span>
                    <Input
                      type="text"
                      className="form-control"
                      id="inputPassword"
                      placeholder=""
                      defaultValue={editData?.totalPrice?.toFixed(2)}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Payment Status<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      type="select"
                      className="form-control"
                      id="studentPaymentStatus"
                      name="studentPaymentStatus"
                      placeholder=""
                      onChange={(e) => setStudentPayStatus(e.target.value)}
                      defaultValue={editData?.studentPaymentStatus}
                      disabled={
                        editData.studentPaymentStatus === "Refund" ||
                        editData.studentPaymentStatus === "Completed"
                      }
                    >
                      <option value="Paid">Paid</option>
                      <option value="Unpaid">Unpaid</option>
                      <option value="Refund">Refund</option>
                      <option value="Completed">Completed</option>
                    </Input>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Payment Method<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      type="select"
                      className="form-control"
                      id="paymentMethod"
                      placeholder=""
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      defaultValue={editData?.paymentMethod}
                      disabled={
                        editData.studentPaymentStatus === "Refund" ||
                        editData.studentPaymentStatus === "Completed"
                      }
                    >
                      <option value="Stripe">Stripe</option>
                      <option value="Razorpay">Razorpay</option>
                    </Input>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <label
                    htmlFor="paymentId"
                    className="col-sm-3 col-form-label"
                  >
                    Payment Id <b className="ms-2">:</b>
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      id="paymentId"
                      defaultValue={editData?.paymentId}
                      disabled={
                        editData?.studentPaymentStatus === "Refund" ||
                        editData?.studentPaymentStatus === "Unpaid" ||
                        editData?.studentPaymentStatus === "Completed"
                      }
                      onChange={(e) => setStudentPaymentId(e.target.value)}
                    />
                  </div>
                </div>
                {editData && editData?.studentPaymentStatus === "Paid" && (
                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-3 col-form-label"
                    >
                      Answering Status<b className="ms-2">:</b>
                    </Label>
                    <div className="col-sm-9">
                      <div className="radio-btn mt-2">
                        <Row>
                          <Col md="3">
                            <FormGroup>
                              <Input
                                name="questionStatus"
                                type="radio"
                                onClick={() => handleRadioClick("Working")}
                                checked={
                                  editData &&
                                  editData.questionStatus === "Working"
                                }
                                disabled={
                                  editData?.studentPaymentStatus === "Refund" ||
                                  editData?.studentPaymentStatus === "Completed"
                                }
                              />
                              <Label className="mx-2">Working</Label>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Input
                                name="questionStatus"
                                type="radio"
                                onClick={() => handleRadioClick("Unsolved")}
                                checked={
                                  editData &&
                                  editData.questionStatus === "Unsolved"
                                }
                                disabled={
                                  editData?.studentPaymentStatus === "Refund" ||
                                  editData?.studentPaymentStatus === "Completed"
                                }
                              />
                              <Label className="mx-2">Not Working</Label>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Input
                                name="questionStatus"
                                type="radio"
                                onClick={() => handleRadioClick("Solved")}
                                checked={
                                  editData &&
                                  editData.questionStatus === "Solved"
                                }
                                disabled={
                                  editData?.studentPaymentStatus === "Refund" ||
                                  editData?.studentPaymentStatus === "Completed"
                                }
                              />
                              <Label className="mx-2">Solved</Label>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Input
                                name="questionStatus"
                                type="radio"
                                onClick={() => handleRadioClick("Close")}
                                checked={
                                  editData &&
                                  editData.questionStatus === "Close"
                                }
                                disabled={
                                  editData?.studentPaymentStatus === "Refund" ||
                                  editData?.studentPaymentStatus === "Completed"
                                }
                              />
                              <Label className="mx-2">Close</Label>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className={`d-flex justify-content-around ${Styles.quesion_color} p-3`}
              >
                <div className="d-flex">
                  <div className={`${Styles.cancel_button} me-4`}>
                    <Button
                      type="button"
                      className={`${Styles.cancel_button}`}
                      onClick={() => setEditingEnabled(false)}
                      disabled={
                        editData.studentPaymentStatus === "Refund" ||
                        editData.studentPaymentStatus === "Completed"
                      }
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={`${Styles.submit_button}`}>
                    <Button
                      type="submit"
                      disabled={
                        editData.studentPaymentStatus === "Refund" ||
                        editData.studentPaymentStatus === "Completed"
                      }
                    >
                      {isLoading ? "Submitting..." : "Submit"}
                    </Button>
                  </div>
                </div>
              </div>
            </form>

            <div className="mt-2">
              <ForPayment />
            </div>
          </div>
        </div>
      </div>

      <CancelModal
        handleClose={handleClose}
        handleResume={handleResume}
        showModal={showModal}
        toggleModal={toggleModal}
      />
    </div>
  );
};

export default Chatform;
