import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import Styles from '../homework/assets/Form.module.css';
import { toast } from 'react-toastify';
import axiosInstance from "../../axiosInterceptor";
import { dateFormat } from "../../dateFormat";
import { Link } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import "rsuite/DateRangePicker/styles/index.css";
import pluscircle from '../homework/assets/images/plus circle_.png'

const Userlist = () => {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [userslist, setUserslist] = useState();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const fetchData = async (page = 1) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post("admin/getAdminUsers", {
        search: searchTerm,
        fromDate: fromDate,
        toDate: toDate,
        perPage: 10,
        pageNo: page,
        email: searchTerm,
      });
      if (response.data.isSuccess) {
        const filteredData = response.data.data.filter((student) =>
          student.email.includes(searchTerm)
        );
        setUserslist(filteredData);
        setTotalPages(response.data.totalPages);
        setCurrentPage(page);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchTerm, fromDate, toDate]);

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      fetchData(page);
    }
  };

  const handleDelete = async (id) => {
    setDeleteItemId(id);
    setModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await axiosInstance.delete(`admin/deleteUser/${deleteItemId}`);
      if (response.status === 200) {
        toast.success(response.data.message);
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again!!');
    } finally {
      setDeleteItemId(null);
      setModalOpen(false);
    }
  };

  return (
    <div className="my-2">
      <div className='row mb-2'>
        <div className='d-flex justify-content-between'>
          <div>
            <h4 className="page-title">Users</h4>
          </div>
          <div>
            <Link to='/add-users'>
              <Button className="action-button add-from-new" >
                <img src={pluscircle} alt="plus" width={20} className='ADD_code' />
                <span className="mx-2">Add User </span>
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <div className="student-list">
        <Card>
          <CardBody>
            <Row>
              <Col md="6">
                <DateRangePicker
                  className="w-100"
                  onChange={(value) => {
                    if (value && value[0]) {
                      setFromDate(value[0].toISOString().split("T")[0]);
                      setToDate(
                        value[1] ? value[1].toISOString().split("T")[0] : ""
                      );
                    }
                  }}
                  onClean={() => {
                    setFromDate(null);
                    setToDate(null);
                  }}
                />
              </Col>
              <Col md="6">
                <InputGroup className="search_input_icon">
                  <Input
                    type="text"
                    id="fname"
                    placeholder="Email Id"
                    name="fname"
                    className="border_none_icon"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        fetchData();
                      }
                    }}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text wallte">
                      <FaSearch />
                    </span>
                  </div>
                </InputGroup>
              </Col>
            </Row>
            {loading ? (
              <div className="text-center mt-4">
                <p>Loading...</p>
              </div>
            ) : userslist?.length > 0 ? (
              <div className="table-responsive">
                <Table bordered responsive className="mt-4" style={{ fontSize: '0.8rem' }}>
                  <thead>
                    <tr className="font-small-head">
                      <th className="text-center">Name</th>
                      <th className="text-center">Email</th>
                      <th className="text-center">Date</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userslist?.map((user) => (
                      <tr key={user._id}>
                        <td className="text-center">{user?.fullName}</td>
                        <td className="text-center">{user?.email}</td>
                        <td className="text-center">
                          {dateFormat(user?.updatedAt)}
                        </td>
                        <td className="text-center">
                          <Link to={`/user-rights/${user._id}`}>
                            <Button className="action-button">Rights</Button>
                          </Link>
                          <Button className="action-button-danger mx-2" onClick={() => handleDelete(user._id)}>Delete</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : (
              <div className="text-center mt-4">
                <p>No data found.</p>
              </div>
            )}

            <div className="float-end">
              <Pagination>
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink
                    previous
                    onClick={() => handlePageClick(currentPage - 1)}
                  />
                </PaginationItem>
                {[...Array(totalPages)].map((_, i) => (
                  <PaginationItem key={i} active={i + 1 === currentPage}>
                    <PaginationLink onClick={() => handlePageClick(i + 1)}>
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink
                    next
                    onClick={() => handlePageClick(currentPage + 1)}
                  />
                </PaginationItem>
              </Pagination>
            </div>
          </CardBody>
        </Card>
      </div>
      <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} style={{ top: "33%" }}>
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>Confirm Delete</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this user?
        </ModalBody>
        <ModalFooter>
          <div className={`${Styles.button_color}`}>
            <Button
              type="cancel"
              onClick={() => setModalOpen(!modalOpen)}
            >
              No
            </Button>
          </div>
          <div className={`${Styles.cancel_button} me-4`}>
            <Button
              type="cancel"
              className={`${Styles.cancel_button}`}
              onClick={confirmDelete}
            >
              Yes
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Userlist;
