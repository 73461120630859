import React, { useEffect, useState } from 'react'
import axiosInstance from '../../axiosInterceptor';
import { Button, Card, CardBody, Col, FormGroup, Input, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap';
import { FaSearch } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import pluscircle from '../homework/assets/images/plus circle_.png'
import grp713 from '../homework/assets/images/Group 713.png'
import delete1 from '../homework/assets/images/delete.png'
import Styles from '../homework/assets/Form.module.css'
import { dateFormat } from '../../dateFormat';
import { toast } from 'react-toastify';

const Cms = () => {

    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [cmsdetelis, setCmsDetelis] = useState();
    const [dateFilter, setDateFilter] = useState(new Date().toISOString().split('T')[0]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const navigator = useNavigate();

    const fetchData = async (page = 1) => {
        try {
            setLoading(true);
            const response = await axiosInstance.post('admin/cms/getCMS', {
                search: searchTerm,
                fromDate: dateFilter,
                toDate: dateFilter,
                perPage: 10,
                pageNo: page,
            });
            if (response.data.isSuccess) {
                setCmsDetelis(response.data.data);
                setTotalPages(response.data.totalPages);
                setCurrentPage(page);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchData();
    }, [searchTerm, dateFilter]);

    const handlePageClick = (page) => {
        if (page >= 1 && page <= totalPages) {
            fetchData(page);
        }
    };

    const handleDelete = async (id) => {
        setDeleteItemId(id);
        setModalOpen(true);
    };

    const confirmDelete = async () => {
        try {
            const response = await axiosInstance.delete(`admin/cms/deleteCMS/${deleteItemId}`);
            if (response.status === 200) {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        } finally {
            setDeleteItemId(null);
            setModalOpen(false);
        }
    };

    return (
        <div className='my-2'>
            <div className='row mb-2'>
                <div className='d-flex justify-content-between'>
                    <div>
                        <h4 className="page-title">CMS</h4>
                    </div>
                    <div>
                        <Link to='/add-cms'>
                            <Button className="action-button add-from-new" >
                                <img src={pluscircle} alt="plus" width={20} className='ADD_code' />
                                <span className=""> Add </span>
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='cms-page'>
                <Card>
                    <CardBody>
                        <Row>
                            <Col md="8">
                                <InputGroup className='search_input_icon'>
                                    <Input
                                        type="text"
                                        id="fname"
                                        placeholder="Search.."
                                        name="fname"
                                        className='border_none_icon'
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                fetchData();
                                            }
                                        }}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text wallte">
                                            <FaSearch />
                                        </span>
                                    </div>
                                </InputGroup>
                            </Col>
                            <Col md="4">
                                <Input
                                    type="date"
                                    id="inputDate"
                                    className='form-control date_padding'
                                    value={dateFilter}
                                    onChange={(e) => setDateFilter(e.target.value)}
                                />
                            </Col>
                        </Row>
                        {loading ? (
                            <div className="text-center mt-4">
                                <p>Loading...</p>
                            </div>
                        ) : cmsdetelis?.length > 0 ? (
                            <div className="table-responsive">
                                <Table bordered responsive className="mt-4" style={{ fontSize: '0.8rem' }}>
                                    <thead>
                                        <tr className='font-small-head'>
                                            <th>Date</th>
                                            <th className='text-center'>Title</th>
                                            <th className='text-center'>Url</th>
                                            <th className='text-center'>isActive</th>
                                            {/* <th className='text-center'>Meta Title</th>
                                        <th className='text-center'>Meta Keyword</th>
                                        <th className='text-center'>Meta Description</th> */}
                                            <th className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cmsdetelis?.map((cms) => (
                                            <tr key={cms._id}>
                                                <td className='cms-table-box' style={{ width: "25%" }}>
                                                    {dateFormat(cms?.updatedAt)}
                                                </td>
                                                <td className='text-center cms-table-box' style={{ width: "25%" }}>{cms?.title}</td>
                                                <td className='text-center'>{cms?.slug}</td>
                                                <td className='text-center'>
                                                    <FormGroup switch>
                                                        <Input
                                                            className={`${Styles.form_check_input} float-none`}
                                                            type="switch"
                                                            checked={cms.isActive}
                                                        />
                                                    </FormGroup>
                                                </td>
                                                {/* <td className='text-center cms-table-box' dangerouslySetInnerHTML={{ __html: cms?.description.slice(0, 15) + (cms?.description.length > 15 ? '...' : '') }}></td> */}

                                                {/* <td className='text-center'>{cms?.metaTitle}</td>
                                            <td className='text-center'>{cms?.metaKeyword}</td>
                                            <td className='text-center'>{cms?.metaDescription}</td> */}
                                                <td className='d-flex justify-content-center'>
                                                    <div className={`${Styles.edit_code}`}>
                                                        <Button className={`${Styles.edit_code}`} onClick={() => { navigator('/add-cms', { state: cms }) }}>
                                                            <img
                                                                src={grp713}
                                                                width={19}
                                                                alt='grp'
                                                            />
                                                            Edit
                                                        </Button>
                                                    </div>
                                                    <div className={`${Styles.delete_button_code} me-2 mx-2`}>
                                                        <Button className={`${Styles.delete_button_code}`} onClick={() => handleDelete(cms._id)}>
                                                            <img
                                                                src={delete1}
                                                                className={`${Styles.delete_icon}`}
                                                                alt='delete'
                                                            />
                                                            Delete
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        ) : (
                            <div className="text-center mt-4">
                                <p>No data found.</p>
                            </div>
                        )}

                        <div className="float-end">
                            <Pagination>
                                <PaginationItem disabled={currentPage === 1}>
                                    <PaginationLink previous onClick={() => handlePageClick(currentPage - 1)} />
                                </PaginationItem>
                                {[...Array(totalPages)].map((_, i) => (
                                    <PaginationItem key={i} active={i + 1 === currentPage}>
                                        <PaginationLink onClick={() => handlePageClick(i + 1)}>{i + 1}</PaginationLink>
                                    </PaginationItem>
                                ))}
                                <PaginationItem disabled={currentPage === totalPages}>
                                    <PaginationLink next onClick={() => handlePageClick(currentPage + 1)} />
                                </PaginationItem>
                            </Pagination>
                        </div>
                    </CardBody>
                </Card>
            </div>
            <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} style={{ top: "33%" }}>
                <ModalHeader toggle={() => setModalOpen(!modalOpen)}>Confirm Delete</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this cms?
                </ModalBody>
                <ModalFooter>
                    <div className={`${Styles.button_color}`}>
                        <Button
                            type="cancel"
                            onClick={() => setModalOpen(!modalOpen)}
                        >
                            No
                        </Button>
                    </div>
                    <div className={`${Styles.cancel_button} me-4`}>
                        <Button
                            type="cancel"
                            className={`${Styles.cancel_button}`}
                            onClick={confirmDelete}
                        >
                            Yes
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default Cms