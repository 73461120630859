import React, { useEffect, useState } from "react";
import {
    Col,
    Row,
    Card,
    CardBody,
    Input,
    InputGroup,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    Button,
} from "reactstrap";
import { FaSearch } from "react-icons/fa";
import axiosInstance from "../../axiosInterceptor";
import { toast } from "react-toastify";
import { dateFormat } from "../../dateFormat";
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';

const Assignmentwallte = () => {

    const navigator = useNavigate();

    const [apiData, setApiData] = useState({});
    const [loading, setLoading] = useState(true);
    // const [dateFilter, setDateFilter] = useState(new Date().toISOString().split('T')[0]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [filterOption, setFilterOption] = useState("All");
    const pageSize = 5; // Number of items per page

    useEffect(() => {
        const fetchCategory = async (page = 1) => {
            try {

                const response = await axiosInstance.post(
                    `admin/wallet/getCategoryWisePayment`,
                    {
                        paymentSection: "AssignmentHelp",
                        // fromDate: dateFilter,
                        // toDate: dateFilter,
                        paymentStatus: filterOption,
                        fromDate: fromDate,
                        toDate: toDate,
                        perPage: 10,
                        pageNo: page,
                    }
                );

                setApiData(response.data);
                setLoading(false);
            } catch (error) {
                toast.error('Something went wrong. Please try again!!');
                setLoading(false);
            }
        };

        fetchCategory();
    }, [fromDate, toDate, filterOption]);


    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    const handleFilterChange = (event) => {
        setFilterOption(event.target.value);
    };

    const filteredData = apiData.transactionHistory?.filter((transaction) =>
        transaction.displayId.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastTransaction = (currentPage + 1) * pageSize;
    const indexOfFirstTransaction = indexOfLastTransaction - pageSize;
    const paginatedData = filteredData?.slice(indexOfFirstTransaction, indexOfLastTransaction);


    return (
        <div className="wallte my-2">
            <h4 className="page-title">Assignment Wallet </h4>
            <Row>
                <Col xs="12" className="grid-margin stretch-card">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md="4">
                                    <DateRangePicker className="w-100"
                                        // value={[new Date(fromDate), new Date(toDate)]}
                                        onChange={(value) => {
                                            if (value && value[0]) {
                                                setFromDate(value[0].toISOString().split('T')[0]);
                                                setToDate(value[1] ? value[1].toISOString().split('T')[0] : '');
                                            }
                                        }}
                                        onClean={() => {
                                            setFromDate(null);
                                            setToDate(null);
                                        }}
                                    />
                                </Col>
                                <Col md="4">
                                    <InputGroup className='search_input_icon'>
                                        <Input
                                            type="text"
                                            id="fname"
                                            placeholder="Order ID"
                                            className='border_none_icon'
                                            name="fname"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text wallte" >
                                                <FaSearch />
                                            </span>
                                        </div>
                                    </InputGroup>
                                </Col>
                                {/* <Col md="4">
                                    <Input
                                        type="date"
                                        className="form-control date_padding"
                                        id="inputFromDate"
                                        value={dateFilter}
                                        onChange={(e) => setDateFilter(e.target.value)}
                                    />
                                </Col> */}
                                <Col md="4">
                                    <Input
                                        id="exampleSelect"
                                        name="select"
                                        type="select"
                                        value={filterOption}
                                        onChange={handleFilterChange}
                                    >
                                        <option value="All">All Payment Status</option>
                                        <option value="Paid">Paid</option>
                                        <option value="Unpaid">Unpaid</option>
                                        <option value="Refund">Refund</option>
                                    </Input>
                                </Col>
                            </Row>
                            {loading ? (
                                <p>Loading...</p>
                            ) : (
                                <>
                                    {/* <div className="mt-4">
                                        <Table bordered className="mt-4">
                                            <thead>
                                                <tr>
                                                    <th className="total-amount">
                                                        Total Amount : ${apiData.totalAmount?.toFixed(2)}
                                                    </th>
                                                    <th className="total-amount"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row" className="table-data">
                                                        Student Amount (Received)
                                                    </th>
                                                    <td className="text-center font-weight-bold">
                                                        ${apiData.studentAmount?.toFixed(2)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="table-data">
                                                        Tutor Amount (Sent)
                                                    </th>
                                                    <td className="text-center font-weight-bold">
                                                        ${apiData.tutorAmount?.toFixed(2)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div> */}
                                    <div className="mt-4">
                                        {/* <h4 className="page-title">Transaction History</h4> */}
                                        <Row>
                                            <Col md="4">
                                                <h5 className="page-title">Transaction History</h5>
                                            </Col>
                                            <Col md="8">
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <h6>Total Amount : <span className='studentamount'>${apiData.totalAmount?.toFixed(2)}</span></h6>
                                                    <h6>Student : <span className='studentamount'>${apiData.studentAmount?.toFixed(2)}</span></h6>
                                                    <h6>Refund : <span className='tutoramount'>{apiData.refundAmount && apiData.refundAmount !== 0 ? `$${apiData.refundAmount?.toFixed(2)}` : '$0.00'}</span></h6>
                                                    {/* <h6>Tutor : <span className='tutoramount'>{apiData.tutorAmount?.toFixed(2)}</span></h6> */}
                                                </div>
                                            </Col>
                                        </Row>
                                        {loading ? (
                                            <div className="text-center mt-4">
                                                <p>Loading...</p>
                                            </div>
                                        ) : (
                                            <>
                                                <div className="table-responsive">
                                                    <Table bordered className="mt-4" responsive style={{ fontSize: '0.8rem' }}>
                                                        <thead>
                                                            <tr>
                                                                <th>Date & Time</th>
                                                                <th className="text-center">Order ID</th>
                                                                <th className="text-center">Type</th>
                                                                <th className='text-center'>Earning</th>
                                                                <th className='text-center'>Refund</th>
                                                                <th className="text-center">Tutor</th>
                                                                <th className="text-center">Status</th>
                                                                <th className="text-center">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {paginatedData?.length > 0 ? (
                                                                paginatedData.map((transaction, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            {dateFormat(transaction.updatedAt)}
                                                                            <br />
                                                                            {transaction?.studentId?.email}
                                                                        </td>
                                                                        <td className="text-center">{transaction.displayId}</td>
                                                                        <td className="text-center">{transaction.subCategoryId.name}</td>
                                                                        <td className="studentamount text-center">${transaction.studentAmount?.toFixed(2)}</td>
                                                                        <td className='tutoramount text-center'>
                                                                            {transaction.refundAmount && transaction.refundAmount !== 0 ? `$${transaction.refundAmount?.toFixed(2)}` : '$0.00'}
                                                                        </td>
                                                                        <td className="tutoramount text-center">{transaction.tutorAmount?.toFixed(2)}</td>
                                                                        <td className="text-center">{transaction.studentPaymentStatus}</td>
                                                                        <td className="text-center">
                                                                            <Button className="action-button" onClick={() => { navigator('/assignment-form', { state: transaction }) }}>View</Button>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="7" className="text-center">
                                                                        No data found.
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </>
                                        )}
                                        <div className="float-end">
                                            <Pagination>
                                                <PaginationItem disabled={currentPage === 0}>
                                                    <PaginationLink previous onClick={() => handlePageClick(currentPage - 1)} />
                                                </PaginationItem>
                                                {[...Array(Math.ceil(filteredData?.length / pageSize))].map((_, i) => (
                                                    <PaginationItem key={i} active={i === currentPage}>
                                                        <PaginationLink onClick={() => handlePageClick(i)}>{i + 1}</PaginationLink>
                                                    </PaginationItem>
                                                ))}
                                                <PaginationItem
                                                    disabled={currentPage === Math.ceil(filteredData.length / pageSize) - 1}
                                                >
                                                    <PaginationLink next onClick={() => handlePageClick(currentPage + 1)} />
                                                </PaginationItem>
                                            </Pagination>
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <div className="mt-4">
                                        {/* <h4 className="page-title">Filter</h4> */}
                                        <div className="table-responsive">
                                            <Table bordered className="mt-4" responsive style={{ fontSize: '0.8rem' }}>
                                                <thead>
                                                    <tr>
                                                        <th className="assignment-title">Assignment Type</th>
                                                        <th className="assignment-title-student text-center">
                                                            Student
                                                        </th>
                                                        <th className="assignment-title-tutor text-center">
                                                            Tutor
                                                        </th>
                                                        <th className="assignment-title-tutor text-center">
                                                            Refund
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {apiData.sectionPayments &&
                                                        Array.isArray(apiData.sectionPayments) &&
                                                        apiData.sectionPayments.length > 0 ? (
                                                        apiData.sectionPayments.map((category, categoryIndex) =>
                                                            category.subCategories.map(
                                                                (subcategory, subcategoryIndex) => (
                                                                    <tr key={`${categoryIndex}-${subcategoryIndex}`}>
                                                                        <th scope="row" className="table-data">
                                                                            {subcategory.subCategoryName}
                                                                        </th>
                                                                        <td className="text-center studentamount">
                                                                            ${subcategory.studentAmount?.toFixed(2)}
                                                                        </td>
                                                                        <td className="text-center tutoramount">
                                                                            {subcategory.tutorAmount?.toFixed(2)}
                                                                        </td>
                                                                        <td className="text-center tutoramount">
                                                                            ${subcategory.refundAmount?.toFixed(2)}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )
                                                        )
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="3" className="text-center">
                                                                No data found.
                                                            </td>
                                                        </tr>
                                                    )}
                                                    <tr className="font-weight-bold">
                                                        <th scope="row" className="assignment-title">
                                                            Total
                                                        </th>
                                                        <td className="assignment-title-student text-center studentamount">
                                                            ${apiData.studentAmount?.toFixed(2)}
                                                        </td>
                                                        <td className="assignment-title-tutor text-center tutoramount">
                                                            {apiData.tutorAmount?.toFixed(2)}
                                                        </td>
                                                        <td className="assignment-title-tutor text-center tutoramount">
                                                            ${apiData.refundAmount?.toFixed(2)}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>

                                </>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Assignmentwallte