import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import {
    Button,
    Card,
    CardBody,
    Col,
    Input,
    InputGroup,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
} from "reactstrap";
import axiosInstance from "../../axiosInterceptor";
import { dateFormat } from "../../dateFormat";
import { Link } from "react-router-dom";
import { DateRangePicker } from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';

const Studentlist = () => {
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [studentslist, setStudentslist] = useState();
    //   const [dateFilter, setDateFilter] = useState(new Date().toISOString().split("T")[0]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const fetchData = async (page = 1) => {
        try {
            setLoading(true);
            const response = await axiosInstance.post("admin/student/getStudents", {
                search: searchTerm,
                // fromDate: dateFilter,
                // toDate: dateFilter,
                fromDate: fromDate,
                toDate: toDate,
                perPage: 10,
                pageNo: page,
                email: searchTerm,
            });
            if (response.data.isSuccess) {
                const filteredData = response.data.data.filter((student) =>
                    student.email.includes(searchTerm)
                );
                setStudentslist(filteredData);
                setTotalPages(response.data.totalPages);
                setCurrentPage(page);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [searchTerm, fromDate, toDate]);

    const handlePageClick = (page) => {
        if (page >= 1 && page <= totalPages) {
            fetchData(page);
        }
    };

    return (
        <div className="my-2">
            <h4 className="page-title">Student List</h4>
            <div className="student-list">
                <Card>
                    <CardBody>
                        <Row>
                            <Col md="6">
                                <DateRangePicker className="w-100"
                                    // value={[new Date(fromDate), new Date(toDate)]}
                                    onChange={(value) => {
                                        if (value && value[0]) {
                                            setFromDate(value[0].toISOString().split('T')[0]);
                                            setToDate(value[1] ? value[1].toISOString().split('T')[0] : '');
                                        }
                                    }}
                                    onClean={() => {
                                        setFromDate(null);
                                        setToDate(null);
                                    }}
                                />
                            </Col>
                            <Col md="6">
                                <InputGroup className="search_input_icon">
                                    <Input
                                        type="text"
                                        id="fname"
                                        placeholder="Email Id"
                                        name="fname"
                                        className="border_none_icon"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                fetchData();
                                            }
                                        }}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text wallte">
                                            <FaSearch />
                                        </span>
                                    </div>
                                </InputGroup>
                            </Col>
                            {/* <Col md="4">
                                <Input
                                    type="date"
                                    id="inputDate"
                                    className="form-control date_padding"
                                    value={dateFilter}
                                    onChange={(e) => setDateFilter(e.target.value)}
                                />
                            </Col> */}

                        </Row>
                        {loading ? (
                            <div className="text-center mt-4">
                                <p>Loading...</p>
                            </div>
                        ) : studentslist?.length > 0 ? (
                            <div className="table-responsive">
                                <Table bordered className="mt-4" responsive style={{ fontSize: '0.8rem' }}>
                                    <thead>
                                        <tr className="font-small-head">
                                            <th>Date</th>
                                            <th className="text-center">Country</th>
                                            {/* <th className="text-center">Register Type</th> */}
                                            <th className="text-center">Homework help</th>
                                            <th className="text-center">Assignmet help</th>
                                            <th className="text-center">Live session help</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {studentslist?.map((student) => (
                                            <tr key={student._id}>
                                                <td>
                                                    {dateFormat(student?.updatedAt)}
                                                    <br />
                                                    {student?.email}
                                                </td>
                                                <td className="text-center">
                                                    {student?.countryId?.countryName}
                                                </td>
                                                {/* <td className="text-center">{student?.registerType}</td> */}
                                                <td>
                                                    <div className="record-details">
                                                        <span className="record-label">Total Question:</span>
                                                        <span className="record-value">
                                                            {student?.homeworkHelpTotalRecords}
                                                        </span>
                                                    </div>
                                                    <div className="record-details">
                                                        <span className="record-label">Student Amount:</span>
                                                        <span className="record-value">
                                                            {student?.homeworkHelpStudentAmount?.toFixed(2)}
                                                        </span>
                                                    </div>
                                                    <div className="record-details">
                                                        <span className="record-label">Tutor Amount:</span>
                                                        <span className="record-value">
                                                            {student?.homeworkHelpTutorAmount?.toFixed(2)}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="record-details">
                                                        <span className="record-label">
                                                            Total Assignment:
                                                        </span>
                                                        <span className="record-value">
                                                            {student?.assignmentHelpTotalRecords}
                                                        </span>
                                                    </div>
                                                    <div className="record-details">
                                                        <span className="record-label">Student Amount:</span>
                                                        <span className="record-value">
                                                            {student?.assignmentHelpStudentAmount?.toFixed(2)}
                                                        </span>
                                                    </div>
                                                    <div className="record-details">
                                                        <span className="record-label">Tutor Amount:</span>
                                                        <span className="record-value">
                                                            {student?.assignmentHelpTutorAmount?.toFixed(2)}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="record-details">
                                                        <span className="record-label">
                                                            Total Live session:
                                                        </span>
                                                        <span className="record-value">
                                                            {student?.liveSessionHelpTotalRecords}
                                                        </span>
                                                    </div>
                                                    <div className="record-details">
                                                        <span className="record-label">Student Amount:</span>
                                                        <span className="record-value">
                                                            {student?.liveSessionHelpStudentAmount?.toFixed(2)}
                                                        </span>
                                                    </div>
                                                    <div className="record-details">
                                                        <span className="record-label">Tutor Amount:</span>
                                                        <span className="record-value">
                                                            {student?.liveSessionHelpTutorAmount?.toFixed(2)}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <Link to={`/student-details/${student._id}`}>
                                                        <Button className="action-button">View</Button>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        ) : (
                            <div className="text-center mt-4">
                                <p>No data found.</p>
                            </div>
                        )}

                        <div className="float-end">
                            <Pagination>
                                <PaginationItem disabled={currentPage === 1}>
                                    <PaginationLink
                                        previous
                                        onClick={() => handlePageClick(currentPage - 1)}
                                    />
                                </PaginationItem>
                                {[...Array(totalPages)].map((_, i) => (
                                    <PaginationItem key={i} active={i + 1 === currentPage}>
                                        <PaginationLink onClick={() => handlePageClick(i + 1)}>
                                            {i + 1}
                                        </PaginationLink>
                                    </PaginationItem>
                                ))}
                                <PaginationItem disabled={currentPage === totalPages}>
                                    <PaginationLink
                                        next
                                        onClick={() => handlePageClick(currentPage + 1)}
                                    />
                                </PaginationItem>
                            </Pagination>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
};

export default Studentlist;
