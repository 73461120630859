import React, { useState } from "react";
import Styles from "./assets/Tutor.module.css";
import grp713 from "./assets/images/Group 713.png";
import vec132 from "./assets/images/Vector 132.png";
import icon from "./assets/images/plus circle_.png";
import { Card, CardBody, Input, Table } from "reactstrap";
import CommonEditor from "../masters/CommonEditor";

export default function Tutor_exam() {
  const [value, setValue] = useState("");
  return (
    <>
      {/* <!---------Body Content ----------> */}
      <div className={`${Styles.second_container}`}>
        <div className="d-flex justify-content-between">
          <div className="">
            <h4>Exam Configuration</h4>
          </div>
          <div>
            <button className={` ${Styles.Back_button}`}>Back</button>
          </div>
        </div>
        <Card className="mt-4">
          <CardBody>
            <div className="bg-white exam_config_content mt-3">
              <div>
                <form className="ps-5 pe-5 pt-3">
                  <div className="form-group row mb-4 mt-4">
                    <label
                      for="inputPassword"
                      className="col-sm-2 col-form-label"
                    >
                      Questions<b className="ms-2">:</b>
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword"
                        placeholder="5"
                      />
                    </div>
                    <div className={`${Styles.button_edit} pe-5 col-sm-3`}>
                      <button className={`${Styles.button_edit}`}>
                        Edit{" "}
                        <img
                          src={grp713}
                          alt="error"
                          className="ps-2"
                          srcSet=""
                        />
                      </button>
                    </div>
                  </div>
                  <div className="form-group row mb-4 mt-5 pt-3">
                    <label
                      for="inputPassword"
                      className="col-sm-2 col-form-label"
                    >
                      Pass<b className="ms-2">:</b>
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword"
                        placeholder="3"
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-4">
                    <label
                      for="inputPassword"
                      className="col-sm-2 col-form-label"
                    >
                      <b className="ms-2"></b>{" "}
                    </label>
                    <div className="col-sm-10 d-flex">
                      <div className={` ${Styles.cancel_button} me-4`}>
                        <button className={`${Styles.cancel_button}`}>
                          Cancel
                        </button>
                      </div>
                      <div className={`${Styles.submit_button}`}>
                        <button>Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </CardBody>
        </Card>
        <div className="mt-4">
          <h4>Questions</h4>
        </div>
        <Card>
          <CardBody>
            <div className="container bg-white assignment-content">
              <div className="col-sm-12 mt-4 pe-5 mb-4 d-flex justify-content-end">
                <button className={`${Styles.add_button} me-3`}>
                  <img
                    src={icon}
                    alt="error"
                    className={`${Styles.add_subject}  ps-2`}
                    srcSet=""
                    width={32}
                  />
                  Add
                </button>
                <div className="">
                  <button className={`${Styles.delete_button}`}>Delete</button>
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-between mt-5">
                <div className="ps-5 pt-2">
                  <span className={`${Styles.student_corner_text}`}>
                    Question
                  </span>
                </div>
                <div className={`${Styles.button_edit} pe-5`}>
                  <button className={`${Styles.button_edit}`}>
                    Edit{" "}
                    <img src={grp713} alt="error" className="ps-2" srcSet="" />
                  </button>
                  <span className="drop-down-arrow">
                    {" "}
                    <img src={vec132} alt="error" className="ps-2" srcSet="" />
                  </span>
                </div>
              </div>
              <form className="ps-5 pe-5 pt-3">
                <div className="form-group row mb-5">
                  <div className="col-sm-12">
                    <CommonEditor
                      value={value}
                      placeholder="Enter content ..."
                      onChange={setValue}
                      className={`${Styles.h_200}`}
                    />
                  </div>
                </div>
                <div className="form-group row mb-4 mt-5 pt-3">
                  <label
                    for="inputPassword"
                    className="col-sm-2 col-form-label"
                  >
                    Subject<b className="ms-2">:</b>{" "}
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      id="inputPassword"
                      placeholder="Economics"
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <label
                    for="inputPassword"
                    className="col-sm-2 col-form-label"
                  >
                    Type<b className="ms-2">:</b>{" "}
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      id="inputPassword"
                      placeholder="MCQ"
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <label
                    for="inputPassword"
                    className="col-sm-2 col-form-label"
                  >
                    <b className="ms-2"></b>{" "}
                  </label>
                  <div className="col-sm-10 d-flex">
                    <div className={` ${Styles.cancel_button} me-4`}>
                      <button className={` ${Styles.cancel_button}`}>
                        Cancel
                      </button>
                    </div>
                    <div className={`${Styles.submit_button}`}>
                      <button>Submit</button>
                    </div>
                  </div>
                </div>
              </form>
              <hr />
              <div className="d-flex justify-content-between mt-5">
                <div className="ps-5 pt-2">
                  <span className={`${Styles.student_corner_text}`}>
                    Answer
                  </span>
                </div>
                <div className={`${Styles.button_edit} pe-5`}>
                  <button className={`${Styles.button_edit}`}>
                    Edit{" "}
                    <img src={grp713} alt="error" className="ps-2" srcSet="" />
                  </button>
                  <span className="drop-down-arrow">
                    {" "}
                    <img src={vec132} alt="error" className="ps-2" srcSet="" />
                  </span>
                </div>
              </div>
              <form className="mt-3 ms-5">
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label className="form-check-label" for="flexRadioDefault1">
                    A
                  </label>
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                  />
                  <label className="form-check-label" for="flexRadioDefault2">
                    B
                  </label>
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label className="form-check-label" for="flexRadioDefault1">
                    C
                  </label>
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    checked
                  />
                  <label className="form-check-label" for="flexRadioDefault2">
                    D
                  </label>
                </div>
                <div className="form-group row mb-4">
                  <label
                    for="inputPassword"
                    className="col-sm-2 col-form-label"
                  >
                    <b className="ms-2"></b>{" "}
                  </label>
                  <div className="col-sm-10 d-flex">
                    <div className={`${Styles.cancel_button} me-4`}>
                      <button className={`${Styles.cancel_button}`}>
                        Cancel
                      </button>
                    </div>
                    <div className={`${Styles.submit_button}`}>
                      <button>Submit</button>
                    </div>
                  </div>
                </div>
              </form>
              <hr />
              <div className="d-flex justify-content-between mt-5">
                <div className="ps-5 pt-2 mb-5">
                  <span className={`${Styles.student_corner_text}`}>
                    Questions
                  </span>
                </div>
                <div className="col-sm-4">
                  <Input id="exampleSelect" name="select" type="select">
                    <option value="1">Select Subject</option>
                    <option value="2">Maths </option>
                    <option value="3">Economics</option>
                  </Input>
                </div>
              </div>
              <div className="ms-5 me-3">
                <Table bordered className="justify-content-center">
                  <thead>
                    <tr>
                      <th>Question</th>
                      <th className="text-center">Subject</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>What's The Difference Homologous And Structures?</td>
                      <td>
                        <button className={`${Styles.subject}`}>Biology</button>
                      </td>
                      <td>
                        <button className={`${Styles.edit}`}>Edit</button>
                        <button className={`${Styles.delete}`}>Delete</button>
                      </td>
                    </tr>
                    <tr>
                      <td>What's The Difference Homologous And Structures?</td>
                      <td>
                        <button className={`${Styles.subject}`}>Biology</button>
                      </td>
                      <td>
                        <button className={`${Styles.edit}`}>Edit</button>
                        <button className={`${Styles.delete}`}>Delete</button>
                      </td>
                    </tr>
                    <tr>
                      <td>What's The Difference Homologous And Structures?</td>
                      <td>
                        <button className={`${Styles.subject}`}>Biology</button>
                      </td>
                      <td>
                        <button className={`${Styles.edit}`}>Edit</button>
                        <button className={`${Styles.delete}`}>Delete</button>
                      </td>
                    </tr>
                    <tr>
                      <td>What's The Difference Homologous And Structures?</td>
                      <td>
                        <button className={`${Styles.subject}`}>Biology</button>
                      </td>
                      <td>
                        <button className={`${Styles.edit}`}>Edit</button>
                        <button className={`${Styles.delete}`}>Delete</button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
}
