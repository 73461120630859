import React, { useState } from "react";
import {
  Col,
  Row,
  Input,
  InputGroup,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
} from "reactstrap";
import { FaSearch } from "react-icons/fa";
import whatappicon from './assets/images/WhatsApp-Logo-Icon.png'
import page from "./assets/images/Page-1.png";
import Styles from "./assets/Tutor.module.css";

const Tutor_payment = () => {

  const [showBankDetails, setShowBankDetails] = useState(false);

  const toggleBankDetails = () => {
    setShowBankDetails(!showBankDetails);
  };


  return (
    <div className="wallte mt-4">
      <div className='row'>
        <div className='col-sm-10'>
          <h4 className="page-title">Tutors Payment </h4>
        </div>
        <div className='col-sm-2'>
          <h6 className={`${Styles.transaction_box}`}>Transaction History</h6>
        </div>
      </div>
      <Row>
        <Col xs="12" className="grid-margin stretch-card">
          <div className="mt-4">
            <Table bordered className="mt-4">
              <thead>
                <tr>
                  <th className="total-amount">
                    Total Amount : 4654465
                  </th>
                  <th className="total-amount"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row" className="table-data">
                    Student Amount (Received)
                  </th>
                  <td className="text-center font-weight-bold">
                    $6546
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="table-data">
                    Tutor Amount (Sent)
                  </th>
                  <td className="text-center font-weight-bold">
                    $554
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="mt-4">
            <h4 className="page-title mb-4">Tutors Name</h4>
            <Row>
              <Col md="8">
                <InputGroup>
                  <Input
                    type="text"
                    id="fname"
                    placeholder="Order ID"
                    name="fname"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text wallte">
                      <FaSearch />
                    </span>
                  </div>
                </InputGroup>
              </Col>
              <Col md="4">
                <Input
                  type="date"
                  className="form-control"
                  id="inputFromDate"
                />
              </Col>
            </Row>
            <div className="mt-4">
              <Table bordered className="table mt-3">
                <thead>
                  <tr>
                    <td>Name</td>
                    <td>Whatsapp No</td>
                    <td>Payable Amount</td>
                    <td>Actions</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Viviek Jain</td>
                    <td>
                      <img src={whatappicon} alt="" srcset="" /> +91 9374744365
                    </td>
                    <td>Rs 300</td>
                    <td>
                      <Button className="action-button-paid">Paid</Button>
                    </td>
                  </tr>
                  <tr >
                    <td className={`${Styles.bank_details_name}`} onClick={toggleBankDetails}>Pratik Chawde</td>
                    <td>
                      <img src={whatappicon} alt="" />
                      +91 9374744365
                    </td>
                    <td>Rs 300</td>
                    <td>
                      <Button className="action-button">
                        Pay Now
                      </Button>
                    </td>
                  </tr>
                  {showBankDetails && (
                    <tr className={`${Styles.bank_details}`}>
                      <td className="d-flex justify-content-between" colSpan="4">
                        <div>
                          <span>Bank Name:</span> SBI India <img className="mx-2" src={page} alt="copy" /> <br />
                          <span>Ac No:</span> 456456456456 <img className="mx-2" src={page} alt="copy" />
                        </div>
                        <hr style={{ transform: 'rotate(90deg)', width: '8%' }} />
                        <div>
                          <span>#SC Code:</span> 565465465456 <img className="mx-2" src={page} alt="copy" /> <br />
                          <span>UPI id:</span> 45655455655j <img className="mx-2" src={page} alt="copy" />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="float-end">
                <Pagination>
                  <PaginationItem>
                    <PaginationLink
                      first
                      href="#"
                    />
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      href="#"
                      previous
                    />
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">
                      1
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">
                      2
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">
                      3
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">
                      4
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">
                      5
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      href="#"
                      next
                    />
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      href="#"
                      last
                    />
                  </PaginationItem>
                </Pagination>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Tutor_payment