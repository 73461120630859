import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from '../assets/logo/logo.png';
import "./Login.scss"
import { Button, Form, FormGroup, Label, Input, Container, Row, Col, FormFeedback } from 'reactstrap';
import axios from "axios";
import { toast } from "react-toastify";

const Forgotpassword = () => {
    
    const navigator = useNavigate();
    const [email, setemail] = useState('');
    const [otp, setOtp] = useState('');
    const [data, setData] = useState([]);
    const [errors, setErrors] = useState('')
    const [submitAttempted, setSubmitAttempted] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitAttempted(true)
        if (!email) {
            return
        }
        try {
            let headersList = {
                "Content-Type": "application/json"
            }

            let bodyContent = JSON.stringify({
                email
            });

            let reqOptions = {
                url: `admin/forgotpassword`,
                method: "POST",
                headers: headersList,
                data: bodyContent,
            }

            let response = await axios.request(reqOptions);
            setData(response.data)
            if (response.status === 200) {
                toast.success(response.data.message)
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    }

    const otpVerify = async (e) => {
        e.preventDefault();
        setFormSubmitted(true)
        if (!email || !otp) {
            return;
        }
        try {
            let bodyContent = {
                otpCode: parseInt(otp),
                email: email
            };
            let reqOptions = {
                url: `admin/verifyOtp`,
                method: "POST",
                data: bodyContent,
            }
            let response = await axios.request(reqOptions);
            if (response.status === 200) {
                toast.success(response.data.message)
                navigator("/reset-password", {state:email})
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    }

    return (
        <div className="login-register-bg">
            <Container>
                <Row className="d-lg-flex align-items-center justify-content-center d-block h-100">
                    <Col lg="7" className="mx-auto">
                        <div className="logo text-center p-4">
                            <Link to="#">
                                <img src={Logo} alt="DoubtQ Logo" />
                            </Link>
                        </div>
                        <div className="rbt-contact-form contact-form-style-1 rbt-shadow-box mb--50 mt-2">
                            <Row className="align-items-center">
                                <Col lg="12" className="pl--30 pl_sm--0">
                                    <div>
                                        <h4 className="title text-center mb--10"><b>Forgot Password</b></h4>
                                        {data.message === 'Otp is sent in your email.' ? "" : <Form className="rbt-profile-row rbt-default-form row row--15"
                                            onSubmit={handleSubmit}>
                                            <Col lg="12" md="12" xs="12">
                                                <FormGroup>
                                                    <Label>Email</Label>
                                                    <Input invalid={!email && submitAttempted} type="email" placeholder="Enter your email" value={email} name="email" onChange={(e) => setemail(e.target.value)} />
                                                    <FormFeedback>Email is required</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12" md="12" xs="12" className="text-end">
                                                <div className="text-center">
                                                    <Button type="submit" className="px-4 login-button">Send</Button>
                                                </div>
                                            </Col>                                           
                                            <Col lg="12" md="12" xs="12" className="text-center mt-3">
                                                Don’t have an account?
                                                <Link to="/" className="color-primary mx-2">
                                                    Sign in
                                                </Link>
                                            </Col>
                                        </Form>}
                                    </div>

                                    {data.message === 'Otp is sent in your email.' ?
                                     <div>
                                        <Form className="rbt-profile-row rbt-default-form row row--15"
                                            onSubmit={otpVerify}>
                                            <Col lg="12" md="12" xs="12">
                                                <FormGroup>
                                                    <Label>Email</Label>
                                                    <Input invalid={!email && formSubmitted} type="email" placeholder="Enter your email" value={email} name="email" onChange={(e) => setemail(e.target.value)} readOnly/>
                                                    {errors && <p className="text-danger">{errors}</p>}
                                                    <FormFeedback invalid>
                                                        Email is required
                                                    </FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12" md="12" xs="12">
                                                <FormGroup>
                                                    <Label>OTP</Label>
                                                    <Input invalid={!otp && formSubmitted} type="text" placeholder="Enter your otp" value={otp} name="otp" onChange={(e) => setOtp(e.target.value)} />
                                                    <FormFeedback invalid>
                                                        OTP is required
                                                    </FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12" md="12" xs="12" className="text-end">
                                                <div className="text-center">
                                                    <Button type="submit" className="px-4 login-button" > Reset Password</Button>
                                                </div>
                                            </Col>
                                        </Form>
                                    </div> : ""}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Forgotpassword;
