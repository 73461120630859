import React, { useState } from 'react'
import { Button, Card, CardBody, Col, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap'
import Styles from '../pages/homework/assets/Form.module.css'
import axiosInstance from '../axiosInterceptor'
import { toast } from 'react-toastify'
import { Link, useLocation, useNavigate } from 'react-router-dom'



const Promocode = () => {
    const location = useLocation();
    const editData = location.state;
    const [promoCode, setPromocode] = useState(editData?.promoCode || '')
    const [promoCodeValue, setPromoCodeValue] = useState(editData?.promoCodeValue || '')
    const [promoCodeValueType, setpromoCodeValueType] = useState(editData?.promoCodeValueType || '')
    const [formSubmitted, setFormSubmitted] = useState(false)
    const navigator = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormSubmitted(true)
        if (!promoCode || !promoCodeValue || !promoCodeValueType) {
            return;
        }
        try {
            const response = await axiosInstance.post(`admin/promoCode/createCode`, {
                promoCode: promoCode,
                promoCodeValue: parseInt(promoCodeValue),
                promoCodeValueType: promoCodeValueType,
            });
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/promo-code");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.put(`admin/promoCode/updateCode/${editData?._id}`, {
                promoCode: promoCode,
                promoCodeValue: parseInt(promoCodeValue),
                promoCodeValueType: promoCodeValueType,
            });
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/promo-code");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    return (
        <div>
            <div className="mt-4 mb-4">
                <div className='row'>
                    <div className='col-sm-6'>
                        <h4 className="page-title">Add Promocode</h4>
                    </div>
                </div>
                <Card className='mt-4'>
                    <CardBody>
                        <Row>
                            <Col md="8">
                                <form className="ps-5 pe-5 pt-3" onSubmit={editData?._id ? handleUpdate : handleSubmit}>
                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-5 col-form-label">
                                            Promo Code<b className="ms-2">:</b>
                                        </Label>
                                        <div className="col-sm-7">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="inputPassword"
                                                placeholder="Enter your promo code "
                                                invalid={!editData && !promoCode && formSubmitted}
                                                defaultValue={promoCode}
                                                onChange={(e) => setPromocode(e.target.value)}
                                            />
                                            <FormFeedback>Promo code is required</FormFeedback>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-5 col-form-label">
                                            Promo Code Value<b className="ms-2">:</b>
                                        </Label>
                                        <div className="col-sm-7">
                                            <Input
                                                type="number"
                                                className="form-control"
                                                id="inputPassword"
                                                placeholder="Enter your promo code value"
                                                invalid={!editData && !promoCodeValue && formSubmitted}
                                                defaultValue={promoCodeValue}
                                                onChange={(e) => setPromoCodeValue(e.target.value)}
                                            />
                                            <FormFeedback>Promo code value is required</FormFeedback>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-5 col-form-label">
                                            Promo Code Type <b className="ms-2">:</b>
                                        </Label>
                                        <div className="col-sm-7">
                                            <FormGroup>
                                                <Input
                                                    id="exampleSelect"
                                                    name="select"
                                                    type="select"
                                                    invalid={!editData && !promoCodeValueType && formSubmitted}
                                                    defaultValue={promoCodeValueType}
                                                    onChange={(e) => setpromoCodeValueType(e.target.value)}
                                                >
                                                    <option value="">--- Select Type ---</option>
                                                    <option value="Percentage">Percentage</option>
                                                </Input>
                                            </FormGroup>
                                            <FormFeedback>Promo code value type is required</FormFeedback>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            <b className="ms-2"></b>
                                        </Label>
                                        <div className="col-sm-7 d-flex">
                                            <div className={`${Styles.submit_button}`}>
                                                <Button type='submit'>{editData?._id ? "Update" : "Save"}</Button>
                                            </div>
                                            <div className={`${Styles.cancel_button} me-4 mx-2`}>
                                                <Link to='/promo-code'>  <Button className={`${Styles.cancel_button}`}>Cancel</Button></Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default Promocode