import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Pagination, PaginationItem, PaginationLink, Table } from 'reactstrap';
import Styles from '../homework/assets/Form.module.css'
// import { FaSearch, } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import pluscircle from '../homework/assets/images/plus circle_.png'
import grp713 from '../homework/assets/images/Group 713.png'
import delete1 from '../homework/assets/images/delete.png'
import axiosInstance from '../../axiosInterceptor';

const AssignmentPrice = () => {
    const [loading, setLoading] = useState(false);
    const [assignmentData, setAssignmentData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [subCategoryId, setSubCategoryId] = useState('')
    const [subCategoryData, setSubCategoryData] = useState([]);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    // const [categoryData, setCategoryData] = useState([]);
    const navigator = useNavigate();
    const perPage = 10;

    const fetchData = async (page = 1) => {
        try {
            setLoading(true);
            const response = await axiosInstance.post(`admin/assignmentPrice/getAllAssignmentPrice`,
                {
                    perPage: perPage,
                    pageNo: page,
                    subCategoryId: subCategoryId
                }
            );
            const { data, totalPages } = response.data;
            setAssignmentData(data);
            setTotalPages(totalPages);
            setCurrentPage(page);
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [subCategoryId]);

    const handlePageClick = (page) => {
        if (page >= 1 && page <= totalPages) {
            fetchData(page);
        }
    };

    const handleDelete = async (id) => {
        setDeleteItemId(id);
        setModalOpen(true);
    };

    const confirmDelete = async () => {
        try {
            const response = await axiosInstance.delete(`admin/assignmentPrice/deleteAssignmentPrice/${deleteItemId}`);
            if (response.status === 200) {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        } finally {
            setDeleteItemId(null);
            setModalOpen(false);
        }
    };

    useEffect(() => {
        const fetchQuestionTypes = async () => {
            try {
                const response = await axiosInstance.get(`admin/category/getAllCategory`);
                if (response.data.isSuccess) {
                    const categories = response.data.data;
                    const homeCategory = categories.find(category => category.name === "Assignment Help");
                    if (homeCategory) {
                        const categoryId = homeCategory._id;
                        await fetchSubcategories(categoryId);
                    } else {
                        toast.error("No category named 'home' found.");
                    }
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                toast.error('Something went wrong. Please try again!!');
            }
        };

        const fetchSubcategories = async (categoryId) => {
            try {
                const response = await axiosInstance.get(`admin/subcategory/getCategoryWiseSubcategory/${categoryId}`);
                if (response.data.isSuccess) {
                    setSubCategoryData(response.data.subCategory);
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                toast.error('Something went wrong. Please try again!!');
            }
        };

        fetchQuestionTypes();
    }, []);

    const handleSubCategoryChange = () => {
        fetchData(1);
    };

    return (
        <>
            <div>
                <div className="mt-4 mb-4">
                    <div className='row mb-4'>
                        <div className='d-flex justify-content-between'>
                            <div>
                                <h4 className="page-title">Assignment Price</h4>
                            </div>
                            <div>
                                <Link to='/addassignmentprice'>
                                    <Button className="action-button add-from-new" >
                                        <img src={pluscircle} alt="plus" width={20} className='ADD_code' />
                                        <span className=""> Add </span>
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <Card className='mt-4 '>
                        <CardBody>
                            <div className="form-group row text-end">
                                <FormGroup>
                                    <Input
                                        id="exampleSelect"
                                        name="subCategoryId"
                                        type="select"
                                        onChange={(e) => {
                                            setSubCategoryId(e.target.value);
                                        }}
                                        onBlur={handleSubCategoryChange}
                                    >
                                        <option value=""> --- Select sub category --- </option>
                                        {subCategoryData?.map((subcat) => (
                                            <option key={subcat._id} value={subcat._id}>
                                                {subcat.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </div>

                            {loading ? (
                                <div className="text-center mt-4">
                                    <p>Loading...</p>
                                </div>
                            ) : assignmentData?.length > 0 ?
                                <div className="table-responsive">
                                    <Table bordered responsive className="mt-2 text-center" style={{ fontSize: '0.8rem' }}>
                                        <thead>
                                            <tr>
                                                <th>Sr. No</th>
                                                <th>Sub Category</th>
                                                <th>Min. Words</th>
                                                <th>Max. Words</th>
                                                <th>Hours</th>
                                                <th>Assignment Price</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {assignmentData?.map((data, index) => {
                                                const serialNumber = (currentPage - 1) * 10 + index + 1;
                                                return (
                                                    <tr key={index}>
                                                        <th scope="row">{serialNumber}</th>
                                                        <td>{data.subCategoryId.name}</td>
                                                        <td>{data.minWords}</td>
                                                        <td>{data.maxWords}</td>
                                                        <td>{data.hours}</td>
                                                        <td>${data.assignmentPrice}</td>
                                                        <td className='d-flex justify-content-center'>
                                                            <div className={`${Styles.edit_code}`}>
                                                                <Button className={`${Styles.edit_code}`} onClick={() => { navigator('/addassignmentprice', { state: data }) }}>
                                                                    <img
                                                                        src={grp713}
                                                                        alt="error"
                                                                        className=""
                                                                        srcSet=""
                                                                        width={20}
                                                                    />
                                                                    Edit
                                                                </Button>
                                                            </div>
                                                            <div className={`${Styles.delete_button_code} me-2 mx-2`}>
                                                                <Button className={`${Styles.delete_button_code}`} onClick={() => handleDelete(data._id)}>
                                                                    <img
                                                                        src={delete1}
                                                                        alt="error"
                                                                        className={`${Styles.delete_icon}`}
                                                                        srcSet=""
                                                                    />
                                                                    Delete
                                                                </Button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                                : (
                                    <div className="text-center mt-4">
                                        <p>No data found.</p>
                                    </div>
                                )}

                            {/* <div className="float-end">
                                <Pagination>
                                    <PaginationItem disabled={currentPage === 1}>
                                        <PaginationLink previous onClick={() => handlePageClick(currentPage - 1)} />
                                    </PaginationItem>

                                    {[...Array(totalPages)].map((_, i) => {
                                        return (
                                            <PaginationItem key={i} active={i + 1 === currentPage}>
                                                <PaginationLink onClick={() => handlePageClick(i + 1)}>{i + 1}</PaginationLink>
                                            </PaginationItem>
                                        );
                                    })}

                                    <PaginationItem disabled={currentPage === totalPages}>
                                        <PaginationLink next onClick={() => handlePageClick(currentPage + 1)} />
                                    </PaginationItem>
                                </Pagination>
                            </div> */}

                            <div className="float-end">
                                <Pagination>
                                    <PaginationItem disabled={currentPage === 1}>
                                        <PaginationLink previous onClick={() => handlePageClick(currentPage - 1)} />
                                    </PaginationItem>

                                    {[...Array(totalPages)].map((_, i) => {
                                        // Display the current page, pages near the current page, and ellipsis
                                        if (
                                            i === 0 || // First page
                                            i + 1 === currentPage || // Current page
                                            i === totalPages - 1 || // Last page
                                            (i >= currentPage - 2 && i <= currentPage + 2) // Pages near the current page
                                        ) {
                                            return (
                                                <PaginationItem key={i} active={i + 1 === currentPage}>
                                                    <PaginationLink onClick={() => handlePageClick(i + 1)}>{i + 1}</PaginationLink>
                                                </PaginationItem>
                                            );
                                        } else if (
                                            // Display ellipsis when there are pages before or after the current page
                                            i === 1 && currentPage > 4 ||
                                            i === totalPages - 2 && currentPage < totalPages - 3
                                        ) {
                                            return (
                                                <PaginationItem key={i} disabled>
                                                    <PaginationLink>...</PaginationLink>
                                                </PaginationItem>
                                            );
                                        }
                                        return null;
                                    })}

                                    <PaginationItem disabled={currentPage === totalPages}>
                                        <PaginationLink next onClick={() => handlePageClick(currentPage + 1)} />
                                    </PaginationItem>
                                </Pagination>
                            </div>

                        </CardBody>
                    </Card>
                </div>
            </div>
            <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} style={{ top: "33%" }}>
                <ModalHeader toggle={() => setModalOpen(!modalOpen)}>Confirm Delete</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this assignment price?
                </ModalBody>
                <ModalFooter>
                    <div className={`${Styles.button_color}`}>
                        <Button
                            type="cancel"
                            onClick={() => setModalOpen(!modalOpen)}
                        >
                            No
                        </Button>
                    </div>
                    <div className={`${Styles.cancel_button} me-4`}>
                        <Button
                            type="cancel"
                            className={`${Styles.cancel_button}`}
                            onClick={confirmDelete}
                        >
                            Yes
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default AssignmentPrice;