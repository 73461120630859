import React, { useState } from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import Styles from "../homework/assets/Form.module.css";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInterceptor";
import ReactPlayer from 'react-player';

const AddTestimonial = () => {
    const location = useLocation();
    const editData = location.state;
    const [formSubmitted, setFormSubmitted] = useState(false);
    const navigator = useNavigate();

    const [data, setData] = useState({
        videoLink: editData?.videoLink || "",
        isActive: editData?.isActive || false,
    });

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        setData({ ...data, [name]: name === "isActive" ? checked : value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        if (!data.videoLink || !data.isActive) {
            toast.error("Please fill all fields");
            return;
        }
        try {
            const response = await axiosInstance.post(
                `admin/testimonial/createTestimonial`,
                data
            );
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/testimonials");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Something went wrong. Please try again!!");
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.put(
                `admin/testimonial/updateTestimonial/${editData._id}`,
                data
            );
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/testimonials");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Something went wrong. Please try again!!");
        }
    };

    return (
        <div>
            <div className="mt-4 mb-4">
                <div className="row">
                    <div className="col-sm-6">
                        <h4 className="page-name">Add Testimonials</h4>
                    </div>
                </div>
                <Row>
                    <Col md="8">
                        <Card className="mt-4">
                            <CardBody>
                                <form
                                    className="ps-5 pe-5 pt-3"
                                    onSubmit={editData?._id ? handleUpdate : handleSubmit}
                                >
                                    <div className="form-group row mb-4">
                                        <Label
                                            for="inputPassword"
                                            className="col-sm-4 col-form-label"
                                        >
                                            Video Link
                                        </Label>
                                        <div className="col-sm-8">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="inputPassword"
                                                placeholder="Enter link"
                                                name="videoLink"
                                                invalid={!data.videoLink && formSubmitted}
                                                defaultValue={data.videoLink}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <FormFeedback>Video link is required</FormFeedback>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <Label
                                            for="inputPassword"
                                            className="col-sm-4 col-form-label"
                                        >
                                            IsActive
                                        </Label>
                                        <div className="col-sm-7">
                                            <FormGroup switch>
                                                <Input
                                                    type="checkbox"
                                                    className={`${Styles.form_check_input}`}
                                                    name="isActive"
                                                    checked={data.isActive}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                                <Label check className="mx-1">
                                                    {data.isActive ? "Active" : "Inactive"}
                                                </Label>
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <Label
                                            for="inputPassword"
                                            className="col-sm-4 col-form-label"
                                        >
                                            <b className="ms-2"></b>
                                        </Label>
                                        <div className="col-sm-7 d-flex">
                                            <div className={`${Styles.submit_button}`}>
                                                <Button type="submit">
                                                    {editData?._id ? "Update" : "Save"}
                                                </Button>
                                            </div>
                                            <div className={`${Styles.cancel_button} me-4 mx-2`}>
                                                <Link to="/testimonials">
                                                    {" "}
                                                    <Button className={`${Styles.cancel_button}`}>
                                                        Cancel
                                                    </Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card className="mt-4">
                            <CardBody>
                                {data.videoLink ? (
                                    <div className="w-100 h-75 text-center">
                                        <p className="mt-3">Video Preview</p>
                                        <ReactPlayer
                                            url={data.videoLink}
                                            controls
                                            width="100%"
                                            height="100%"
                                            className="react-player px-4"
                                        />
                                    </div>
                                ) : (
                                    <p className="text-center">No video found</p>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default AddTestimonial;
