import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Input, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap';
import Styles from '../homework/assets/Form.module.css'
import { FaSearch, } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import pluscircle from '../homework/assets/images/plus circle_.png'
import grp713 from '../homework/assets/images/Group 713.png'
import delete1 from '../homework/assets/images/delete.png'
import axiosInstance from '../../axiosInterceptor';
import { dateFormat } from '../../dateFormat';
import { ImageLink } from '../../constants';

const Subject = () => {
    const [subjectData, setSubjectData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const navigator = useNavigate();

    const fetchData = async (page = 1) => {
        try {
            setLoading(true);
            const response = await axiosInstance.post(`admin/subject/getSubjects`,
                {
                    search: searchTerm,
                    perPage: perPage,
                    pageNo: page,
                }
            );
            const { data, totalPages } = response.data;
            setSubjectData(data);
            setTotalPages(totalPages);
            setCurrentPage(page);
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handlePageClick = (page) => {
        if (page >= 1 && page <= totalPages) {
            fetchData(page);
        }
    };

    const handleDelete = async (id) => {
        setDeleteItemId(id);
        setModalOpen(true);
    };

    const confirmDelete = async () => {
        try {
            const response = await axiosInstance.delete(`admin/subject/deleteSubject/${deleteItemId}`);
            if (response.status === 200) {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        } finally {
            setDeleteItemId(null);
            setModalOpen(false);
        }
    };


    return (
        <>
            <div>
                <div className="my-2">
                    <div className='row mb-2'>
                        <div className='d-flex justify-content-between'>
                            <div>
                                <h4 className="page-title">Subject</h4>
                            </div>
                            <div>
                                <Link to='/addsubject'>
                                    <Button className="action-button add-from-new" >
                                        <img src={pluscircle} alt="plus" width={20} className='ADD_code' />
                                        <span className=""> Add </span>
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <Card>
                        <CardBody>
                            <Row className='Extra_padding'>
                                <InputGroup className='search_input_icon'>
                                    <Input
                                        type="text"
                                        id="fname"
                                        placeholder="Search.."
                                        name="searchTerm"
                                        className='border_none_icon'
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                fetchData();
                                            }
                                        }}
                                    />
                                    <div className="input-group-append" >
                                        <span className="input-group-text wallte" onClick={() => fetchData()} >
                                            <FaSearch />
                                        </span>
                                    </div>
                                </InputGroup>
                            </Row>
                            {loading ? (
                                <div className="text-center mt-4">
                                    <p>Loading...</p>
                                </div>
                            ) : subjectData?.length > 0 ?
                                <Table bordered responsive className="mt-4 text-center" style={{fontSize: '0.8r'}}>
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Images</th>
                                            <th>Subject</th>
                                            <th>Updated At</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {subjectData?.map((subject, index) => {
                                            const serialNumber = (currentPage - 1) * perPage + index + 1;
                                            return (
                                                <tr key={index}>
                                                    <th scope="row">{serialNumber}</th>
                                                    <td><img src={ImageLink(subject.image)} className="w-50" alt='Subject_img' /></td>
                                                    <td>{subject.questionSubject}</td>
                                                    <td>{dateFormat(subject.updatedAt)}</td>
                                                    <td className='d-flex justify-content-center'>
                                                        <div className={`${Styles.edit_code}`}>
                                                            <Button className={`${Styles.edit_code}`} onClick={() => { navigator('/addsubject', { state: subject }) }}>
                                                                <img
                                                                    src={grp713}
                                                                    alt="error"
                                                                    className=""
                                                                    srcSet=""
                                                                    width={20}
                                                                />
                                                                Edit
                                                            </Button>
                                                        </div>
                                                        <div className={`${Styles.delete_button_code} me-2 mx-2`}>
                                                            <Button className={`${Styles.delete_button_code}`} onClick={() => handleDelete(subject._id)}>
                                                                <img
                                                                    src={delete1}
                                                                    alt="error"
                                                                    className={`${Styles.delete_icon}`}
                                                                    srcSet=""
                                                                />
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        )}
                                    </tbody>
                                </Table>
                                : (
                                    <div className="text-center mt-4">
                                        <p>No data found.</p>
                                    </div>
                                )}
                            <div className="float-end">
                                <Pagination>
                                    <PaginationItem disabled={currentPage === 1}>
                                        <PaginationLink previous onClick={() => handlePageClick(currentPage - 1)} />
                                    </PaginationItem>
                                    {[...Array(totalPages)].map((_, i) => (
                                        <PaginationItem key={i} active={i + 1 === currentPage}>
                                            <PaginationLink onClick={() => handlePageClick(i + 1)}>{i + 1}</PaginationLink>
                                        </PaginationItem>
                                    ))}
                                    <PaginationItem disabled={currentPage === totalPages}>
                                        <PaginationLink next onClick={() => handlePageClick(currentPage + 1)} />
                                    </PaginationItem>
                                </Pagination>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>
            <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} style={{ top: "33%" }}>
                <ModalHeader toggle={() => setModalOpen(!modalOpen)}>Confirm Delete</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this subject?
                </ModalBody>
                <ModalFooter>
                    <div className={`${Styles.button_color}`}>
                        <Button
                            type="cancel"
                            onClick={() => setModalOpen(!modalOpen)}
                        >
                            No
                        </Button>
                    </div>
                    <div className={`${Styles.cancel_button} me-4`}>
                        <Button
                            type="cancel"
                            className={`${Styles.cancel_button}`}
                            onClick={confirmDelete}
                        >
                            Yes
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default Subject