import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Styles from "../homework/assets/Form.module.css";
import { Button, Input, Label } from "reactstrap";
import axiosInstance from "../../axiosInterceptor";
import pluscircle from "../homework/assets/images/plus circle_.png";
import { toast } from "react-toastify";

const baseURL = process.env.REACT_APP_FILE_BASE_URL;

const AddAssignmentLibrary = () => {
  const location = useLocation();
  const editData = location.state;
  const navigate = useNavigate();
  const formRef = useRef(null);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [solutionFiles, setSolutionFiles] = useState([]);

  const [questionTitle, setQuestionTitle] = useState(
    editData?.questionTitle || ""
  );
  const [solutionTitle, setSolutionTitle] = useState(
    editData?.answerTitle || ""
  );

  useEffect(() => {
    if (editData?.questionFile) {
      const initialFiles = editData.questionFile.map((file) => ({
        name: file.split("/").pop(),
        url: `${baseURL}${file}`,
        isNew: false,
      }));
      setSelectedFiles(initialFiles);
    }
    if (editData?.solutionFile) {
      const initialSolutionFiles = editData.solutionFile.map((file) => ({
        name: file.split("/").pop(),
        url: `${baseURL}${file}`,
        isNew: false,
      }));
      setSolutionFiles(initialSolutionFiles);
    }
  }, [editData, baseURL]);

  const [questionTypes, setQuestionTypes] = useState([]);
  const [selectedQuestionType, setSelectedQuestionType] = useState(
    editData?.subCategoryId?._id || ""
  );

  useEffect(() => {
    const fetchQuestionTypes = async () => {
      try {
        const response = await axiosInstance.get(
          `admin/category/getAllCategory`
        );
        if (response.data.isSuccess) {
          const categories = response.data.data;
          const homeCategory = categories.find(
            (category) => category.name === "Assignment Help"
          );
          if (homeCategory) {
            const categoryId = homeCategory._id;
            await fetchSubcategories(categoryId);
          } else {
            toast.error("No category named 'Assignment Help' found.");
          }
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again!!");
      }
    };

    const fetchSubcategories = async (categoryId) => {
      try {
        const response = await axiosInstance.get(
          `admin/subcategory/getCategoryWiseSubcategory/${categoryId}`
        );
        if (response.data.isSuccess) {
          setQuestionTypes(response.data.subCategory);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again!!");
      }
    };

    fetchQuestionTypes();
  }, []);

  const handleSubmit = async () => {
    if (!editData) {
      if (!questionTitle) {
        toast.error("Please enter a question title.");
        return;
      }
      if (!solutionTitle) {
        toast.error("Please enter a solution title.");
        return;
      }
      if (!selectedQuestionType) {
        toast.error("Please select a question type.");
        return;
      }
      if (formRef.current?.assignment_file_question?.files.length === 0) {
        toast.error("Please upload at least one question file.");
        return;
      }
      if (formRef.current?.assignment_file_reference?.files.length === 0) {
        toast.error("Please upload at least one solution file.");
        return;
      }
    }

    try {
      const formData = new FormData();
      formData.append("questionTitle", questionTitle);
      formData.append("answerTitle", solutionTitle);
      formData.append("subCategoryId", selectedQuestionType);

      const filePromises = selectedFiles.map(async (fileDetail) => {
        if (fileDetail.isNew) {
          formData.append("questionFile", fileDetail.file);
        } else {
          const response = await fetch(fileDetail.url);
          const blob = await response.blob();
          const fileName = fileDetail.url.split("/").pop();
          formData.append("questionFile", new File([blob], fileName));
        }
      });

      const solutionPromises = solutionFiles.map(async (fileDetail) => {
        if (fileDetail.isNew) {
          formData.append("solutionFile", fileDetail.file);
        } else {
          const response = await fetch(fileDetail.url);
          const blob = await response.blob();
          const fileName = fileDetail.url.split("/").pop();
          formData.append("solutionFile", new File([blob], fileName));
        }
      });

      await Promise.all([...filePromises, ...solutionPromises]);

      let response;
      if (editData) {
        response = await axiosInstance.put(
          `admin/libraryAssignmentHelp/updateQA/${editData._id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        response = await axiosInstance.post(
          `admin/libraryAssignmentHelp/createQA`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      if (response.data.isSuccess) {
        toast.success(response.data.message);
        handleReset();
        navigate("/assignment-library");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  const handleAddClick = () => {
    handleSubmit();
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const fileDetails = files.map((file) => ({
      name: file.name,
      file: file,
      isNew: true,
    }));
    setSelectedFiles((prevFiles) => [...prevFiles, ...fileDetails]);
  };

  const handleSolutionFileChange = (event) => {
    const files = Array.from(event.target.files);
    const fileDetails = files.map((file) => ({
      name: file.name,
      file: file,
      isNew: true,
    }));
    setSolutionFiles((prevFiles) => [...prevFiles, ...fileDetails]);
  };

  const handleRemoveFile = async (index, type) => {
    const files = type === "question" ? selectedFiles : solutionFiles;
    const fileToRemove = files[index];
    const newFiles = files.filter((_, i) => i !== index);

    if (type === "question") {
      setSelectedFiles(newFiles);
    } else {
      setSolutionFiles(newFiles);
    }

    if (!fileToRemove.isNew) {
      const requestData = {
        paymentSection: "LibraryAssignment",
        fieldName: type === "question" ? "questionFile" : "solutionFile",
        questionId: editData?._id,
        fileName: fileToRemove.url.split("/").pop(),
      };

      try {
        const response = await axiosInstance.post(
          "admin/deleteFile",
          requestData
        );
        if (response.data.isSuccess) {
          // toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again!!");
      }
    }
  };

  const handleQuestionTypeChange = (event) => {
    setSelectedQuestionType(event.target.value);
  };

  const handleReset = () => {
    setQuestionTitle("");
    setSolutionTitle("");
    setSelectedFiles([]);
    setSolutionFiles([]);
    setSelectedQuestionType("");
  };

  return (
    <div>
      <div className={`${Styles.second_container}`}>
        <div className="mt-4 mb-4">
          <div className="row">
            <div className="col-sm-6">
              <h4 className="page-title">Assignment Library</h4>
              <p className="page-subtitle mt-2">Add Q & A</p>
            </div>
            <div className="col-sm-6 text-end">
              <Link to="/assignment-library">
                <Button>Back</Button>
              </Link>
            </div>
          </div>
        </div>
        <div className={`bg-white ${Styles.assignment_content}`}>
          <div className="ps-5 pe-5">
            <form className="pt-3" ref={formRef}>
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-group mb-4">
                    <Label for="inputPassword" className="col-form-label">
                      Question Title
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="inputPassword"
                      placeholder="Enter your question title"
                      name="questionTitle"
                      value={questionTitle}
                      onChange={(e) => setQuestionTitle(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group mb-4">
                    <Label for="inputPassword" className="col-form-label">
                      Solution Title
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="inputPassword"
                      placeholder="Enter your solution title"
                      name="answerTitle"
                      value={solutionTitle}
                      onChange={(e) => setSolutionTitle(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group mb-4">
                    <Label for="inputPassword" className="col-form-label">
                      Assignment Type
                    </Label>
                    <Input
                      id="exampleSelect"
                      name="select"
                      type="select"
                      onChange={handleQuestionTypeChange}
                      value={selectedQuestionType}
                    >
                      <option value="">
                        {editData
                          ? editData?.subCategoryId
                            ? editData?.subCategoryId.name
                            : "Please select question type"
                          : "Please select question type"}
                      </option>
                      {questionTypes?.map((questionType) => (
                        <option key={questionType._id} value={questionType._id}>
                          {questionType.name}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group mb-4">
                    <Label className="col-form-label">Question Files</Label>
                    <div
                      style={{
                        backgroundColor: "#F5F5F5",
                        height: "auto",
                        padding: "0px",
                      }}
                    >
                      <Input
                        type="file"
                        name="assignment_file_question"
                        multiple
                        onChange={handleFileChange}
                      />
                      <div className="d-flex align-items-center mt-4">
                        {selectedFiles?.length > 0 && (
                          <ol>
                            {selectedFiles.map((fileDetail, index) => (
                              <li className="mb-2 answer-pdf" key={index}>
                                {fileDetail.isNew ? (
                                  <span>{fileDetail.name}</span>
                                ) : (
                                  <Link
                                    className="text-decoration-none"
                                    to={fileDetail.url}
                                    target="_blank"
                                  >
                                    {fileDetail.name}
                                  </Link>
                                )}
                                <button
                                  className="mx-2 answer-pdf-box"
                                  type="button"
                                  onClick={() =>
                                    handleRemoveFile(index, "question")
                                  }
                                >
                                  X
                                </button>
                              </li>
                            ))}
                          </ol>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group mb-4">
                    <Label for="inputPassword" className="col-form-label">
                      Solution File
                    </Label>
                    <div
                      style={{
                        backgroundColor: "#F5F5F5",
                        height: "auto",
                        padding: "0px",
                      }}
                    >
                      <Input
                        type="file"
                        name="assignment_file_reference"
                        multiple
                        onChange={handleSolutionFileChange}
                      />
                      <div className="d-flex align-items-center mt-4">
                        {solutionFiles?.length > 0 && (
                          <ol>
                            {solutionFiles.map((fileDetail, index) => (
                              <li className="mb-2 answer-pdf" key={index}>
                                {fileDetail.isNew ? (
                                  <span>{fileDetail.name}</span>
                                ) : (
                                  <Link
                                    className="text-decoration-none"
                                    to={fileDetail.url}
                                    target="_blank"
                                  >
                                    {fileDetail.name}
                                  </Link>
                                )}
                                <button
                                  className="mx-2 answer-pdf-box"
                                  type="button"
                                  onClick={() =>
                                    handleRemoveFile(index, "solution")
                                  }
                                >
                                  X
                                </button>
                              </li>
                            ))}
                          </ol>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="row mb-4">
              <div className="col-sm-12">
                <Button
                  className="action-button add-from-new"
                  onClick={handleAddClick}
                >
                  <img src={pluscircle} alt="plus" width={30} />
                  <span className="mx-2"> Add</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAssignmentLibrary;
