import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
// import { FaSearch } from 'react-icons/fa';
import "./Componet.scss";
import axiosInstance from "../../axiosInterceptor";
import { dateFormat } from "../../dateFormat";
import Select from "react-select";
import { toast } from "react-toastify";
import { DateRangePicker } from "rsuite";
import "rsuite/DateRangePicker/styles/index.css";

const Rewards = () => {
  const [rewardsData, setRewardsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);

  // const [dateFilter, setDateFilter] = useState(new Date().toISOString().split('T')[0]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchData = async (page = 1) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post("admin/reward/getReward", {
        // fromDate: dateFilter,
        // toDate: dateFilter,
        fromDate: fromDate,
        toDate: toDate,
        perPage: 10,
        pageNo: page,
        studentId: selectedStudent?.value || null, // Use the selectedStudent value or null
      });
      if (response.data.isSuccess) {
        setRewardsData(response.data.data || []);
        setTotalPages(response.data.totalPages);
        setCurrentPage(page);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchStudents = async () => {
    try {
      const response = await axiosInstance.get("admin/student/getAllStudent");
      if (response.data.isSuccess) {
        const studentOptions = response.data.data.map((student) => ({
          value: student._id,
          label: student.email,
        }));
        setStudents(studentOptions);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  useEffect(() => {
    fetchData();
    fetchStudents();
  }, [fromDate, toDate, selectedStudent]);

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      fetchData(page);
    }
  };

  const handleStudentChange = (selectedOption) => {
    setSelectedStudent(selectedOption);
  };

  const handleGiftClick = async (rewardId) => {
    try {
      const response = await axiosInstance.get(
        `admin/reward/sendReward/${rewardId}`
      );
      if (response.data.isSuccess) {
        toast.success(response.data.message);
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  return (
    <div className="my-2">
      <h4 className="page-title">Rewards</h4>
      <div className="rewards-table">
        <Card>
          <CardBody>
            <Row>
              {/* <Col md="8">
                                <InputGroup className='search_input_icon'>
                                    <Input
                                        type="text"
                                        id="fname"
                                        placeholder="Search.."
                                        name="fname"
                                        className='border_none_icon'
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text wallte">
                                            <FaSearch />
                                        </span>
                                    </div>
                                </InputGroup>
                            </Col> */}
              {/* <Col md="4" /> */}
              <Col md="6">
                <DateRangePicker
                  className="w-100"
                  // value={[new Date(fromDate), new Date(toDate)]}
                  onChange={(value) => {
                    if (value && value[0]) {
                      setFromDate(value[0].toISOString().split("T")[0]);
                      setToDate(
                        value[1] ? value[1].toISOString().split("T")[0] : ""
                      );
                    }
                  }}
                  onClean={() => {
                    setFromDate(null);
                    setToDate(null);
                  }}
                />
              </Col>
              <Col md="6">
                <Select
                  id="exampleSelect"
                  name="select"
                  options={students}
                  value={selectedStudent}
                  onChange={handleStudentChange}
                  placeholder="Student email"
                />
              </Col>
              {/* <Col md="4">
                                <Input
                                    type="date"
                                    id="inputDate"
                                    className="form-control"
                                    value={dateFilter}
                                    onChange={(e) => setDateFilter(e.target.value)}
                                />
                            </Col> */}
            </Row>
            {loading ? (
              <div className="text-center mt-4">
                <p>Loading...</p>
              </div>
            ) : rewardsData?.length > 0 ? (
              <div className="table-responsive">
                <Table
                  bordered
                  responsive
                  className="mt-4"
                  style={{ fontSize: "0.8rem" }}
                >
                  <thead>
                    <tr className="font-small-head">
                      <th>Date</th>
                      <th>Streak</th>
                      <th className="text-center">Reward</th>
                      <th className="text-center">Count Post</th>
                      <th className="text-center">Gifts</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rewardsData?.map((rewards, index) => {
                      return (
                        <tr key={rewards._id}>
                          <td>
                            {dateFormat(rewards.updatedAt)}
                            <br />
                            {rewards?.studentId?.email}
                          </td>
                          <td>{rewards?.postingStreakId?.streak}</td>
                          <td className="text-center">
                            {rewards?.postingStreakId?.rewards}
                          </td>
                          <td className="text-center">
                            {rewards?.postingStreakId?.countPost}
                          </td>
                          <td className="text-center">
                            {(rewards?.isSendGift && "Sended") || "Pending"}
                          </td>
                          <td className="text-center">
                            <button
                              type="submit"
                              onClick={() => handleGiftClick(rewards._id)}
                              disabled={rewards.isSendGift}
                              className={
                                rewards.isSendGift
                                  ? "disabled-button"
                                  : "gift-send"
                              }
                            >
                              Share Gift
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              <div className="text-center mt-4">
                <p>No data found.</p>
              </div>
            )}

            <div className="float-end">
              <Pagination>
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink
                    previous
                    onClick={() => handlePageClick(currentPage - 1)}
                  />
                </PaginationItem>
                {[...Array(totalPages)].map((_, i) => (
                  <PaginationItem key={i} active={i + 1 === currentPage}>
                    <PaginationLink onClick={() => handlePageClick(i + 1)}>
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink
                    next
                    onClick={() => handlePageClick(currentPage + 1)}
                  />
                </PaginationItem>
              </Pagination>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Rewards;
