import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
} from "reactstrap";
import "./Componet.scss";
import axiosInstance from "../../axiosInterceptor";
import { toast } from "react-toastify";
import "rsuite/DateRangePicker/styles/index.css";

const Referralpayment = () => {
  const [rewardsData, setRewardsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchData = async (page = 1) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        "admin/reward/getReferralReward",
        {
          perPage: 10,
          pageNo: page,
        }
      );
      if (response.data.isSuccess) {
        setRewardsData(response.data.data || []);
        setTotalPages(response.data.totalPages);
        setCurrentPage(page);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      fetchData(page);
    }
  };

  const handleGiftClick = async (rewardId) => {
    try {
      const response = await axiosInstance.get(
        `admin/reward/changeStatus/${rewardId}`
      );
      if (response.data.isSuccess) {
        toast.success(response.data.message);
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  return (
    <div className="my-2">
      <h4 className="page-title">Referral Payment</h4>
      <div className="rewards-table">
        <Card>
          <CardBody>
            <div className="table-responsive">
              <Table
                bordered
                responsive
                className="mt-4"
                style={{ fontSize: "0.8rem" }}
              >
                <thead>
                  <tr className="font-small-head">
                    <th>Date</th>
                    <th>Referral Name</th>
                    <th className="text-center">Amount</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="5" className="text-center">
                        <p>Loading...</p>
                      </td>
                    </tr>
                  ) : rewardsData?.length > 0 ? (
                    rewardsData.map((rewards, index) => (
                      <tr key={rewards._id}>
                        <td>{rewards?.studentId?.email}</td>
                        <td>{rewards?.childId?.email}</td>
                        <td className="text-center">
                          ${rewards?.amount?.toFixed(2)}
                        </td>
                        <td className="text-center">{rewards?.status}</td>
                        <td className="text-center">
                          <button
                            type="submit"
                            onClick={() => handleGiftClick(rewards._id)}
                            disabled={rewards.status === "Paid"}
                            className={
                              rewards.status === "Paid"
                                ? "disabled-button"
                                : "gift-send"
                            }
                          >
                            Pay Now
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        <p>No data found.</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>

            <div className="float-end">
              <Pagination>
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink
                    previous
                    onClick={() => handlePageClick(currentPage - 1)}
                  />
                </PaginationItem>
                {[...Array(totalPages)].map((_, i) => (
                  <PaginationItem key={i} active={i + 1 === currentPage}>
                    <PaginationLink onClick={() => handlePageClick(i + 1)}>
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink
                    next
                    onClick={() => handlePageClick(currentPage + 1)}
                  />
                </PaginationItem>
              </Pagination>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Referralpayment;
