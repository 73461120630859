import React from 'react'
import { useLocation } from 'react-router-dom';
import { Card, CardBody, Table } from 'reactstrap';

const Referraldetails = () => {

    const location = useLocation();
    const editData = location.state;

    return (
        <div className="my-2">
            <h4 className="page-title">Referral Details</h4>
            <div className="student-list">
                <Card>
                    <CardBody>
                        <div className="table-responsive">
                            <Table bordered className="mt-4" responsive style={{ fontSize: '0.8rem' }}>
                                <thead>
                                    <tr className="font-small-head">
                                        <th>Email</th>
                                        <th className="text-center">Country</th>
                                        <th className="text-center">Total Paid</th>
                                        <th className="text-center">Total Pending</th>
                                        <th className="text-center">Total Referral</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {editData?.getReferral?.length > 0 ? (
                                        editData.getReferral.map(referral => (
                                            <tr key={referral._id}>
                                                <td>{referral.email}</td>
                                                <td className="text-center">{referral?.countryId?.countryName}</td>
                                                <td className="text-center">${referral.paidAmount?.toFixed(2)}</td>
                                                <td className="text-center">${referral.pendingAmount?.toFixed(2)}</td>
                                                <td className="text-center">${referral.referralAmount?.toFixed(2)}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5" className="text-center">No data found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default Referraldetails