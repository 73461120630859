import React, { useState } from 'react'
import { Button, Card, CardBody, Col, FormFeedback, Input, Label, Row } from 'reactstrap'
import Styles from '../homework/assets/Form.module.css'
import { toast } from 'react-toastify'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../../axiosInterceptor'
import { ImageLink } from '../../constants'

const AddSubject = () => {
    const location = useLocation();
    const subjectData = location.state;
    const [formSubmitted, setFormSubmitted] = useState(false)
    const navigator = useNavigate();
    const [data, setData] = useState({
        questionSubject: subjectData?.questionSubject || '',
        image: subjectData?.image || '',
    })

    const handleChange = (e) => {
        const { name, files } = e.target;
        if (name === 'image') {
            setData({ ...data, [name]: files.length > 0 ? files[0] : null });
        } else {
            setData({
                ...data,
                [name]: e.target.value,
            });
        }
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     setFormSubmitted(true)
    //     if (!data.questionSubject || !data.image) {
    //         return;
    //     }
    //     try {
    //         const response = await axiosInstance.post(`admin/subject/createsubject`, {
    //             questionSubject: data.questionSubject,
    //             image: data.image,
    //         });
    //         if (response.status === 200) {
    //             toast.success(response.data.message);
    //             navigator("/subject");
    //         } else {
    //             toast.error(response.data.message);
    //         }
    //     } catch (error) {
    //         toast.error('Something went wrong. Please try again!!');
    //     }
    // };

    // const handleUpdate = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const response = await axiosInstance.put(`admin/subject/updateSubject/${subjectData?._id}`, {
    //             questionSubject: data.questionSubject,
    //             image: data.image,
    //         });
    //         if (response.status === 200) {
    //             toast.success(response.data.message);
    //             navigator("/subject");
    //         } else {
    //             toast.error(response.data.message);
    //         }
    //     } catch (error) {
    //         toast.error('Something went wrong. Please try again!!');
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        if (!data.questionSubject || !data.image) {
            return;
        }
        try {
            const formData = new FormData();
            formData.append('questionSubject', data.questionSubject);
            formData.append('image', data.image);
            const response = await axiosInstance.post(`admin/subject/createsubject`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/subject");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('questionSubject', data.questionSubject);
            formData.append('image', data.image);
            const response = await axiosInstance.put(`admin/subject/updateSubject/${subjectData?._id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/subject");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };


    return (
        <div>
            <div className="mt-4 mb-4">
                <div className='row'>
                    <div className='col-sm-6'>
                        <h4 className="page-title">Add Subject</h4>
                    </div>
                </div>
                <Card className='mt-4'>
                    <CardBody>
                        <Row>
                            <Col md="8">
                                <form className="ps-5 pe-5 pt-3" onSubmit={subjectData?._id ? handleUpdate : handleSubmit}>
                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Subject Name
                                        </Label>
                                        <div className="col-sm-7">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="inputPassword"
                                                placeholder="Enter your subject name "
                                                invalid={!subjectData && !data.questionSubject && formSubmitted}
                                                name='questionSubject'
                                                value={data.questionSubject}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <FormFeedback>Subject name is required</FormFeedback>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <Label for="img" className="col-sm-4 col-form-label">
                                            Image
                                        </Label>
                                        <div className="col-sm-7">
                                            <Input
                                                type="file"
                                                id="img"
                                                name="image"
                                                invalid={!subjectData && !data.image && formSubmitted}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <FormFeedback>Subject image is required</FormFeedback>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            <b className="ms-2"></b>
                                        </Label>
                                        <div className="col-sm-7 d-flex">
                                            <div className={`${Styles.submit_button}`}>
                                                <Button type='submit'>{subjectData?._id ? "Update" : "Save"}</Button>
                                            </div>
                                            <div className={`${Styles.cancel_button} me-4 mx-2`}>
                                                <Link to='/subject'> <Button className={`${Styles.cancel_button}`}>Cancel</Button></Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Col>
                            {data.image && (
                                <Col md="4">
                                    <div className="border w-100 h-75 text-center">
                                        <p className="mt-3">Image Preview</p>
                                        <br />
                                        {data.image instanceof File ? (
                                            <img src={URL.createObjectURL(data.image)} alt="Preview" className="w-50" />
                                        ) : (
                                            <img src={ImageLink(data.image)} alt="Preview" className="w-50" />
                                        )}
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default AddSubject