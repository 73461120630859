
import React, { useState } from 'react'
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import Styles from '../homework/assets/Form.module.css'
import { toast } from 'react-toastify'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../../axiosInterceptor'

const AddContactus = () => {
    const location = useLocation();
    const editData = location.state;


    const [data, setData] = useState({
        email: editData?.email || '',
        name: editData?.name || '',
        phoneNumber: editData?.phoneNumber || '',
        message: editData?.message || '',
        status: false
    })

    const handleChange = (e) => {
        const { name, value, checked } = e.target;

        if (name === 'isActive') {
            setData({ ...data, status: checked });
        } else {
            setData({ ...data, [name]: value });
        }
    };


    const navigator = useNavigate();
    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.put(`admin/contactUs/updateContact/${editData?._id}`, {
                email: editData?.email || '',
                name: editData?.name || '',
                phoneNumber: editData?.phoneNumber || '',
                message: editData?.message || '',
                status: data.status
            });
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/contact-us");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };




    return (
        <div>
            <div className="mt-4 mb-4">
                <div className='row'>
                    <div className='col-sm-6'>
                        <h4 className="page-title">Contact us</h4>
                    </div>
                </div>
                <Card className='mt-4'>
                    <CardBody>
                        <Row>
                            <Col md="8">
                                <form className="ps-5 pe-5 pt-3" onSubmit={editData?._id ? handleUpdate : undefined}>

                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Email
                                        </Label>
                                        <div className="col-sm-8">
                                            <Input
                                                type="input"
                                                className="form-control"
                                                id="inputPassword"
                                                placeholder="Enter your title"
                                                name='email'
                                                value={data.email}
                                                onChange={(e) => handleChange(e)}
                                                disabled
                                            />
                                        </div>
                                    </div>


                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Name
                                        </Label>
                                        <div className="col-sm-8">
                                            <Input
                                                type="input"
                                                className="form-control"
                                                id="inputPassword"
                                                placeholder="Enter your banner metaTitle"
                                                name='name'
                                                value={data.name}
                                                onChange={(e) => handleChange(e)}
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Contact No.
                                        </Label>
                                        <div className="col-sm-8">
                                            <Input
                                                type="input"
                                                className="form-control"
                                                id="inputPassword"
                                                placeholder="Enter your meta keyword"
                                                name='phoneNumber'
                                                value={data.phoneNumber}
                                                onChange={(e) => handleChange(e)}
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Message
                                        </Label>
                                        <div className="col-sm-8">
                                            <Input
                                                type="textarea"
                                                className="form-control"
                                                id="inputPassword"
                                                placeholder="Enter your meta description"
                                                name='message'
                                                value={data.message}
                                                onChange={(e) => handleChange(e)}
                                                rows={3}
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <Label
                                            for="inputPassword"
                                            className="col-sm-4 col-form-label"
                                        >
                                            Query
                                        </Label>
                                        <div className="col-sm-8">
                                            <FormGroup switch>
                                                <Input
                                                    type="checkbox"
                                                    className={`${Styles.form_check_input}`}
                                                    name="isActive"
                                                    checked={data.status}
                                                    onChange={handleChange}
                                                />

                                                <Label check className="mx-1">
                                                    {data.status ? "Solved" : "Unsolved"}
                                                </Label>
                                            </FormGroup>

                                        </div>
                                    </div>


                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            <b className="ms-2"></b>
                                        </Label>
                                        <div className="col-sm-7 d-flex">
                                            <div className={`${Styles.submit_button}`}>
                                                <Button type='submit'>{editData?._id ? "Update" : "Save"}</Button>
                                            </div>
                                            <div className={`${Styles.cancel_button} me-4 mx-2`}>
                                                <Link to='/contact-us'> <Button className={`${Styles.cancel_button}`}>Cancel</Button></Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default AddContactus