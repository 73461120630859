import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import Styles from "../homework/assets/Form.module.css";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInterceptor";
import { Rating } from 'react-simple-star-rating';

const AddStudentStory = () => {
  const location = useLocation();
  const editData = location.state;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigator = useNavigate();

  const [data, setData] = useState({
    name: editData?.name || "",
    title: editData?.title || "",
    description: editData?.description || "",
    status: editData?.status || "",
    rating: editData?.rating || 0,
  });

  const handleChange = (e) => {
    const { name } = e.target;
    setData({
      ...data,
      [name]: e.target.value,
    });
  };

  const handleRating = (rate) => {
    setData({
      ...data,
      rating: rate.toString(),
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!data.description || !data.name || !data.title) {
      return;
    }
    try {
      const response = await axiosInstance.post(
        `admin/studentStories/createStory`,
        data
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/reviews");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.put(
        `admin/studentStories/updateStory/${editData._id}`,
        data
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/reviews");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  return (
    <div>
      <div className="mt-4 mb-4">
        <div className="row">
          <div className="col-sm-6">
            <h4 className="page-name">Add Reviews</h4>
          </div>
        </div>
        <Card className="mt-4">
          <CardBody>
            <Row>
              <Col md="8">
                <form
                  className="ps-5 pe-5 pt-3"
                  onSubmit={editData?._id ? handleUpdate : handleSubmit}
                >
                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      Name
                    </Label>
                    <div className="col-sm-8">
                      <Input
                        type="text"
                        className="form-control"
                        id="inputPassword"
                        placeholder="Enter your name"
                        name="name"
                        invalid={!editData && !data.name && formSubmitted}
                        defaultValue={data.name}
                        onChange={(e) => handleChange(e)}
                      />
                      <FormFeedback>Name is required</FormFeedback>
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      Title
                    </Label>
                    <div className="col-sm-8">
                      <Input
                        type="text"
                        className="form-control"
                        id="inputPassword"
                        placeholder="Enter your title"
                        name="title"
                        invalid={!editData && !data.title && formSubmitted}
                        defaultValue={data.title}
                        onChange={(e) => handleChange(e)}
                      />
                      <FormFeedback>Title is required</FormFeedback>
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      Description
                    </Label>
                    <div className="col-sm-8">
                      <Input
                        type="textarea"
                        className="form-control"
                        id="inputPassword"
                        placeholder="Enter your description"
                        name="description"
                        invalid={
                          !editData && !data.description && formSubmitted
                        }
                        value={data.description}
                        onChange={(e) => handleChange(e)}
                        rows={5}
                      />
                      <FormFeedback>description is required</FormFeedback>
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      Rating
                    </Label>
                    <div className="col-sm-8">
                      <Rating
                        onClick={handleRating}
                        value={parseInt(data.rating)}
                        initialValue={data.rating}
                      />
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      Status
                    </Label>
                    <div className="col-sm-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="status"
                        id="statusMale"
                        value="Male"
                        checked={data.status === "Male"}
                        onChange={(e) => handleChange(e)}
                      />
                      <label className="form-check-label mx-2" htmlFor="statusMale">
                        Male
                      </label>
                    </div>
                    <div className="col-sm-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="status"
                        id="statusFemale"
                        value="Female"
                        checked={data.status === "Female"}
                        onChange={(e) => handleChange(e)}
                      />
                      <label className="form-check-label mx-2" htmlFor="statusFemale">
                        Female
                      </label>
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      <b className="ms-2"></b>
                    </Label>
                    <div className="col-sm-7 d-flex">
                      <div className={`${Styles.submit_button}`}>
                        <Button type="submit">
                          {editData?._id ? "Update" : "Save"}
                        </Button>
                      </div>
                      <div className={`${Styles.cancel_button} me-4 mx-2`}>
                        <Link to="/reviews">
                          {" "}
                          <Button className={`${Styles.cancel_button}`}>
                            Cancel
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AddStudentStory;
