import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Styles from "../homework/assets/Form.module.css";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInterceptor";
import { ImageLink } from "../../constants";
import CommonEditor from "./CommonEditor";

const AddSubcategory = () => {
  const location = useLocation();
  const editData = location.state;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const navigator = useNavigate();

  const [data, setData] = useState({
    name: editData?.name || "",
    description: editData?.description || "",
    ShortDescription: editData?.ShortDescription || "",
    LongDescription: editData?.LongDescription || "",
    categoryId: editData?.categoryId._id || "",
    path: editData?.path || "",
    image: editData?.image || null,
    isActive: editData?.isActive || false,
  });

  const handleChange = (e) => {
    const { name, value, checked, files } = e.target;
    if (name !== "isActive") {
      setData({ ...data, [name]: name === "image" ? files[0] : value });
    } else {
      setData({ ...data, [name]: checked });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (
      !data.categoryId ||
      !data.description ||
      !data.ShortDescription ||
      !data.LongDescription ||
      !data.name ||
      !data.image
    ) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append("categoryId", data.categoryId);
      formData.append("name", data.name);
      formData.append("image", data.image);
      formData.append("description", data.description);
      formData.append("ShortDescription", data.ShortDescription);
      formData.append("LongDescription", data.LongDescription);
      formData.append("path", data.path);
      formData.append("isActive", data.isActive);
      const response = await axiosInstance.post(
        `admin/subcategory/createSubcategory`,
        formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/sub-category");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong. Please try again!!");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("categoryId", data.categoryId);
      formData.append("name", data.name);
      formData.append("description", data.description);
      formData.append("ShortDescription", data.ShortDescription);
      formData.append("LongDescription", data.LongDescription);
      formData.append("path", data.path);
      formData.append("isActive", data.isActive);
      if (data.image instanceof File) {
        formData.append("image", data.image);
      }

      const response = await axiosInstance.put(
        `admin/subcategory/updateSubcategory/${editData._id}`,
        formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/sub-category");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong. Please try again!!");
    }
  };

  const fetchData = async (page = 1) => {
    try {
      const response = await axiosInstance.get(`admin/category/getAllCategory`);
      const { data } = response.data;
      setCategoryData(data);
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong. Please try again!!");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="mt-4 mb-4">
        <div className="row">
          <div className="col-sm-6">
            <h4 className="page-title">Add Sub Category</h4>
          </div>
        </div>
        <Card className="mt-4">
          <CardBody>
            <Row>
              <Col md="12">
                <form
                  className="ps-5 pe-5 pt-3"
                  onSubmit={editData?._id ? handleUpdate : handleSubmit}
                >
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group mb-2">
                        <Label for="inputPassword" className="col-form-label">
                          Category
                        </Label>
                        <FormGroup>
                          <Input
                            id="exampleSelect"
                            name="categoryId"
                            type="select"
                            invalid={
                              !editData && !data.categoryId && formSubmitted
                            }
                            defaultValue={
                              editData
                                ? editData?.categoryId._id === data.categoryId
                                  ? data.categoryId
                                  : ""
                                : ""
                            }
                            onChange={(e) => handleChange(e)}
                          >
                            <option value="">
                              {editData
                                ? editData?.categoryId._id === data.categoryId
                                  ? categoryData.find(
                                      (subcat) => subcat._id === data.categoryId
                                    )?.name
                                  : "--- Select category ---"
                                : "--- Select category ---"}
                            </option>
                            {categoryData?.map((subcat) => (
                              <option key={subcat._id} value={subcat._id}>
                                {subcat.name}
                              </option>
                            ))}
                          </Input>
                          <FormFeedback>Category is required</FormFeedback>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group mb-2">
                        <Label for="inputPassword" className="col-form-label">
                          Name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="inputPassword"
                          placeholder="Enter your sub category name"
                          name="name"
                          invalid={!editData && !data.name && formSubmitted}
                          defaultValue={data.name}
                          onChange={(e) => handleChange(e)}
                        />
                        <FormFeedback>
                          Sub category name is required
                        </FormFeedback>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group mb-4">
                        <Label for="inputPassword" className="col-form-label">
                          Path
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="inputPassword"
                          name="path"
                          placeholder="Enter your sub category path"
                          invalid={!editData && !data.path && formSubmitted}
                          defaultValue={data.path}
                          onChange={(e) => handleChange(e)}
                        />
                        <FormFeedback>
                          Sub category path is required
                        </FormFeedback>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <Label for="inputPassword" className="col-form-label">
                        Banner Description
                      </Label>
                      <Input
                        type="textarea"
                        className="form-control"
                        id="inputPassword"
                        placeholder="Enter your sub category description"
                        name="description"
                        invalid={
                          !editData && !data.description && formSubmitted
                        }
                        value={data.description}
                        onChange={(e) => handleChange(e)}
                        rows={5}
                      />
                      <FormFeedback>
                        Sub category description is required
                      </FormFeedback>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group mb-4">
                        <Label for="inputPassword" className="col-form-label">
                          Short Description
                        </Label>
                        <CommonEditor
                          value={data.ShortDescription}
                          placeholder="Enter content ..."
                          onChange={(value) =>
                            handleChange({
                              target: { name: "ShortDescription", value },
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group mb-4">
                        <Label for="inputPassword" className="col-form-label">
                          Long Description
                        </Label>
                        <CommonEditor
                          value={data.LongDescription}
                          placeholder="Enter content ..."
                          onChange={(value) =>
                            handleChange({
                              target: { name: "LongDescription", value },
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <Label for="inputPassword" className="col-form-label">
                        Image
                      </Label>
                      <Input
                        type="file"
                        className="form-control"
                        id="inputPassword"
                        name="image"
                        invalid={!editData && !data.image && formSubmitted}
                        onChange={(e) => handleChange(e)}
                      />
                      <FormFeedback>Banner image is required</FormFeedback>
                      <div className="mt-4">
                        {data.image instanceof File ? (
                          <img
                            src={URL.createObjectURL(data.image)}
                            alt="Preview"
                            className="w-50"
                          />
                        ) : (
                          <img
                            src={ImageLink(data.image)}
                            className="w-50"
                            alt="data_img"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row mb-4 mt-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-2 col-form-label"
                    >
                      IsActive
                    </Label>
                    <div className="col-sm-10">
                      <FormGroup switch>
                        <Input
                          type="switch"
                          className={`${Styles.form_check_input}`}
                          name="isActive"
                          checked={data.isActive}
                          onChange={(e) => handleChange(e)}
                        />
                        <Label check className="mx-1">
                          {data.isActive === true ? "Active" : "Deactive"}
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 d-flex justify-content-start align-items-center">
                      <div className={`${Styles.submit_button} me-3`}>
                        <Button type="submit">
                          {editData?._id ? "Update" : "Save"}
                        </Button>
                      </div>
                      <div className={`${Styles.cancel_button}`}>
                        <Link to="/sub-category">
                          <Button className={`${Styles.cancel_button}`}>
                            Cancel
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AddSubcategory;
