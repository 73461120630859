import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Styles from './homework/assets/Form.module.css'

const CancelModal = ({ handleClose, showModal, toggleModal, handleResume }) => {
    return (
        <div>
            <Modal isOpen={showModal} toggle={toggleModal} style={{ top: "33%" }}>
                <ModalHeader toggle={toggleModal}>Cancel Order</ModalHeader>
                <ModalBody>
                    Are you sure you want to cancel this order?
                </ModalBody>
                <ModalFooter>
                    <div className={`${Styles.button_color}`}>
                        <Button
                            type="cancel"
                            onClick={handleResume}
                        >
                            No
                        </Button>
                    </div>
                    <div className={`${Styles.cancel_button} me-4`}>
                        <Button
                            type="cancel"
                            className={`${Styles.cancel_button}`}
                            onClick={handleClose}
                        >
                            Yes
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default CancelModal