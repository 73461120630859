import React, { useState } from "react";
import Styles from "../homework/assets/Form.module.css";
import { Button, Input, Label } from "reactstrap";
import axiosInstance from "../../axiosInterceptor";
import { Rating } from "react-simple-star-rating";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const ForStudent = () => {
  const location = useLocation();
  const questionId = location.state._id;
  const [whatsappNo, setWhatsappNo] = useState(location.state.whatsappNo || "");
  const [zoomLink, setZoomLink] = useState(location.state.zoomLink || "");
  const [isLoading, setIsLoading] = useState(false);
  const adminId = localStorage.getItem("_id");
  const [ratingValue, setRatingValue] = useState(location?.state?.rating || 0);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      await axiosInstance.put(
        `admin/liveSessionHelp/updateAnswer/${questionId}`,
        {
          whatsappNo,
          zoomLink,
          adminId: adminId,
        }
      );
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="mt-5">
        <div className="d-flex justify-content-between mt-5">
          <div className="ps-5 pt-2">
            <span className={` ${Styles.student_corner_text}`}>
              For Student
            </span>
          </div>
        </div>
        <form className="pt-3" onSubmit={handleSubmit}>
          <div className="ps-5 pe-5">
            <div className="form-group row mb-4">
              <Label for="whatsappNo" className="col-sm-3 col-form-label">
                Whatsapp No <b className="ms-2">:</b>
              </Label>
              <div className="col-sm-6">
                <Input
                  type="number"
                  className="form-control"
                  id="whatsappNo"
                  defaultValue={whatsappNo}
                  onChange={(e) => setWhatsappNo(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group row mb-4">
              <Label for="zoomLink" className="col-sm-3 col-form-label">
                Meet Link <b className="ms-2">:</b>
              </Label>
              <div className="col-sm-6">
                <Input
                  type="text"
                  className="form-control"
                  id="zoomLink"
                  defaultValue={zoomLink}
                  onChange={(e) => setZoomLink(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group row mb-4">
              <Label for="inputPassword" className="col-sm-3 col-form-label">
                Student Rating<b className="ms-2">:</b>
              </Label>
              <div className="col-sm-6">
                <Rating
                  value={ratingValue}
                  initialValue={ratingValue}
                  readonly
                />
              </div>
            </div>
          </div>
          <div
            className={`d-flex justify-content-around ${Styles.quesion_color} p-3`}
          >
            <div className="d-flex">
              <div className={`${Styles.cancel_button} me-4`}>
                <Button className={`${Styles.cancel_button}`}>Cancel</Button>
              </div>
              <div className={`${Styles.submit_button}`}>
                <Button type="submit">
                  {isLoading ? "Submitting..." : "Submit"}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForStudent;
