import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Col, Input, Label, Row } from 'reactstrap';
import axiosInstance from "../axiosInterceptor";
import Styles from '../pages/homework/assets/Form.module.css';
import { toast } from 'react-toastify';


const Currency = () => {
    const [usdToInr, setUsdToInr] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchCurrencyData();
    }, []);

    const fetchCurrencyData = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get(`admin/currency/getCurrency`);
            const currencyData = response.data.data;
            setUsdToInr(currencyData.usdtoinr);
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        } finally {
            setLoading(false);
        }
    };

    const handleUpdate = async () => {
        try {
            setLoading(true);

            const response = await axiosInstance.put(`admin/currency/updateCurrency`, {
                usdtoinr: usdToInr
            });
            if (response.data.isSuccess) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Error updating currency: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div className="mt-4 mb-4">
                <div className='row'>
                    <div className='col-sm-6'>
                        <h4 className="page-title">Currency Conversion Rate</h4>
                    </div>
                </div>
                <Card className='mt-4'>
                    <CardBody>
                        {loading ? (
                            <div className="text-center mt-4">
                                <p>Loading...</p>
                            </div>
                        ) : (
                            <Row>
                                <Col md="8">
                                    <form className="ps-5 pe-5 pt-3">
                                        <div className="form-group row mb-4">
                                            <Label for="inputPassword" className="col-sm-2 col-form-label">
                                                1 USD =
                                            </Label>
                                            <div className="col-sm-6  d-flex align-items-center">
                                                <Input
                                                    type="number"
                                                    className="form-control"
                                                    id="inputPassword"
                                                    value={usdToInr}
                                                    onChange={(e) => setUsdToInr(e.target.value)}
                                                />
                                                <span className={`${Styles.plandoll} me-1`}>INR</span>
                                            </div>
                                            <div className='col-sm-3'>
                                                <div className={`${Styles.submit_button}`}>
                                                    <Button onClick={handleUpdate}>Save</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </Col>
                            </Row>
                        )}
                    </CardBody>
                </Card>
            </div>
        </div>
    );
};

export default Currency;
