import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Styles from "../homework/assets/Form.module.css";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInterceptor";
import { ImageLink } from "../../constants";

const AddCategory = () => {
  const location = useLocation();
  const categoryData = location.state;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigator = useNavigate();

  const [data, setData] = useState({
    name: categoryData?.name || "",
    description: categoryData?.description || "",
    image: categoryData?.image || "",
    isActive: categoryData?.isActive || false,
  });

  const handleChange = (e) => {
    const { name, files, checked } = e.target;
    if (name === 'image') {
      setData({ ...data, [name]: files.length > 0 ? files[0] : null, });
    } else if (name === "isActive") {
      setData({ ...data, [name]: checked });
    } else {
      setData({
        ...data, [name]: e.target.value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!data.name) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append('name', data.name);
      formData.append('description', data.description);
      formData.append('image', data.image);
      formData.append('isActive', data.isActive);
      const response = await axiosInstance.post(
        `admin/category/createCategory`, formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/category");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('name', data.name);
      formData.append('description', data.description);
      formData.append('image', data.image);
      formData.append('isActive', data.isActive);
      const response = await axiosInstance.put(
        `admin/category/updateCategory/${categoryData?._id}`, formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/category");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  return (
    <div>
      <div className="mt-4 mb-4">
        <div className="row">
          <div className="col-sm-6">
            <h4 className="page-title">Add Category</h4>
          </div>
        </div>
        <Card className="mt-4">
          <CardBody>
            <Row>
              <Col md="8">
                <form
                  className="ps-5 pe-5 pt-3"
                  onSubmit={categoryData?._id ? handleUpdate : handleSubmit}
                >
                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      Category Name
                    </Label>
                    <div className="col-sm-7">
                      <Input
                        type="text"
                        className="form-control"
                        id="inputPassword"
                        name="name"
                        placeholder="Enter your category name "
                        invalid={!categoryData && !data.name && formSubmitted}
                        value={data.name}
                        onChange={(e) => handleChange(e)}
                      />
                      <FormFeedback>Category name is required</FormFeedback>
                    </div>
                  </div>
                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      Description
                    </Label>
                    <div className="col-sm-7">
                      <Input
                        type="text"
                        className="form-control"
                        id="inputPassword"
                        name="description"
                        placeholder="Enter your description"
                        invalid={!categoryData && !data.description && formSubmitted}
                        value={data.description}
                        onChange={(e) => handleChange(e)}
                      />
                      <FormFeedback>Description is required</FormFeedback>
                    </div>
                  </div>
                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      image
                    </Label>
                    <div className="col-sm-7">
                      <Input
                        type="file"
                        className="form-control"
                        id="inputPassword"
                        name="image"
                        placeholder="Enter your image"
                        invalid={!categoryData && !data.image && formSubmitted}
                        // value={data.image}
                        onChange={(e) => handleChange(e)}
                      />
                      <FormFeedback>image is required</FormFeedback>
                    </div>
                  </div>
                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      IsActive
                    </Label>
                    <div className="col-sm-7">
                      <FormGroup switch>
                        <Input
                          type="switch"
                          className={`${Styles.form_check_input}`}
                          name="isActive"
                          checked={data.isActive}
                          onChange={(e) => handleChange(e)}
                        />
                        <Label check className="mx-1">
                          {data.isActive === true ? "Active" : "Deactive"}
                        </Label>
                      </FormGroup>
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      <b className="ms-2"></b>
                    </Label>
                    <div className="col-sm-7 d-flex">
                      <div className={`${Styles.submit_button}`}>
                        <Button type="submit">
                          {categoryData?._id ? "Update" : "Save"}
                        </Button>
                      </div>
                      <div className={`${Styles.cancel_button} me-4 mx-2`}>
                        <Link to="/category">
                          {" "}
                          <Button className={`${Styles.cancel_button}`}>
                            Cancel
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </Col>
              {data.image &&
                <Col md="4">
                  <div className='border w-100 h-75 text-center'>
                    <p className='mt-3'>Image Preview</p>
                    <br />
                    {data.image instanceof File ? (
                      <img
                        src={URL.createObjectURL(data.image)}
                        alt="Preview"
                        className="w-25"
                      />
                    ) : (
                      <img src={ImageLink(data.image)} className="w-25" alt='data_img' />
                    )
                    }

                  </div>
                </Col>
              }
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AddCategory;
