import React, { Children } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import Styles from "./assets/App.module.css";

import Sidebar from "./Layout/Sidebar";
import Navbar from "./Layout/Navbar";

import Dashboard from "./pages/Dashboard";
import Wallet from "./pages/Wallet/Wallet";

import HomeworkWallet from "./pages/homework/HomeworkWallet";
import HomeForm from "./pages/homework/Form";
import Homeworklist from "./pages/homework/Homeworklist";

import Assignmentwallte from "./pages/assignmenthelp/Assignmentwallte";
import Assignmentlist from "./pages/assignmenthelp/Assignmentlist";
import AssignmentForm from "./pages/assignmenthelp/Form";

import Livesessionwallte from "./pages/livesessionhelp/Livesessionwallte";
import Livesessionlist from "./pages/livesessionhelp/Livesessionlist";
import Chatform from "./pages/livesessionhelp/Chatform";

import Librarysubscriptionplan from "./pages/library/Librarysubscriptionplan ";
import Searchengine from "./pages/library/Searchengine";
import Libraryquestionlist from "./pages/library/Libraryquestionlist";

import Tutor_list from "./pages/tutor/Tutor_list";
import Tutor_exam from "./pages/tutor/Tutor_exam";
import Tutor_profile from "./pages/tutor/Tutor_profile";
import Tutor_payment from "./pages/tutor/Tutor_payment";

import Login from "./Layout/Login";
import Forgotpassword from "./Layout/Forgotpassword";
import Currency from "./pages/Currency";
import Promocode from "./pages/Promocode";
import Confirmpassword from "./Layout/Resetpassword";
import Resetpassword from "./Layout/Resetpassword";
import Changepassword from "./Layout/Changepassword";
import PromocodeData from "./pages/PromocodeData";
import Category from "./pages/masters/Category";
import AddCategory from "./pages/masters/AddCategory";
import Subcategory from "./pages/masters/Subcategory";
import AddSubcategory from "./pages/masters/AddSubcategory";
import Subject from "./pages/masters/Subject";
import AddSubject from "./pages/masters/AddSubject";
import FontSize from "./pages/masters/FontSize";
import AddFontSize from "./pages/masters/AddFontSize";
import FontStyle from "./pages/masters/FontStyle";
import AddFontStyle from "./pages/masters/AddFontStyle";
import RefrenceStyle from "./pages/masters/RefrenceStyle";
import AddReferenceStyle from "./pages/masters/AddReferenceStyle";
import LineSpacing from "./pages/masters/LineSpacing";
import AddLineSpacing from "./pages/masters/AddLineSpacing";
import BonusOffer from "./pages/masters/BonusOffer";
import AddBonusOffer from "./pages/masters/AddBonusOffer";
import Banners from "./pages/masters/Banners";
import AddBanners from "./pages/masters/AddBanners";
import Bonus from "./pages/masters/Bonus";
import AddBonus from "./pages/masters/AddBonus";
import BecomeExpert from "./pages/masters/BecomeExpert";
import AddBecomeExpert from "./pages/masters/AddBecomeExpert";
import Benefits from "./pages/masters/Benefits";
import AddBenefit from "./pages/masters/AddBenefit";
import CompletehelpStep from "./pages/masters/CompletehelpStep";
import AddHelpStep from "./pages/masters/AddHelpStep";
import AcademicHelp from "./pages/masters/AcademicHelp";
import AddAcademicHelp from "./pages/masters/AddAcademicHelp";
import SocialMedia from "./pages/masters/SocialMedia";
import AddSocialMedia from "./pages/masters/AddSocialMedia";
import StudentStory from "./pages/masters/StudentStory";
import AddStudentStory from "./pages/masters/AddStudentStory";
import PostingStreack from "./pages/masters/PostingStreack";
import AddPostingStreak from "./pages/masters/AddPostingStreak";
import QuestionPrice from "./pages/masters/QuestionPrice";
import AddQuestionPrice from "./pages/masters/AddQuestionPrice";
import AssignmentPrice from "./pages/masters/AssignmentPrice";
import AddAssignmentPrice from "./pages/masters/AddAssignmentPrice";

import LiveSessionPrice from "./pages/masters/LiveSessionPrice";
import AddLiveSessionPrice from "./pages/masters/AddLiveSessionPrice";
import ContactUs from "./pages/masters/ContactUs";
import Setting from "./pages/masters/Setting";
import Profile from "./Layout/Profile";
import WhatsAppQuery from "./pages/masters/WhatsAppQuery";
import AddWhatsAppQuery from "./pages/masters/AddWhatsAppQuery";
import EmailSetting from "./pages/masters/EmailSetting";
import Rewards from "./pages/Component/Rewards";
import Studentlist from "./pages/Component/Studentlist";
import Studentdetalis from "./pages/Component/Studentdetails";
import Cms from "./pages/masters/Cms";
import AddCms from "./pages/masters/AddCms";
import AddContactus from "./pages/masters/AddContactus";
import Sitemap from "./pages/masters/Sitemap";
import Page from "./pages/masters/Page";
import AddPage from "./pages/masters/AddPage";
import PageAction from "./pages/masters/PageAction";
import AddPageAction from "./pages/masters/AddPageAction";
import Userlist from "./pages/userPermission/Userlist";
import Userrights from "./pages/userPermission/Userrights";
import AddUsers from "./pages/userPermission/AddUsers";
import Welcome from "./pages/Welcome";
import Referrallist from "./pages/Component/Referrallist";
import Referralpayment from "./pages/Component/Referralpayment";
import Referraldetails from "./pages/Component/Referraldetails";
import Testimonial from "./pages/masters/Testimonial";
import AddTestimonial from "./pages/masters/AddTestimonial";
import AssignmentLibraylist from "./pages/library/AssignmentLibraylist";
import AddAssignmentLibray from "./pages/library/AddAssignmentLibray";
import Submitquery from "./pages/library/Submitquery";
import Viewsubmitquery from "./pages/library/Viewsubmitquery";

export default function App() {
  const RequireAuth = ({ children }) => {
    if (!localStorage.getItem("authToken")) {
      return <Navigate to="/" />;
    }

    return children;
  };

  const isAuthenticated = !!localStorage.getItem("authToken");

  return (
    <div className={Styles.app}>
      {localStorage.getItem("authToken") ? <Sidebar /> : <></>}
      <div
        className={`${Styles.content} ${isAuthenticated ? Styles.loggedIn : ""
          }`}
      >
        {localStorage.getItem("authToken") ? <Navbar /> : <></>}
        <div className="px-3">
          <Routes>
            <Route
              path="/"
              element={
                isAuthenticated ? <Navigate to="/welcome" /> : <Login />
              }
            />
            {/* <Route path="/login" element={<Login />} /> */}
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />
            <Route
              path="/welcome"
              element={
                <RequireAuth>
                  <Welcome />
                </RequireAuth>
              }
            />
            <Route
              path="orders"
              element={
                <RequireAuth>
                  <Wallet />
                </RequireAuth>
              }
            />

            <Route
              path="assignment-wallet"
              element={
                <RequireAuth>
                  <Assignmentwallte />
                </RequireAuth>
              }
            />
            <Route
              path="assignment-list"
              element={
                <RequireAuth>
                  <Assignmentlist />
                </RequireAuth>
              }
            />
            <Route
              path="assignment-form"
              element={
                <RequireAuth>
                  <AssignmentForm />
                </RequireAuth>
              }
            />

            <Route
              path="homework-wallet"
              element={
                <RequireAuth>
                  <HomeworkWallet />
                </RequireAuth>
              }
            />
            <Route
              path="homework-list"
              element={
                <RequireAuth>
                  <Homeworklist />
                </RequireAuth>
              }
            />
            <Route
              path="homework-form"
              element={
                <RequireAuth>
                  <HomeForm />
                </RequireAuth>
              }
            />

            <Route
              path="live-session-wallet"
              element={
                <RequireAuth>
                  <Livesessionwallte />
                </RequireAuth>
              }
            />
            <Route
              path="live-session-list"
              element={
                <RequireAuth>
                  <Livesessionlist />
                </RequireAuth>
              }
            />
            <Route
              path="live-session-chat"
              element={
                <RequireAuth>
                  <Chatform />
                </RequireAuth>
              }
            />

            <Route
              path="subscription-plan"
              element={
                <RequireAuth>
                  <Librarysubscriptionplan />
                </RequireAuth>
              }
            />
            <Route
              path="assignment-library"
              element={
                <RequireAuth>
                  <AssignmentLibraylist />
                </RequireAuth>
              }
            />
            <Route
              path="add-assignment-library"
              element={
                <RequireAuth>
                  <AddAssignmentLibray />
                </RequireAuth>
              }
            />
            <Route
              path="submit-query"
              element={
                <RequireAuth>
                  <Submitquery />
                </RequireAuth>
              }
            />
            <Route
              path="view-submit-query"
              element={
                <RequireAuth>
                  <Viewsubmitquery />
                </RequireAuth>
              }
            />
            <Route
              path="library-question-list"
              element={
                <RequireAuth>
                  <Libraryquestionlist />
                </RequireAuth>
              }
            />
            <Route
              path="q-a-search-engine"
              element={
                <RequireAuth>
                  <Searchengine />
                </RequireAuth>
              }
            />
            <Route
              path="q-a-search-engine/:id"
              element={
                <RequireAuth>
                  <Searchengine />
                </RequireAuth>
              }
            />
            <Route
              path="currency"
              element={
                <RequireAuth>
                  <Currency />
                </RequireAuth>
              }
            />
            <Route
              path="users-rights"
              element={
                <RequireAuth>
                  <Userlist />
                </RequireAuth>
              }
            />
            <Route
              path="user-rights/:userId"
              element={
                <RequireAuth>
                  <Userrights />
                </RequireAuth>
              }
            />
            <Route
              path="add-users"
              element={
                <RequireAuth>
                  <AddUsers />
                </RequireAuth>
              }
            />
            <Route
              path="tutor-list"
              element={
                <RequireAuth>
                  <Tutor_list />
                </RequireAuth>
              }
            />
            <Route
              path="tutor-exam"
              element={
                <RequireAuth>
                  <Tutor_exam />
                </RequireAuth>
              }
            />
            <Route
              path="tutor-details"
              element={
                <RequireAuth>
                  <Tutor_profile />
                </RequireAuth>
              }
            />
            <Route
              path="tutor-payment"
              element={
                <RequireAuth>
                  <Tutor_payment />
                </RequireAuth>
              }
            />

            <Route
              path="promo-code"
              element={
                <RequireAuth>
                  <PromocodeData />
                </RequireAuth>
              }
            />
            <Route
              path="addpromocode"
              element={
                <RequireAuth>
                  <Promocode />
                </RequireAuth>
              }
            />
            <Route
              path="category"
              element={
                <RequireAuth>
                  <Category />
                </RequireAuth>
              }
            />
            <Route
              path="addcategory"
              element={
                <RequireAuth>
                  <AddCategory />
                </RequireAuth>
              }
            />
            <Route
              path="sub-category"
              element={
                <RequireAuth>
                  <Subcategory />
                </RequireAuth>
              }
            />
            <Route
              path="addsubcategory"
              element={
                <RequireAuth>
                  <AddSubcategory />
                </RequireAuth>
              }
            />
            <Route
              path="subject"
              element={
                <RequireAuth>
                  <Subject />
                </RequireAuth>
              }
            />
            <Route
              path="page"
              element={
                <RequireAuth>
                  <Page />
                </RequireAuth>
              }
            />
            <Route
              path="page-action"
              element={
                <RequireAuth>
                  <PageAction />
                </RequireAuth>
              }
            />
            <Route
              path="addsubject"
              element={
                <RequireAuth>
                  <AddSubject />
                </RequireAuth>
              }
            />
            <Route
              path="add-page"
              element={
                <RequireAuth>
                  <AddPage />
                </RequireAuth>
              }
            />
            <Route
              path="add-page-action"
              element={
                <RequireAuth>
                  <AddPageAction />
                </RequireAuth>
              }
            />
            <Route
              path="font-size"
              element={
                <RequireAuth>
                  <FontSize />
                </RequireAuth>
              }
            />
            <Route
              path="addfontsize"
              element={
                <RequireAuth>
                  <AddFontSize />
                </RequireAuth>
              }
            />
            <Route
              path="font-style"
              element={
                <RequireAuth>
                  <FontStyle />
                </RequireAuth>
              }
            />
            <Route
              path="addfontstyle"
              element={
                <RequireAuth>
                  <AddFontStyle />
                </RequireAuth>
              }
            />
            <Route
              path="reference-style"
              element={
                <RequireAuth>
                  <RefrenceStyle />
                </RequireAuth>
              }
            />
            <Route
              path="addrefstyle"
              element={
                <RequireAuth>
                  <AddReferenceStyle />
                </RequireAuth>
              }
            />
            <Route
              path="line-spacing"
              element={
                <RequireAuth>
                  <LineSpacing />
                </RequireAuth>
              }
            />
            <Route
              path="addlinespacing"
              element={
                <RequireAuth>
                  <AddLineSpacing />
                </RequireAuth>
              }
            />
            <Route
              path="bonus-offer"
              element={
                <RequireAuth>
                  <BonusOffer />
                </RequireAuth>
              }
            />
            <Route
              path="addbonusoffer"
              element={
                <RequireAuth>
                  <AddBonusOffer />
                </RequireAuth>
              }
            />
            <Route
              path="banner"
              element={
                <RequireAuth>
                  <Banners />
                </RequireAuth>
              }
            />
            <Route
              path="addbanner"
              element={
                <RequireAuth>
                  <AddBanners />
                </RequireAuth>
              }
            />
            <Route
              path="bonus"
              element={
                <RequireAuth>
                  <Bonus />
                </RequireAuth>
              }
            />
            <Route
              path="addbonus"
              element={
                <RequireAuth>
                  <AddBonus />
                </RequireAuth>
              }
            />
            <Route
              path="become-an-expert"
              element={
                <RequireAuth>
                  <BecomeExpert />
                </RequireAuth>
              }
            />
            <Route
              path="addbecomexpert"
              element={
                <RequireAuth>
                  <AddBecomeExpert />
                </RequireAuth>
              }
            />
            <Route
              path="benefits"
              element={
                <RequireAuth>
                  <Benefits />
                </RequireAuth>
              }
            />
            <Route
              path="addbenefits"
              element={
                <RequireAuth>
                  <AddBenefit />
                </RequireAuth>
              }
            />
            <Route
              path="complete-help-step"
              element={
                <RequireAuth>
                  <CompletehelpStep />
                </RequireAuth>
              }
            />
            <Route
              path="addhelpstep"
              element={
                <RequireAuth>
                  <AddHelpStep />
                </RequireAuth>
              }
            />
            <Route
              path="academic-help"
              element={
                <RequireAuth>
                  <AcademicHelp />
                </RequireAuth>
              }
            />
            <Route
              path="addacademichelp"
              element={
                <RequireAuth>
                  <AddAcademicHelp />
                </RequireAuth>
              }
            />
            <Route
              path="social-media"
              element={
                <RequireAuth>
                  <SocialMedia />
                </RequireAuth>
              }
            />
            <Route
              path="addsocialmedia"
              element={
                <RequireAuth>
                  <AddSocialMedia />
                </RequireAuth>
              }
            />
            <Route
              path="reviews"
              element={
                <RequireAuth>
                  <StudentStory />
                </RequireAuth>
              }
            />
            <Route
              path="addreviews"
              element={
                <RequireAuth>
                  <AddStudentStory />
                </RequireAuth>
              }
            />
            <Route
              path="testimonials"
              element={
                <RequireAuth>
                  <Testimonial />
                </RequireAuth>
              }
            />
            <Route
              path="addtestimonials"
              element={
                <RequireAuth>
                  <AddTestimonial />
                </RequireAuth>
              }
            />
            <Route
              path="posting-streak"
              element={
                <RequireAuth>
                  <PostingStreack />
                </RequireAuth>
              }
            />
            <Route
              path="addpostingstreak"
              element={
                <RequireAuth>
                  <AddPostingStreak />
                </RequireAuth>
              }
            />

            <Route
              path="whatsapp-query"
              element={
                <RequireAuth>
                  <WhatsAppQuery />
                </RequireAuth>
              }
            />
            <Route
              path="addwhatsapp-query"
              element={
                <RequireAuth>
                  <AddWhatsAppQuery />
                </RequireAuth>
              }
            />

            <Route
              path="question-price"
              element={
                <RequireAuth>
                  <QuestionPrice />
                </RequireAuth>
              }
            />
            <Route
              path="addquestionprice"
              element={
                <RequireAuth>
                  <AddQuestionPrice />
                </RequireAuth>
              }
            />
            <Route
              path="assignment-price"
              element={
                <RequireAuth>
                  <AssignmentPrice />
                </RequireAuth>
              }
            />
            <Route
              path="addassignmentprice"
              element={
                <RequireAuth>
                  <AddAssignmentPrice />
                </RequireAuth>
              }
            />
            <Route
              path="contact-us"
              element={
                <RequireAuth>
                  <ContactUs />
                </RequireAuth>
              }
            />
            <Route
              path="addcontact-us/:contactid"
              element={
                <RequireAuth>
                  <AddContactus />
                </RequireAuth>
              }
            />
            <Route
              path="settings"
              element={
                <RequireAuth>
                  <Setting />
                </RequireAuth>
              }
            />
            <Route
              path="sitemap"
              element={
                <RequireAuth>
                  <Sitemap />
                </RequireAuth>
              }
            />

            <Route
              path="live-session-price"
              element={
                <RequireAuth>
                  <LiveSessionPrice />
                </RequireAuth>
              }
            />
            <Route
              path="add-live-session-price"
              element={
                <RequireAuth>
                  <AddLiveSessionPrice />
                </RequireAuth>
              }
            />

            <Route
              path="cms"
              element={
                <RequireAuth>
                  <Cms />
                </RequireAuth>
              }
            />

            <Route
              path="add-cms"
              element={
                <RequireAuth>
                  <AddCms />
                </RequireAuth>
              }
            />

            <Route
              path="rewards"
              element={
                <RequireAuth>
                  <Rewards />
                </RequireAuth>
              }
            />

            <Route
              path="student-list"
              element={
                <RequireAuth>
                  <Studentlist />
                </RequireAuth>
              }
            />
            <Route
              path="referral-list"
              element={
                <RequireAuth>
                  <Referrallist />
                </RequireAuth>
              }
            />
            <Route
              path="referral-payment"
              element={
                <RequireAuth>
                  <Referralpayment />
                </RequireAuth>
              }
            />

            <Route
              path="student-details/:studentId"
              element={
                <RequireAuth>
                  <Studentdetalis />
                </RequireAuth>
              }
            />

            <Route
              path="referral-details"
              element={
                <RequireAuth>
                  <Referraldetails />
                </RequireAuth>
              }
            />

            <Route path="forgot-password" element={<Forgotpassword />}></Route>
            <Route path="reset-password" element={<Resetpassword />}></Route>
            <Route path="email-settings" element={<EmailSetting />}></Route>
            <Route path="profile" element={<Profile />}></Route>
            <Route path="change-password" element={<Changepassword />}></Route>
          </Routes>
          <ToastContainer autoClose={1000} />
        </div>
      </div>
    </div>
  );
}
