import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.scss"
import { Button, Form, FormGroup, Label, Input, Container, Row, Col, FormFeedback } from 'reactstrap';
import { toast } from "react-toastify";
import axiosInstance from "../axiosInterceptor";

const Changepassword = () => {
    const navigator = useNavigate();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [submitAttempted, setSubmitAttempted] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setSubmitAttempted(true);

        if (!oldPassword || !newPassword) {
            return;
        }
        try {
            const response = await axiosInstance.post(
                `admin/changePassword`,
                {
                    oldPassword: oldPassword,
                    newPassword: newPassword
                }
            );
            if (response.status === 200) {
                toast.success(response.data.message)
                navigator("/")
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    }

    return (
        <div className="login-register-bg mt-4">
            <Container>
                <Row className="d-lg-flex align-items-center justify-content-center d-block h-100">
                    <Col lg="7" className="mx-auto mt-1">
                       
                        <div className="rbt-contact-form contact-form-style-1 rbt-shadow-box mb--50 mt-2">
                            <Row className="align-items-center">
                                <Col lg="12" className="pl--30 pl_sm--0">
                                    <div>
                                        <h4 className="title text-center mb--10"><b>Change Password</b></h4>
                                        <Form className="rbt-profile-row rbt-default-form row row--15"
                                            onSubmit={handleSubmit}>
                                            <Col lg="12" md="12" xs="12">
                                                <FormGroup>
                                                    <Label>Old Password</Label>
                                                    <Input invalid={!oldPassword && submitAttempted} type="password" placeholder="Enter your old password" value={oldPassword} name="oldPassword" onChange={(e) => setOldPassword(e.target.value)} />
                                                    <FormFeedback>Old password is required.</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="12" md="12" xs="12">
                                                <FormGroup>
                                                    <Label>New Password</Label>
                                                    <Input invalid={!newPassword && submitAttempted} type="password" placeholder="Enter your new password" value={newPassword} name="newPassword" onChange={(e) => setNewPassword(e.target.value)} />
                                                    <FormFeedback>New password is required.</FormFeedback>
                                                </FormGroup>
                                            </Col>

                                            <Col lg="12" md="12" xs="12" className="text-end">
                                                <div className="text-center">
                                                    <Button type="submit" className="px-4 login-button">Save</Button>
                                                </div>
                                            </Col>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Changepassword