import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Styles from "../homework/assets/Form.module.css";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInterceptor";
// import
import CommonEditor from "./CommonEditor";

const AddCms = () => {
  const location = useLocation();
  const editData = location.state;
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [data, setData] = useState({
    title: editData?.title || "",
    slug: editData?.slug || "",
    description: editData?.description || "",
    metaTitle: editData?.metaTitle || "",
    metaKeyword: editData?.metaKeyword || "",
    metaDescription: editData?.metaDescription || "",
    isActive: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setData({ ...data, [name]: checked });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const navigator = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!data.description.trim()) {
      return;
    }

    try {
      const response = await axiosInstance.post(`admin/cms/createCMS`, {
        title: data.title,
        slug: data.slug,
        description: data.description,
        metaTitle: data.metaTitle,
        metaKeyword: data.metaKeyword,
        metaDescription: data.metaDescription,
        isActive: data.isActive,
      });

      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/cms");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.put(
        `admin/cms/updateCMS/${editData?._id}`,
        {
          title: data.title,
          slug: data.slug,
          description: data.description,
          metaTitle: data.metaTitle,
          metaKeyword: data.metaKeyword,
          metaDescription: data.metaDescription,
          isActive: data.isActive,
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/cms");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  return (
    <div>
      <div className="mt-4 mb-4">
        <div className="row">
          <div className="col-sm-6">
            <h4 className="page-title">Add CMS</h4>
          </div>
        </div>
        <Card className="mt-4">
          <CardBody>
            <Row>
              <Col md="12">
                <form
                  className="ps-5 pe-5 pt-3"
                  onSubmit={editData?._id ? handleUpdate : handleSubmit}
                >
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group mb-4">
                        <Label for="inputPassword" className="col-form-label">
                          Title
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="inputPassword"
                          placeholder="Enter your title"
                          name="title"
                          invalid={!editData && !data?.title && formSubmitted}
                          value={data.title}
                          onChange={(e) => handleChange(e)}
                        />
                        <FormFeedback>Title is required</FormFeedback>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group mb-4">
                        <Label for="inputPassword" className="col-form-label">
                          Url
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="inputPassword"
                          placeholder="Enter your url"
                          name="slug"
                          invalid={!editData && !data.slug && formSubmitted}
                          value={data.slug}
                          onChange={(e) => handleChange(e)}
                        />
                        <FormFeedback>URL is required</FormFeedback>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group mb-4">
                        <Label for="inputPassword" className="col-form-label">
                          Meta Title
                        </Label>
                        <Input
                          type="input"
                          className="form-control"
                          id="inputPassword"
                          placeholder="Enter your meta title"
                          name="metaTitle"
                          value={data.metaTitle}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group mb-4">
                        <Label for="inputPassword" className="col-form-label">
                          Meta Keyword
                        </Label>
                        <Input
                          type="textarea"
                          className="form-control"
                          id="inputPassword"
                          placeholder="Enter your meta keyword"
                          name="metaKeyword"
                          value={data.metaKeyword}
                          onChange={(e) => handleChange(e)}
                          rows={3}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group mb-4">
                        <Label for="inputPassword" className="col-form-label">
                          Meta Description
                        </Label>
                        <Input
                          type="textarea"
                          className="form-control"
                          id="inputPassword"
                          placeholder="Enter your meta description"
                          name="metaDescription"
                          value={data.metaDescription}
                          onChange={(e) => handleChange(e)}
                          rows={3}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <Label for="inputPassword" className="col-form-label">
                        Description
                      </Label>
                      <CommonEditor
                        value={data.description}
                        placeholder="Enter content ..."
                        onChange={(value) =>
                          handleChange({
                            target: { name: "description", value },
                          })
                        }
                      />
                      {formSubmitted && !data?.description?.trim() && (
                        <div className="text-danger">
                          Description is required
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row mb-4 mt-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-2 col-form-label"
                    >
                      isActive
                    </Label>
                    <div className="col-sm-10">
                      <FormGroup switch>
                        <Input
                          type="checkbox"
                          className={`${Styles.form_check_input}`}
                          name="isActive"
                          checked={data.isActive}
                          onChange={handleChange}
                        />

                        <Label check className="mx-1">
                          {data.isActive ? "Active" : "Deactive"}
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="form-group row mb-4">
                    <div className="col-sm-7 d-flex">
                      <div className={`${Styles.submit_button}`}>
                        <Button type="submit">
                          {editData?._id ? "Update" : "Save"}
                        </Button>
                      </div>
                      <div className={`${Styles.cancel_button} me-4 mx-2`}>
                        <Link to="/cms">
                          {" "}
                          <Button className={`${Styles.cancel_button}`}>
                            Cancel
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AddCms;
