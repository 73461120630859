import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Input, Row, Table } from "reactstrap";
import axiosInstance from "../../axiosInterceptor";
import { dateFormat } from "../../dateFormat";
import { Link, useParams } from "react-router-dom";
import "rsuite/DateRangePicker/styles/index.css";
import { MultiSelect } from "react-multi-select-component";
import { toast } from "react-toastify";

const Userrights = () => {
  const { userId } = useParams();
  const [loading, setLoading] = useState(false);
  const [permissionlist, setPermissionlist] = useState();
  const [pages, setPages] = useState([]);
  const [pageId, setPageId] = useState("");
  const [pageActions, setPageActions] = useState([]);
  const [actionIds, setPageActionIds] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `admin/page/getPermissionByAdminUser/${userId}`
      );
      if (response.data.isSuccess) {
        setPermissionlist(response.data.data.pages);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPage();
    fetchData();
  }, [userId]);

  const fetchPage = async () => {
    try {
      const response = await axiosInstance.get(
        `admin/page/listRemainingPages/${userId}`
      );
      if (response.data.isSuccess) {
        setPages(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchActions = async () => {
      try {
        if (pageId) {
          let arr = [];
          let data = {
            pageId: pageId,
            adminId: userId,
          };
          const response = await axiosInstance.post(
            "admin/page/getPageById",
            data
          );
          if (response.data.isSuccess) {
            response?.data?.data?.map((el) => {
              arr.push({
                label: el.actionType,
                value: el._id,
              });
            });
            setPageActions(arr);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchActions();
  }, [pageId, userId]);

  const handleSavePage = async () => {
    const data = {
      pages: {
        pageId: pageId,
        allocatedActions: actionIds.map((x) => x.value),
      },
    };
    const response = await axiosInstance.put(
      `admin/managePageAccess/${userId}`,
      data
    );
    if (response.data.isSuccess) {
      toast.success(response.data.message);
      setPageId("");
      setPageActionIds([]);
      setSelectedOptions([]);
      fetchData();
      fetchPage();
    }
  };

  const handleRemovePage = async (row) => {
    const data = {
      pageId: row.pageId,
    };
    const response = await axiosInstance.put(
      `admin/removePageAccess/${userId}`,
      data
    );
    if (response.data.isSuccess) {
      toast.success(response.data.message);
      fetchData();
    }
  };

  const handleAllocatedAction = async (el, row) => {
    const data = {
      allocatedActionId: el.actionId,
      status: el.status === true ? false : el.status === false ? true : null,
      pageId: row.pageId,
    };
    const response = await axiosInstance.put(
      `admin/managePageAllocatedAction/${userId}`,
      data
    );
    if (response.data.isSuccess) {
      toast.success(response.data.message);
      fetchData();
    }
  };

  const handleSubpage = (selectedOptions) => {
    setPageActionIds(selectedOptions);
    setSelectedOptions(selectedOptions);
  };

  return (
    <div className="mt-4 mb-4">
      <h4 className="page-title">Rights</h4>
      <div className="student-list">
        <Card className="mt-4 ">
          <CardBody>
            <Row>
              <Col md="5">
                <Input
                  type="select"
                  value={pageId}
                  onChange={(e) => setPageId(e.target.value)}
                >
                  <option value="">Select Page</option>
                  {pages?.map((page) => (
                    <option key={page._id} value={page._id}>
                      {page.pageName}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col md="5">
                <MultiSelect
                  options={pageActions}
                  value={actionIds}
                  onChange={handleSubpage}
                  labelledBy={"Select page action"}
                  hasSelectAll={false}
                  disableSearch={true}
                />
              </Col>
              <Col md="2">
                <Button
                  className="action-button add-from-new"
                  onClick={handleSavePage}
                  disabled={selectedOptions.length === 0}
                >
                  Save
                </Button>
              </Col>
            </Row>
            {loading ? (
              <div className="text-center mt-4">
                <p>Loading...</p>
              </div>
            ) : permissionlist?.length > 0 ? (
              <div className="table-responsive">
                <Table bordered responsive className="mt-4" style={{ fontSize: '0.8rem' }}>
                  <thead>
                    <tr className="font-small-head">
                      <th className="text-center">Page Name</th>
                      <th className="text-center">Allocated Actions</th>
                      <th className="text-center">Allocated Date</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {permissionlist?.map((row) => (
                      <tr key={row._id}>
                        <td className="text-center">{row?.pageName}</td>
                        <td className="text-center">
                          {row?.allocatedActions.map((action, index) => {
                            return (
                              <div className="gap-2" key={index}>
                                <button
                                  className={`${action.status ? "text-success mb-2" : "text-danger mb-2"
                                    }`}
                                  onClick={() => handleAllocatedAction(action, row)}
                                >
                                  {action.actionType}
                                </button>
                              </div>
                            );
                          })}
                        </td>
                        <td className="text-center">
                          {dateFormat(row?.permissionDate)}
                        </td>
                        <td className="text-center">
                          <Button className="action-button" onClick={() => handleRemovePage(row)}>
                            Remove Authorized Page
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : (
              <div className="text-center mt-4">
                <p>No data found.</p>
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Userrights;
