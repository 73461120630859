import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap';
// import { FaSearch } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from "../../axiosInterceptor";
import { toast } from 'react-toastify';
import { dateFormat } from '../../dateFormat';
import Styles from '../homework/assets/Form.module.css'
import grp713 from '../homework/assets/images/Group 713.png'
import delete1 from '../homework/assets/images/delete.png'
// import PDF from '../../assets/icon/pdf.png'
import { DateRangePicker } from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';

// const baseURL = process.env.REACT_APP_FILE_BASE_URL;

const AssignmentLibraylist = () => {

  const [loading, setLoading] = useState(false);
  const [questionTypes, setQuestionTypes] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [questionData, setquestionData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSubject, setSelectedSubject] = useState('');

  const [deleteItemId, setDeleteItemId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const navigator = useNavigate();

  useEffect(() => {
    const fetchQuestionTypes = async () => {
      try {
        const response = await axiosInstance.get(`admin/category/getAllCategory`);
        if (response.data.isSuccess) {
          const categories = response.data.data;
          const homeCategory = categories.find(category => category.name === "Assignment Help");
          if (homeCategory) {
            const categoryId = homeCategory._id;
            await fetchSubcategories(categoryId);
          } else {
            toast.error("No category named 'Assignment Help' found.");
          }
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again!!");
      }
    };

    const fetchSubcategories = async (categoryId) => {
      try {
        const response = await axiosInstance.get(`admin/subcategory/getCategoryWiseSubcategory/${categoryId}`);
        if (response.data.isSuccess) {
          setQuestionTypes(response.data.subCategory);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again!!");
      }
    };

    fetchQuestionTypes();
  }, []);


  const fetchData = async (page = 1, subjectId = '') => {
    try {
      setLoading(true);

      const response = await axiosInstance.post(
        `admin/libraryAssignmentHelp/getAllQA?page=${page}`,
        {
          fromDate: fromDate,
          toDate: toDate,
          perPage: 10,
          pageNo: page,
          subjectId: subjectId
        }
      );

      const { data, totalPages } = response.data;
      setquestionData(data);
      setTotalPages(totalPages);
      setCurrentPage(page);
    } catch (error) {
      toast.error('Something went wrong. Please try again!!');
    } finally {
      setLoading(false);
    }
  };


  const handleDelete = async (questionId) => {
    setDeleteItemId(questionId);
    setModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await axiosInstance.delete(`admin/libraryAssignmentHelp/deleteQA/${deleteItemId}`);
      if (response.status === 200) {
        toast.success(response.data.message);
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again!!');
    } finally {
      setDeleteItemId(null);
      setModalOpen(false);
    }
  };


  useEffect(() => {
    if (selectedSubject) {
      fetchData(1, selectedSubject);
    } else {
      fetchData();
    }
  }, [selectedSubject, fromDate, toDate]);

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      fetchData(page, selectedSubject);
    }
  };

  return (
    <div>
      <div className="mt-4 mb-4">
        <div className='row'>
          <div className='col-sm-6'>
            <h4 className="page-title">Assignment Libray</h4>
          </div>
          <div className='col-sm-6 text-end mt-2'>
            <Link to="/add-assignment-library">
              <Button className="action-button add-from-new">
                ADD Q & A
              </Button>
            </Link>
          </div>
        </div>
        <Card className='mt-4'>
          <CardBody>
            <Row>
              <Col md="6">
                <DateRangePicker className='w-100'
                  // value={[new Date(fromDate), new Date(toDate)]}
                  onChange={(value) => {
                    if (value && value[0]) {
                      setFromDate(value[0].toISOString().split('T')[0]);
                      setToDate(value[1] ? value[1].toISOString().split('T')[0] : '');
                    }
                  }}
                  onClean={() => {
                    setFromDate(null);
                    setToDate(null);
                  }}
                />
              </Col>
              <Col md="6">
                <Input
                  id="exampleSelect"
                  name="select"
                  type="select"
                  value={selectedSubject}
                  onChange={(e) => setSelectedSubject(e.target.value)}
                >
                  <option value="">All Assignment Type</option>
                  {questionTypes?.map((questionType) => (
                    <option key={questionType._id} value={questionType._id}>
                      {questionType.name}
                    </option>
                  ))}
                </Input>
              </Col>
            </Row>
            {loading ? (
              <div className="text-center mt-4">
                <p>Loading...</p>
              </div>
            ) : questionData?.length > 0 ? (
              <div className="table-responsive">
                <Table bordered responsive className="mt-4" style={{ fontSize: '0.8rem' }}>
                  <thead>
                    <tr>
                      <th>Date & Time</th>
                      <th>Question Title</th>
                      <th>Solution Title</th>
                      <th>Type</th>
                      <th className='text-center'>Question Files</th>
                      <th className='text-center'>Solution Files</th>
                      <th className='text-center'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {questionData.map((item, index) => (
                      <tr key={index}>
                        <td>{dateFormat(item.updatedAt)}</td>
                        <td>{item?.questionTitle}</td>
                        <td>{item?.answerTitle}</td>
                        <td>{item?.subCategoryId?.name}</td>
                        <td className='text-center'>{item?.questionFile?.length}</td>
                        <td className='text-center'>{item?.solutionFile?.length}</td>
                        {/* <td className='text-center'>
                          <Link to={`${baseURL}${item.questionFile}`} target="_blank">
                            <img src={PDF} alt="pdf" className="ms-3 me-3" width={60} />
                          </Link>
                        </td>
                        <td className='text-center'>
                          <Link to={`${baseURL}${item.questionFile}`} target="_blank">
                            <img src={PDF} alt="pdf" className="ms-3 me-3" width={60} />
                          </Link>
                        </td> */}
                        <td className='d-flex justify-content-center'>
                          <div className={`${Styles.edit_code}`}>
                            <Button className={`${Styles.edit_code}`}
                              onClick={() => { navigator('/add-assignment-library', { state: item }) }}>
                              <img
                                src={grp713}
                                alt="error"
                                className="mx-1"
                                srcSet=""
                                width={19}
                              />
                              Edit
                            </Button>
                          </div>
                          <div className={`${Styles.delete_button_code} me-2 mx-2`}>
                            <Button className={`${Styles.delete_button_code}`} onClick={() => handleDelete(item._id)}>
                              <img
                                src={delete1}
                                alt="error"
                                className={`${Styles.delete_icon}`}
                                srcSet=""
                              />
                              Delete
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : (
              <div className="text-center mt-4">
                <p>No data found.</p>
              </div>
            )}
            <div className="float-end">
              <Pagination>
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink previous onClick={() => handlePageClick(currentPage - 1)} />
                </PaginationItem>
                {[...Array(totalPages)].map((_, i) => (
                  <PaginationItem key={i} active={i + 1 === currentPage}>
                    <PaginationLink onClick={() => handlePageClick(i + 1)}>{i + 1}</PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink next onClick={() => handlePageClick(currentPage + 1)} />
                </PaginationItem>
              </Pagination>
            </div>
          </CardBody>
        </Card>

      </div>
      <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} style={{ top: "33%" }}>
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>Confirm Delete</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this files?
        </ModalBody>
        <ModalFooter>
          <div className={`${Styles.button_color}`}>
            <Button
              type="cancel"
              onClick={() => setModalOpen(!modalOpen)}
            >
              No
            </Button>
          </div>
          <div className={`${Styles.cancel_button} me-4`}>
            <Button
              type="cancel"
              className={`${Styles.cancel_button}`}
              onClick={confirmDelete}
            >
              Yes
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default AssignmentLibraylist