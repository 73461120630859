import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/logo/logo.png";
import "./Login.scss";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";
import { toast } from "react-toastify";
import axiosInstance from "../axiosInterceptor";

const Login = () => {
  const navigator = useNavigate();

  const [formData, setFormData] = useState({});
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitAttempted(true);

    if (!formData.email || !formData.password) {
      return;
    }

    try {
      const response = await axiosInstance.post(`admin/login`, formData);
      localStorage.setItem("authToken", response.data.authToken);
      localStorage.setItem("_id", response.data.admin._id);

      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/welcome");
        window.location.reload();
      } else {
        toast.error(response.data.message);
        navigator("/");
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  return (
    <div className="login-register-bg">
      <Container>
        <Row className="d-lg-flex align-items-center justify-content-center d-block h-100">
          <Col lg="7" className="mx-auto">
            <div className="logo text-center p-4">
              <Link to="#">
                <img src={Logo} alt="DoubtQ Logo" />
              </Link>
            </div>
            <div className="rbt-contact-form contact-form-style-1 rbt-shadow-box mb--50 mt-2">
              <Row className="align-items-center">
                <Col lg="12" className="pl--30 pl_sm--0">
                  <div>
                    <h4 className="title text-center mb--10">
                      <b>Admin Login</b>
                    </h4>
                    <Form
                      className="rbt-profile-row rbt-default-form row row--15"
                      onSubmit={handleSubmit}
                    >
                      <Col lg="12" md="12" xs="12">
                        <FormGroup>
                          <Label>Email</Label>
                          <Input
                            invalid={!formData.email && submitAttempted}
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Enter your email"
                          />
                          <FormFeedback>Email is required</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col lg="12" md="12" xs="12">
                        <FormGroup>
                          <Label>Password</Label>
                          <Input
                            invalid={!formData.password && submitAttempted}
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            placeholder="Enter your password"
                          />
                          <FormFeedback> Password is required </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col lg="12" md="12" xs="12" className="text-end">
                        <div className="mt--5">
                          <Link
                            className="color-primary pt--10"
                            to="/forgot-password"
                          >
                            Forgot password?
                          </Link>
                        </div>
                        <div className="text-center">
                          <Button type="submit" className="px-4 login-button">
                            Login
                          </Button>
                        </div>
                      </Col>
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
