import axios from "axios";

const apiKey = process.env.REACT_APP_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: apiKey,
});

const token = localStorage.getItem("authToken");

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // You can add any logic here before the request is sent

    config.headers["authorization"] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // You can add any logic here for successful responses
    return response;
  },
  (error) => {
    // You can add error handling logic here
    handleTokenErrors(error)
    return Promise.reject(error);
  }
);

export const handleTokenErrors = (error) => {
  if (error.response.data.error === 'jwt expired') {
    localStorage.removeItem("authToken");
    window.location.href = "/";
  }
};

export default axiosInstance;
