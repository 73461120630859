import React, { useState, useEffect } from 'react';
import Styles from '../pages/homework/assets/Form.module.css';
import { Button, Card, CardBody, Col, Input, Label, Row } from 'reactstrap';
import axiosInstance from '../axiosInterceptor';
import { toast } from 'react-toastify';

const Profile = () => {
    const [profileData, setProfileData] = useState(null);
    useEffect(() => {
        axiosInstance.get('admin/getProfile')
            .then(response => {
                if (response.data.isSuccess) {
                    setProfileData(response.data.profile);
                } else {
                    toast.error(response.data.error);
                }
            })
            .catch(error => {
                toast.error('Something went wrong. Please try again!!');
            });
    }, []);

    return (
        <div>
            <div className="mt-4 mb-4">
                <div className='row'>
                    <div className='col-sm-6'>
                        <h4 className="page-title">Profile</h4>
                    </div>
                    <div className='mt-4'>
                        <Row>
                            <Col md="8">
                                <form>
                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-3 col-form-label">
                                            Name
                                        </Label>
                                        <div className="col-sm-6">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                placeholder="Enter your name"
                                                name='name'
                                                value={profileData?.fullName || ''}
                                                readOnly // to make the field read-only
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-3 col-form-label">
                                            Email
                                        </Label>
                                        <div className="col-sm-6">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="email"
                                                placeholder="Enter your email"
                                                name='email'
                                                value={profileData?.email || ''}
                                                readOnly // to make the field read-only
                                            />
                                        </div>
                                    </div>

                                    {/* <div className="form-group row mb-4">
                                            <Label for="inputPassword" className="col-sm-3 col-form-label">
                                                <b className="ms-2"></b>
                                            </Label>
                                            <div className="col-sm-6 d-flex">
                                                <div className={`${Styles.submit_button}`}>
                                                    <Button type='submit'>Save</Button>
                                                </div>
                                            </div>
                                        </div> */}
                                </form>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;
