import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Styles from "../homework/assets/Form.module.css";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInterceptor";

const AddFontSize = () => {
  const location = useLocation();
  const editData = location.state;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigator = useNavigate();

  const [data, setData] = useState({
    fontSize: editData?.fontSize || "",
    isActive: editData?.isActive || false,
  });

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name !== "image" && name !== "isActive") {
      setData({ ...data, [name]: value });
    } else if (name === "isActive") {
      setData({ ...data, [name]: checked });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!data.fontSize) {
      return;
    }
    try {
      const response = await axiosInstance.post(
        `admin/fontSize/createFontSize`,
        {
          fontSize: data.fontSize,
          isActive: data.isActive,
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/font-size");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.put(
        `admin/fontSize/updateFontSize/${editData?._id}`,
        {
          fontSize: data.fontSize,
          isActive: data.isActive,
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/font-size");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  return (
    <div>
      <div className="mt-4 mb-4">
        <div className="row">
          <div className="col-sm-6">
            <h4 className="page-title">Add Font Size</h4>
          </div>
        </div>
        <Card className="mt-4">
          <CardBody>
            <Row>
              <Col md="8">
                <form
                  className="ps-5 pe-5 pt-3"
                  onSubmit={editData?._id ? handleUpdate : handleSubmit}
                >
                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      Font Size
                    </Label>
                    <div className="col-sm-7">
                      <Input
                        type="number"
                        className="form-control"
                        id="inputPassword"
                        placeholder="Enter your font size "
                        invalid={!editData && !data.fontSize && formSubmitted}
                        name="fontSize"
                        value={data.fontSize}
                        onChange={(e) => handleChange(e)}
                      />
                      <FormFeedback>Font size is required</FormFeedback>
                    </div>
                  </div>
                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      IsActive
                    </Label>
                    <div className="col-sm-7">
                      <FormGroup switch>
                        <Input
                          type="switch"
                          className={`${Styles.form_check_input}`}
                          name="isActive"
                          checked={data.isActive}
                          onChange={(e) => handleChange(e)}
                        />
                        <Label check className="mx-1">
                          {data.isActive === true ? "Active" : "Deactive"}
                        </Label>
                      </FormGroup>
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      <b className="ms-2"></b>
                    </Label>
                    <div className="col-sm-7 d-flex">
                      <div className={`${Styles.submit_button}`}>
                        <Button type="submit">
                          {editData?._id ? "Update" : "Save"}
                        </Button>
                      </div>
                      <div className={`${Styles.cancel_button} me-4 mx-2`}>
                        <Link to="/font-size">
                          <Button className={`${Styles.cancel_button}`}>
                            Cancel
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AddFontSize;
