import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import axiosInstance from '../../axiosInterceptor';
import { toast } from 'react-toastify';

const Sitemap = () => {
    const [loading, setLoading] = useState(false);
    const [sitemapData, setSitemapData] = useState([]);

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get('admin/sitemap');
            const { data } = response.data;
            setSitemapData(data);
            toast.success('Sitemap generated successfully!');
        } catch (error) {
            toast.error('Failed to generate sitemap. Please try again!');
        } finally {
            setLoading(false);
        }
    };

    const handleGenerateSitemap = () => {
        fetchData();
    };

    return (
        <div>
            <div className="my-2">
                <div className="row">
                    <div className="col-sm-6">
                        <h4 className="page-title">Sitemap Generator</h4>
                    </div>
                </div>
                <Card>
                    <CardBody>
                        <Row>
                            <Col md="8">
                                <div className="generate-sitemap">
                                    <Button
                                        type="button"
                                        onClick={handleGenerateSitemap}
                                        style={{
                                            background: 'linear-gradient(to right, #5956E9, #E541ED)',
                                            color: 'white',
                                            border: 'none'
                                        }}
                                    >
                                        {loading ? 'Generating...' : 'Generate Sitemap'}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
};

export default Sitemap;
