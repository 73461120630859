import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Styles from "../homework/assets/Form.module.css";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInterceptor";
import { ImageLink } from "../../constants";

const AddBonus = () => {
  const location = useLocation();
  const editData = location.state;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [subCategory, setSubcategory] = useState([]);
  const navigator = useNavigate();

  const [data, setData] = useState({
    title: editData?.title || "",
    description: editData?.description || "",
    subCategoryId: editData?.subCategoryId._id || "",
    image: editData?.image || "",
    isActive: editData?.isActive || false,
  });

  const handleChange = (e) => {
    const { name, value, checked, files } = e.target;
    if (name !== "isActive") {
      setData({ ...data, [name]: name === "image" ? files[0] : value });
    } else {
      setData({ ...data, [name]: checked });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (
      !data.subCategoryId ||
      !data.description ||
      !data.title ||
      !data.image
    ) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append("subCategoryId", data.subCategoryId);
      formData.append("title", data.title);
      formData.append("image", data.image);
      formData.append("description", data.description);
      formData.append("isActive", data.isActive);
      const response = await axiosInstance.post(
        `admin/bonuses/createBonus`,
        formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/bonus");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("subCategoryId", data?.subCategoryId);
      formData.append("title", data.title);
      formData.append("description", data.description);
      formData.append("isActive", data.isActive);
      if (data.image instanceof File) {
        formData.append("image", data.image);
      }
      const response = await axiosInstance.put(
        `admin/bonuses/updateBonus/${editData?._id}`,
        formData
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/bonus");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  const fetchSubCategory = async () => {
    try {
      const response = await axiosInstance.get(
        `admin/subcategory/getAllSubcategory`
      );
      if (response.data.isSuccess) {
        setSubcategory(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  useEffect(() => {
    fetchSubCategory();
  }, []);

  return (
    <div>
      <div className="mt-4 mb-4">
        <div className="row">
          <div className="col-sm-6">
            <h4 className="page-title">Add Bonus</h4>
          </div>
        </div>
        <Card className="mt-4">
          <CardBody>
            <Row>
              <Col md="8">
                <form
                  className="ps-5 pe-5 pt-3"
                  onSubmit={editData?._id ? handleUpdate : handleSubmit}
                >
                  <div className="form-group row mb-2">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      Sub Category
                    </Label>
                    <div className="col-sm-8">
                      <FormGroup>
                        <Input
                          id="exampleSelect"
                          name="subCategoryId"
                          type="select"
                          invalid={!data.subCategoryId && formSubmitted}
                          defaultValue={
                            editData
                              ? editData?.subCategoryId === data.subCategoryId
                                ? data.subCategoryId
                                : ""
                              : ""
                          }
                          onChange={(e) => handleChange(e)}
                        >
                          <option value="">
                            {editData
                              ? editData?.subCategoryId._id ===
                                data.subCategoryId
                                ? subCategory.find(
                                    (subcat) =>
                                      subcat._id === data.subCategoryId
                                  )?.name
                                : "--- Select sub category ---"
                              : "--- Select sub category ---"}
                          </option>
                          {subCategory?.map((subcat) => (
                            <option key={subcat._id} value={subcat._id}>
                              {subcat.name}
                            </option>
                          ))}
                        </Input>
                        <FormFeedback>Sub category is required</FormFeedback>
                      </FormGroup>
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      Bonus Title
                    </Label>
                    <div className="col-sm-8">
                      <Input
                        type="text"
                        className="form-control"
                        id="inputPassword"
                        name="title"
                        placeholder="Enter your bonus title"
                        invalid={!data.title && formSubmitted}
                        value={data.title}
                        onChange={(e) => handleChange(e)}
                      />
                      <FormFeedback>Bonus title is required</FormFeedback>
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      Description{" "}
                    </Label>
                    <div className="col-sm-8">
                      <Input
                        type="textarea"
                        className="form-control"
                        id="inputPassword"
                        name="description"
                        placeholder="Enter your bonus description"
                        invalid={!data.description && formSubmitted}
                        value={data.description}
                        onChange={(e) => handleChange(e)}
                        rows={5}
                      />
                      <FormFeedback>Bonus description is required</FormFeedback>
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      Image{" "}
                    </Label>
                    <div className="col-sm-8">
                      <Input
                        type="file"
                        className="form-control"
                        id="inputPassword"
                        name="image"
                        onChange={(e) => handleChange(e)}
                      />
                      <FormFeedback>Banner image is required</FormFeedback>
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      IsActive
                    </Label>
                    <div className="col-sm-7">
                      <FormGroup switch>
                        <Input
                          type="switch"
                          className={`${Styles.form_check_input}`}
                          name="isActive"
                          checked={data.isActive}
                          onChange={(e) => handleChange(e)}
                        />
                        <Label check className="mx-1">
                          {data.isActive === true ? "Active" : "Deactive"}
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      <b className="ms-2"></b>
                    </Label>
                    <div className="col-sm-7 d-flex">
                      <div className={`${Styles.submit_button}`}>
                        <Button type="submit">
                          {editData?._id ? "Update" : "Save"}
                        </Button>
                      </div>
                      <div className={`${Styles.cancel_button} me-4 mx-2`}>
                        <Link to="/bonus">
                          {" "}
                          <Button className={`${Styles.cancel_button}`}>
                            Cancel
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </Col>
              {data.image && (
                <Col md="4">
                  <div className="border w-100 h-75 text-center">
                    <p className="mt-3">Image Preview</p>
                    <br />
                    {data.image instanceof File ? (
                      <img
                        src={URL.createObjectURL(data.image)}
                        alt="Preview"
                        className="w-50"
                      />
                    ) : (
                      <img src={ImageLink(data.image)} className="w-50" alt="data_img"/>
                    )}
                  </div>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AddBonus;
