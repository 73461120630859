
import React, { useState } from 'react'
import { Button, Card, CardBody, Col, FormFeedback, Input, Label, Row } from 'reactstrap'
import Styles from '../homework/assets/Form.module.css'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import axiosInstance from '../../axiosInterceptor'

const AddUsers = () => {

    const [formSubmitted, setFormSubmitted] = useState(false)
    const [data, setData] = useState({
        fullName: '',
        email: '',
        password: '',
    })
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const navigator = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        const validationErrors = {};
        if (!data.fullName.trim()) {
            validationErrors.fullName = 'Full Name is required';
        }
        if (!data.email.trim()) {
            validationErrors.email = 'Email is required';
        }
        if (!data.password.trim()) {
            validationErrors.password = 'Password is required';
        }

        if (Object.keys(validationErrors).length > 0) {
            // If there are validation errors, set errors state and return
            setErrors(validationErrors);
            return;
        }

        try {
            const response = await axiosInstance.post(`admin/addUser`, {
                fullName: data.fullName,
                email: data.email,
                password: data.password,
            });

            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/users-rights");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    return (
        <div>
            <div className="mt-4 mb-4">
                <div className='row'>
                    <div className='col-sm-6'>
                        <h4 className="page-title">Add Users</h4>
                    </div>
                </div>
                <Card className='mt-4'>
                    <CardBody>
                        <Row>
                            <Col md="10">
                                <form className="ps-5 pe-5 pt-3" onSubmit={handleSubmit}>
                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Full Name
                                        </Label>
                                        <div className="col-sm-8">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="fullName"
                                                placeholder="Enter your name"
                                                name='fullName'
                                                invalid={formSubmitted && errors.fullName}
                                                value={data.fullName}
                                                onChange={(e) => handleChange(e)}
                                                rows={3}
                                            />
                                            {formSubmitted && errors.fullName && <FormFeedback>{errors.fullName}</FormFeedback>}
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Email
                                        </Label>
                                        <div className="col-sm-8">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="email"
                                                placeholder="Enter your email"
                                                name='email'
                                                invalid={formSubmitted && errors.email}
                                                value={data.email}
                                                onChange={(e) => handleChange(e)}
                                                rows={3}
                                            />
                                            {formSubmitted && errors.email && <FormFeedback>{errors.email}</FormFeedback>}
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Password
                                        </Label>
                                        <div className="col-sm-8">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="password"
                                                placeholder="Enter your password"
                                                name='password'
                                                invalid={formSubmitted && errors.password}
                                                value={data.password}
                                                onChange={(e) => handleChange(e)}
                                                rows={3}
                                            />
                                            {formSubmitted && errors.password && <FormFeedback>{errors.password}</FormFeedback>}
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            <b className="ms-2"></b>
                                        </Label>
                                        <div className="col-sm-7 d-flex">
                                            <div className={`${Styles.submit_button}`}>
                                                <Button type='submit'>Save</Button>
                                            </div>
                                            <div className={`${Styles.cancel_button} me-4 mx-2`}>
                                                <Link to='/users-rights'> <Button className={`${Styles.cancel_button}`}>Cancel</Button></Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default AddUsers