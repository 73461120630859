import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Input, Label, Row } from 'reactstrap';
import Styles from '../homework/assets/Form.module.css';
import grp713 from '../homework/assets/images/Group 713.png'
import vector132 from '../homework/assets/images/Vector 132.png'
import { toast } from 'react-toastify';
import axiosInstance from '../../axiosInterceptor';

const EmailSetting = () => {

    const [editMode, setEditMode] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        host: '',
        port: '',
        fromEmail: '',
        bccEmail: '',
        ccEmail: ''
    });

    useEffect(() => {
        axiosInstance.get('admin/emailSetting/getEmailSetting')
            .then(response => {
                if (response.data.isSuccess) {
                    setFormData(response.data.data);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(error => {
                toast.error('Error fetching email settings');
                console.error('Error fetching email settings:', error);
            });
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { _id, __v, updatedAt, createdAt, ...dataWithoutId } = formData;
        axiosInstance.put('admin/emailSetting/updateEmailSetting', dataWithoutId)
            .then(response => {
                if (response.data.isSuccess) {
                    toast.success('Email settings updated successfully');
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(error => {
                toast.error('Error updating email settings');
                console.error('Error updating email settings:', error);
            });
    };

    return (
        <div>
            <div className="my-2">
                <div className='row'>
                    <div className='col-sm-6'>
                        <h4 className="page-title">Email Settings</h4>
                    </div>
                </div>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <div className={`${Styles.button_edit} pe-5 float-end`}>
                                    <Button className={`${Styles.button_edit}`} onClick={() => setEditMode(!editMode)}>
                                        {editMode ? "Cancel" : "Edit"}
                                        <img
                                            src={grp713}
                                            alt="error"
                                            className="ps-2"
                                            srcSet=""
                                        />
                                    </Button>
                                    <span className={`${Styles.drop_down_arrow}`}>

                                        <img
                                            src={vector132}
                                            alt="error"
                                            className="ps-2"
                                            srcSet=""
                                        />
                                    </span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="8">
                                <form className="ps-5 pe-5 pt-3" onSubmit={handleSubmit}>
                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Gmail/workspace id: </Label>
                                        <div className="col-sm-7">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="email"
                                                name='email'
                                                placeholder="Enter your email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                disabled={!editMode}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Password </Label>
                                        <div className="col-sm-7">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="inputPassword"
                                                name='password'
                                                placeholder="Enter your password"
                                                value={formData.password}
                                                onChange={handleChange}
                                                disabled={!editMode}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Host </Label>
                                        <div className="col-sm-7">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="inputPassword"
                                                name='host'
                                                placeholder="Enter your host"
                                                value={formData.host}
                                                onChange={handleChange}
                                                disabled={!editMode}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Port</Label>
                                        <div className="col-sm-7">
                                            <Input
                                                type="number"
                                                className="form-control"
                                                id="inputPassword"
                                                placeholder="Enter your port"
                                                name='port'
                                                value={formData.port}
                                                onChange={handleChange}
                                                disabled={!editMode}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            FromEmail </Label>
                                        <div className="col-sm-7">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="inputPassword"
                                                name='fromEmail'
                                                placeholder="Enter your from email"
                                                value={formData.fromEmail}
                                                onChange={handleChange}
                                                disabled={!editMode}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            BccEmail </Label>
                                        <div className="col-sm-7">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="inputPassword"
                                                name='bccEmail'
                                                placeholder="Enter your bcc email"
                                                value={formData.bccEmail}
                                                onChange={handleChange}
                                                disabled={!editMode}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            CcEmail </Label>
                                        <div className="col-sm-7">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="inputPassword"
                                                name='ccEmail'
                                                placeholder="Enter your cc email"
                                                value={formData.ccEmail}
                                                onChange={handleChange}
                                                disabled={!editMode}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            <b className="ms-2"></b>
                                        </Label>
                                        <div className="col-sm-7 d-flex">
                                            <div className={`${Styles.cancel_button} me-4`}>
                                                <Button onClick={() => setEditMode(false)} disabled={!editMode} className={`${Styles.cancel_button}`}>Cancel</Button>
                                            </div>
                                            <div className={`${Styles.submit_button}`}>
                                                <Button onClick={handleSubmit} disabled={!editMode}>Submit</Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
};

export default EmailSetting;
