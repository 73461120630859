import React, { useState } from 'react'
import { Button, Card, CardBody, Col, FormFeedback, Input, Label, Row } from 'reactstrap'
import Styles from '../homework/assets/Form.module.css'
import { toast } from 'react-toastify'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../../axiosInterceptor'

const AddBonusOffer = () => {
  const location = useLocation();
  const bonusOfferData = location.state;
  const [formSubmitted, setFormSubmitted] = useState(false)
  const navigator = useNavigate();

  const [data, setData] = useState({
    name: bonusOfferData?.name || '',
    description: bonusOfferData?.description || '',
    percentage: bonusOfferData?.percentage || '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name) {
      setData({ ...data, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true)
    if (!data.name || !data.percentage || !data.description) {
      return;
    }
    try {
      const response = await axiosInstance.post(`admin/bonusOffers/createBonusOffer`, {
        name: data.name,
        percentage: parseInt(data.percentage),
        description: data.description
      });
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/bonus-offer");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again!!');
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.put(`admin/bonusOffers/updateBonusOffer/${bonusOfferData?._id}`, {
        name: data.name,
        percentage: parseInt(data.percentage),
        description: data.description
      });
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/bonus-offer");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again!!');
    }
  };

  return (
    <div>
      <div className="mt-4 mb-4">
        <div className='row'>
          <div className='col-sm-6'>
            <h4 className="page-title">Add Bonus Offer</h4>
          </div>
        </div>
        <Card className='mt-4'>
          <CardBody>
            <Row>
              <Col md="8">
                <form className="ps-5 pe-5 pt-3" onSubmit={bonusOfferData?._id ? handleUpdate : handleSubmit}>
                  <div className="form-group row mb-4">
                    <Label for="inputPassword" className="col-sm-4 col-form-label">
                      Offer Name </Label>
                    <div className="col-sm-7">
                      <Input
                        type="text"
                        className="form-control"
                        id="inputPassword"
                        placeholder="Enter your offer name "
                        name='name'
                        invalid={!bonusOfferData && !data.name && formSubmitted}
                        value={data.name}
                        onChange={(e) => handleChange(e)}
                      />
                      <FormFeedback>Offer name is required</FormFeedback>
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <Label for="inputPassword" className="col-sm-4 col-form-label">
                      Offer Percentage </Label>
                    <div className="col-sm-7">
                      <Input
                        type="number"
                        className="form-control"
                        id="inputPassword"
                        name='percentage'
                        placeholder="Enter your offer percentage "
                        invalid={!bonusOfferData && !data.percentage && formSubmitted}
                        value={data.percentage}
                        onChange={(e) => handleChange(e)}
                      />
                      <FormFeedback>Offer percentege is required</FormFeedback>
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <Label for="inputPassword" className="col-sm-4 col-form-label">
                      Offer Description </Label>
                    <div className="col-sm-7">
                      <Input
                        type="textarea"
                        className="form-control"
                        id="inputPassword"
                        name='description'
                        placeholder="Enter your offer description"
                        invalid={!bonusOfferData && !data.description && formSubmitted}
                        value={data.description}
                        onChange={(e) => handleChange(e)}
                        rows={5}
                      />
                      <FormFeedback>Offer description is required</FormFeedback>
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <Label for="inputPassword" className="col-sm-4 col-form-label">
                      <b className="ms-2"></b>
                    </Label>
                    <div className="col-sm-7 d-flex">
                      <div className={`${Styles.submit_button}`}>
                        <Button type='submit'>{bonusOfferData?._id ? "Update" : "Save"}</Button>
                      </div>
                      <div className={`${Styles.cancel_button} me-4 mx-2`}>
                        <Link to='/bonus-offer'> <Button className={`${Styles.cancel_button}`}>Cancel</Button></Link>
                      </div>
                    </div>
                  </div>
                </form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default AddBonusOffer