import React from 'react'
import grp713 from "./assets/images/Group 713.png";
import grp750 from "./assets/images/Group 750.png";
import icon from "./assets/images/plus circle_.png";
import Styles from "./assets/Tutor.module.css";
import { Table } from "reactstrap";
import { Link } from 'react-router-dom';

const Tutor_profile = () => {
  return (
    <div>
      <div className={`${Styles.second_container}`}>
        <div className="d-flex justify-content-between">
          <div className="">
            <h4>Tutor Details</h4>
          </div>
          <div>
            <button className={`${Styles.Back_button}`}>Back</button>
          </div>
        </div>
        {/* <!--------Profile Section Start-------------> */}
        <div className="card-group col mt-4">
          <div className="card m-2 col-sm-4">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <div className="pt-4 ps-3 pe-2">
                  <h4>Profile Details</h4>
                </div>
                <div className="">
                  <img
                    src={grp750}
                    alt="Profile"
                    height="80px"
                    width="80px"
                  />
                </div>
              </div>
              <hr />
              <div className="form-group row">
                <label for="inputPassword" className="col-sm-5 col-form-label">
                  Name<b className="ms-2">:</b>
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    id="inputPassword"
                    placeholder="Kartik Jain"
                  />
                </div>
              </div>
              <div className="form-group row mb-2 mt-2 pt-2">
                <label for="inputPassword" className="col-sm-5 col-form-label">
                  Whatsapp<b className="ms-2">:</b>
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    id="inputPassword"
                    placeholder="+91 9374744365"
                  />
                </div>
              </div>
              <div className="form-group row mb-2 mt-2 pt-2">
                <label for="inputPassword" className="col-sm-5 col-form-label">
                  Email id<b className="ms-2">:</b>
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    id="inputPassword"
                    placeholder="kartik@gmail.com"
                  />
                </div>
              </div>
              <div className="form-group row mb-2 mt-2 pt-2">
                <label for="inputPassword" className="col-sm-5 col-form-label">
                  Password<b className="ms-2">:</b>
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    id="inputPassword"
                    placeholder="67h7*hyhu"
                  />
                </div>
              </div>
              <div className="form-group row mb-2 mt-2 pt-2">
                <label for="inputPassword" className="col-sm-5 col-form-label">
                  Gender<b className="Male">:</b>
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    id="inputPassword"
                    placeholder="3"
                  />
                </div>
              </div>
              <div className="form-group row mb-2 mt-2 pt-2">
                <label for="inputPassword" className="col-sm-5 col-form-label">
                  Date of Birth<b className="ms-2">:</b>
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    id="inputPassword"
                    placeholder="27/12/1996"
                  />
                </div>
              </div>
              <div className="form-group row mb-2 mt-2 pt-2">
                <label for="inputPassword" className="col-sm-5 col-form-label">
                  Experience<b className="ms-2">:</b>
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    id="inputPassword"
                    placeholder="5"
                  />
                </div>
              </div>
              <div className="form-group row mb-1">
                <label for="inputPassword" className="col-form-label">
                  <b className="ms-2"></b>{" "}
                </label>
                <div className="col-sm-5  mt-5 d-flex">
                  <div className={`${Styles.submit_button} me-2`}>
                    <button>
                      Edit{" "}
                      <img
                        src={grp713}
                        alt="error"
                        className="ps-2"
                        srcSet=""
                      />
                    </button>
                  </div>
                  <div>
                    <button className={`${Styles.submit_profile_button}`}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}

          <div className=" rows col-sm-4">
            <div className="card m-2">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div className="pt-4 ps-3 pe-2">
                    <h4>Professional Details</h4>
                  </div>
                  <div className="">
                    <img
                      src={grp750}
                      alt="Profile"
                      height="80px"
                      width="80px"
                    />
                  </div>
                </div>
                <hr />
                <div className="">
                  <div className="form-group row">
                    <label for="inputPassword" className="col-sm-6 col-form-label">
                      Degree Name<b className="ms-2">:</b>
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword"
                        placeholder="B.com"
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-1">
                    <label for="inputPassword" className="col-form-label">
                      <b className="ms-2"></b>{" "}
                    </label>
                    <div className="col-sm-5  mt-1 d-flex">
                      <div className={`${Styles.submit_button} me-2`}>
                        <button>
                          Edit{" "}
                          <img
                            src={grp713}
                            alt="error"
                            className="ps-2"
                            srcSet=""
                          />
                        </button>
                      </div>
                      <div>
                        <button className={`${Styles.submit_profile_button}`}>
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card m-2">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div className="pt-4 ps-3 pe-2">
                      <h4>Bank Account Details</h4>
                    </div>
                  </div>
                  <hr />
                  <div className="">
                    <div className="form-group row mb-2">
                      <label
                        for="inputPassword"
                        className="col-sm-5 col-form-label"
                      >
                        Bank Name<b className="ms-2">:</b>
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="inputPassword"
                          placeholder="bank of baroda"
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-2">
                      <label
                        for="inputPassword"
                        className="col-sm-5 col-form-label"
                      >
                        A/c Number<b className="ms-2">:</b>
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="inputPassword"
                          placeholder="UHUUIH94984"
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-2">
                      <label
                        for="inputPassword"
                        className="col-sm-5 col-form-label"
                      >
                        IFSC CODE<b className="ms-2">:</b>
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="inputPassword"
                          placeholder="5965HBHB"
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-2">
                      <label
                        for="inputPassword"
                        className="col-sm-5 col-form-label"
                      >
                        UPI ID<b className="ms-2">:</b>
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="inputPassword"
                          placeholder="ijdiv@okici"
                        />
                      </div>
                    </div>

                    <div className="form-group row mb-1">
                      <label for="inputPassword" className="col-form-label">
                        <b className="ms-2"></b>{" "}
                      </label>
                      <div className="col-sm-5  mt-1 d-flex">
                        <div className={`${Styles.submit_button} me-2`}>
                          <button>
                            Edit{" "}
                            <img
                              src={grp713}
                              alt="error"
                              className="ps-2"
                              srcSet=""
                            />
                          </button>
                        </div>
                        <div>
                          <button className={`${Styles.submit_profile_button}`}>
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card m-2 col-sm-4">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <div className="pt-4 ps-3 pe-2">
                  <h4>Subject Details</h4>
                </div>
                <button className={`${Styles.add_button}  mt-4`}>
                  <img
                    src={icon}
                    alt="error"
                    className={`${Styles.add_subject}  ps-2`}
                    srcSet=""
                    width={32}
                  />
                  Add
                </button>
              </div>
              <hr />
              <div className="">
                <div className="form-group row mb-3">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      id="inputPassword"
                      placeholder="B.com"
                    />
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      id="inputPassword"
                      placeholder="B.com"
                    />
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      id="inputPassword"
                      placeholder="B.com"
                    />
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      id="inputPassword"
                      placeholder="B.com"
                    />
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      id="inputPassword"
                      placeholder="B.com"
                    />
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      id="inputPassword"
                      placeholder="B.com"
                    />
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      id="inputPassword"
                      placeholder="B.com"
                    />
                  </div>
                </div>

                <div className="form-group row mb-1">
                  <label for="inputPassword" className="col-form-label">
                    <b className="ms-2"></b>{" "}
                  </label>
                  <div className="col-sm-5  mt-5 d-flex">
                    <div className={`${Styles.submit_button} me-2`}>
                      <button>
                        Edit{" "}
                        <img
                          src={grp713}
                          alt="error"
                          className="ps-2"
                          srcSet=""
                        />
                      </button>
                    </div>
                    <div>
                      <button className={`${Styles.submit_profile_button}`}>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--------Profile Section End-------------> */}
          </div>
          <div className="container bg-white assignment-content">
            <div className="d-flex justify-content-between">
              <div className="pt-4 pe-2 mt-2">
                <h4>Tutor Exam Check</h4>
              </div>
              <button className={`${Styles.add_subject_button}  mt-4 me-3 mb-3`}>
                <img
                  src={icon}
                  alt="error"
                  className={`${Styles.add_subject}  ps-2`}
                  srcSet=""
                  width={32}
                />
                Add Subject
              </button>
            </div>
            <div className="mt-4">
              <Table bordered  id="table">
                <thead>
                  <tr>
                    <th>Exam Subject</th>
                    <th>Final Score </th>
                    <th>Attempt</th>
                    <th>Result</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Accounting</td>
                    <td>10</td>
                    <td>7</td>
                    <td>
                      <button className={`${Styles.pass}`}>Pass</button>
                    </td>
                    <td>
                      <button className={`${Styles.subject}`}>Approve</button>
                    </td>
                  </tr>
                  <tr>
                    <td>Operations Management</td>
                    <td>5</td>
                    <td>5</td>
                    <td>
                      <button className={`${Styles.fail}`}>Fail</button>
                    </td>
                    <td>
                      <button className={`${Styles.subject}`}>Approve</button>
                    </td>
                  </tr>
                  <tr>
                    <td>Economics</td>
                    <td>5</td>
                    <td>9</td>
                    <td>
                      <button className={`${Styles.pass}`}>Pass</button>
                    </td>
                    <td>
                      <button className={`${Styles.subject}`}>Approve</button>
                    </td>
                  </tr>
                  <tr>
                    <td>Statistics and Probability</td>
                    <td>9</td>
                    <td>8</td>
                    <td>
                      <button className={`${Styles.fail}`}>Fail</button>
                    </td>
                    <td>
                      <button className={`${Styles.subject}`}>Approve</button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            {/* <!--------------Pagging---------------> */}
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-end ">
                <li className="page-item">
                  <Link className="page-link" to="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only"></span>
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    1
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link  active" to="#">
                    2
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    3
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    4
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    5
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                    <span className="sr-only"></span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>

          {/* <!-------------------Suspension List Table Start---------------------> */}
          <div className="container bg-white assignment-content mt-4">
            <div className="d-flex justify-content-between">
              <div className="pt-4 pe-2 mt-2">
                <h4>Suspension List</h4>
              </div>
              <button className={`${Styles.add_subject_button}  mt-4 me-3 mb-3`}>
                <img
                  src={icon}
                  alt="error"
                  className={`${Styles.add_subject}  ps-2`}
                  srcSet=""
                  width={32}
                />
                Add Subject
              </button>
            </div>
            <div className="mt-4">
              <Table bordered  id="table">
                <thead>
                  <tr>
                    <th>Question ID</th>
                    <th>Days</th>
                    <th>Times</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>65956959</td>
                    <td>10</td>
                    <td>7</td>
                    <td>
                      <button className={`${Styles.subject}`}>Approve</button>
                    </td>
                  </tr>
                  <tr>
                    <td>65956959</td>
                    <td>5</td>
                    <td>5</td>
                    <td>
                      <button className={`${Styles.subject}`}>Approve</button>
                    </td>
                  </tr>
                  <tr>
                    <td>65956959</td>
                    <td>5</td>
                    <td>9</td>
                    <td>
                      <button className={`${Styles.subject}`}>Approve</button>
                    </td>
                  </tr>
                  <tr>
                    <td>65956959</td>
                    <td>9</td>
                    <td>8</td>
                    <td>
                      <button className={`${Styles.subject}`}>Approve</button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            {/* <!--------------Pagging---------------> */}
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-end ">
                <li className="page-item">
                  <Link className="page-link" to="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span className={`${Styles.sr_only}`}></span>
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    1
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link  active" to="#">
                    2
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    3
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    4
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    5
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                    <span className="sr-only"></span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {/* <!-------------------Transaction History Table Start---------------------> */}
        <div className="container bg-white assignment-content mt-4">
          <div className="d-flex justify-content-start">
            <div className="pt-4 pe-2 mt-2">
              <h4>Transaction History</h4>
            </div>
          </div>
          <div className="mt-4">
            <Table bordered  id="table">
              <thead>
                <tr>
                  <th>Date & Time</th>
                  <th>Order ID</th>
                  <th>Type</th>
                  <th>Earning</th>
                  <th>Payment</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>Essay Writing</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td className={`${Styles.text_danger}`}>$30</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>True / False</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td className={`${Styles.text_danger}`}>$30</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>1-on-1 Chat Session</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td className={`${Styles.text_danger}`}>$30</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>Assignment Writing</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td className={`${Styles.text_danger}`}>$30</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>Definition</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td className={`${Styles.text_danger}`}>$30</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>Article Writting</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td className={`${Styles.text_danger}`}>$30</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>Essay Writing</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td className={`${Styles.text_danger}`}>$30</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>1-on-1 Chat Session</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td className={`${Styles.text_danger}`}>$30</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          {/* <!--------------Pagging---------------> */}
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end ">
              <li className="page-item">
                <Link className="page-link" to="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only"></span>
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  1
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link  active" to="#">
                  2
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  3
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  4
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  5
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only"></span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>

        {/* <!-------------------Question SolvedTable Start---------------------> */}
        <div className="container bg-white assignment-content mt-4">
          <div className="d-flex justify-content-start">
            <div className="pt-4 pe-2 mt-2">
              <h4>Question Solved</h4>
            </div>
          </div>
          <div className="mt-4">
            <Table bordered  id="table">
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Date & Time</th>
                  <th>Order ID</th>
                  <th>Type</th>
                  <th>Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>Essay Writing</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>True / False</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>1-on-1 Chat Session</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>Assignment Writing</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>Definition</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>Article Writting</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>Essay Writing</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>1-on-1 Chat Session</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          {/* <!--------------Pagging---------------> */}
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end ">
              <li className="page-item">
                <Link className="page-link" to="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only"></span>
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  1
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link  active" to="#">
                  2
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  3
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  4
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  5
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only"></span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        {/* <!-------------------Assignment Solved Start---------------------> */}
        <div className="container bg-white assignment-content mt-4">
          <div className="d-flex justify-content-start">
            <div className="pt-4 pe-2 mt-2">
              <h4>Assignment Solved</h4>
            </div>
          </div>
          <div className="mt-4">
            <Table bordered  id="table">
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Date & Time</th>
                  <th>Order ID</th>
                  <th>Type</th>
                  <th>Timer</th>
                  <th>Deadline</th>
                  <th>Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>Essay Writing</td>
                  <td className="pb-5">
                    <div className="d-flex justify-content-around">
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">10</p>
                        <p className={`${Styles.timer}`}>Hrs</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">25</p>
                        <p className={`${Styles.timer}`}>Min</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">45</p>
                        <p className={`${Styles.timer}`}>Sec</p>
                      </div>
                    </div>
                  </td>
                  <td>15 Hrs</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>True / False</td>
                  <td className="pb-5">
                    <div className="d-flex justify-content-around">
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">10</p>
                        <p className={`${Styles.timer}`}>Hrs</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">25</p>
                        <p className={`${Styles.timer}`}>Min</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">45</p>
                        <p className={`${Styles.timer}`}>Sec</p>
                      </div>
                    </div>
                  </td>
                  <td>15 Hrs</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>1-on-1 Chat Session</td>
                  <td className="pb-5">
                    <div className="d-flex justify-content-around">
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">10</p>
                        <p className={`${Styles.timer}`}>Hrs</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">25</p>
                        <p className={`${Styles.timer}`}>Min</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">45</p>
                        <p className={`${Styles.timer}`}>Sec</p>
                      </div>
                    </div>
                  </td>
                  <td>15 Hrs</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>Assignment Writing</td>
                  <td className="pb-5">
                    <div className="d-flex justify-content-around">
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">10</p>
                        <p className={`${Styles.timer}`}>Hrs</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">25</p>
                        <p className={`${Styles.timer}`}>Min</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">45</p>
                        <p className={`${Styles.timer}`}>Sec</p>
                      </div>
                    </div>
                  </td>
                  <td>15 Hrs</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>Definition</td>
                  <td className="pb-5">
                    <div className="d-flex justify-content-around">
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">10</p>
                        <p className={`${Styles.timer}`}>Hrs</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">25</p>
                        <p className={`${Styles.timer}`}>Min</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">45</p>
                        <p className={`${Styles.timer}`}>Sec</p>
                      </div>
                    </div>
                  </td>
                  <td>15 Hrs</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>Article Writting</td>
                  <td className="pb-5">
                    <div className="d-flex justify-content-around">
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">10</p>
                        <p className={`${Styles.timer}`}>Hrs</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">25</p>
                        <p className={`${Styles.timer}`}>Min</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">45</p>
                        <p className={`${Styles.timer}`}>Sec</p>
                      </div>
                    </div>
                  </td>
                  <td>15 Hrs</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>Essay Writing</td>
                  <td className="pb-5">
                    <div className="d-flex justify-content-around">
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">10</p>
                        <p className={`${Styles.timer}`}>Hrs</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">25</p>
                        <p className={`${Styles.timer}`}>Min</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">45</p>
                        <p className={`${Styles.timer}`}>Sec</p>
                      </div>
                    </div>
                  </td>
                  <td>15 Hrs</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>1-on-1 Chat Session</td>
                  <td className="pb-5">
                    <div className="d-flex justify-content-around">
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">10</p>
                        <p className={`${Styles.timer}`}>Hrs</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">25</p>
                        <p className={`${Styles.timer}`}>Min</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">45</p>
                        <p className={`${Styles.timer}`}>Sec</p>
                      </div>
                    </div>
                  </td>
                  <td>15 Hrs</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          {/* <!--------------Pagging---------------> */}
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end ">
              <li className="page-item">
                <Link className="page-link" to="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only"></span>
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  1
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link  active" to="#">
                  2
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  3
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  4
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  5
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only"></span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        {/* <!-------------------Assignment Solved Start---------------------> */}
        <div className="container bg-white assignment-content mt-4">
          <div className="d-flex justify-content-start">
            <div className="pt-4 pe-2 mt-2">
              <h4>Assignment Solved</h4>
            </div>
          </div>
          <div className="mt-4">
            <Table bordered  id="table">
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Date & Time</th>
                  <th>Order ID</th>
                  <th>Type</th>
                  <th>Timer</th>
                  <th>Deadline</th>
                  <th>Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>Essay Writing</td>
                  <td className="pb-5">
                    <div className="d-flex justify-content-around">
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">10</p>
                        <p className={`${Styles.timer}`}>Hrs</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">25</p>
                        <p className={`${Styles.timer}`}>Min</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">45</p>
                        <p className={`${Styles.timer}`}>Sec</p>
                      </div>
                    </div>
                  </td>
                  <td>15 Hrs</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>True / False</td>
                  <td className="pb-5">
                    <div className="d-flex justify-content-around">
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">10</p>
                        <p className={`${Styles.timer}`}>Hrs</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">25</p>
                        <p className={`${Styles.timer}`}>Min</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">45</p>
                        <p className={`${Styles.timer}`}>Sec</p>
                      </div>
                    </div>
                  </td>
                  <td>15 Hrs</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>1-on-1 Chat Session</td>
                  <td className="pb-5">
                    <div className="d-flex justify-content-around">
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">10</p>
                        <p className={`${Styles.timer}`}>Hrs</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">25</p>
                        <p className={`${Styles.timer}`}>Min</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">45</p>
                        <p className={`${Styles.timer}`}>Sec</p>
                      </div>
                    </div>
                  </td>
                  <td>15 Hrs</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>Assignment Writing</td>
                  <td className="pb-5">
                    <div className="d-flex justify-content-around">
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">10</p>
                        <p className={`${Styles.timer}`}>Hrs</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">25</p>
                        <p className={`${Styles.timer}`}>Min</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">45</p>
                        <p className={`${Styles.timer}`}>Sec</p>
                      </div>
                    </div>
                  </td>
                  <td>15 Hrs</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>Definition</td>
                  <td className="pb-5">
                    <div className="d-flex justify-content-around">
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">10</p>
                        <p className={`${Styles.timer}`}>Hrs</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">25</p>
                        <p className={`${Styles.timer}`}>Min</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">45</p>
                        <p className={`${Styles.timer}`}>Sec</p>
                      </div>
                    </div>
                  </td>
                  <td>15 Hrs</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>Article Writting</td>
                  <td className="pb-5">
                    <div className="d-flex justify-content-around">
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">10</p>
                        <p className={`${Styles.timer}`}>Hrs</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">25</p>
                        <p className={`${Styles.timer}`}>Min</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">45</p>
                        <p className={`${Styles.timer}`}>Sec</p>
                      </div>
                    </div>
                  </td>
                  <td>15 Hrs</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>Essay Writing</td>
                  <td className="pb-5">
                    <div className="d-flex justify-content-around">
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">10</p>
                        <p className={`${Styles.timer}`}>Hrs</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">25</p>
                        <p className={`${Styles.timer}`}>Min</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">45</p>
                        <p className={`${Styles.timer}`}>Sec</p>
                      </div>
                    </div>
                  </td>
                  <td>15 Hrs</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>1-on-1 Chat Session</td>
                  <td className="pb-5">
                    <div className="d-flex justify-content-around">
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">10</p>
                        <p className={`${Styles.timer}`}>Hrs</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">25</p>
                        <p className={`${Styles.timer}`}>Min</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">45</p>
                        <p className={`${Styles.timer}`}>Sec</p>
                      </div>
                    </div>
                  </td>
                  <td>15 Hrs</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          {/* <!--------------Pagging---------------> */}
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end ">
              <li className="page-item">
                <Link className="page-link" to="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only"></span>
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  1
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link  active" to="#">
                  2
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  3
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  4
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  5
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only"></span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        {/* <!-------------------Live Session Solved Start---------------------> */}
        <div className="container bg-white assignment-content mt-4">
          <div className="d-flex justify-content-start">
            <div className="pt-4 pe-2 mt-2">
              <h4>Live Session Solved</h4>
            </div>
          </div>
          <div className="mt-4">
            <Table bordered  id="table">
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Date & Time</th>
                  <th>Order ID</th>
                  <th>Type</th>
                  <th>Timer</th>
                  <th>Deadline</th>
                  <th>Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>Essay Writing</td>
                  <td className="pb-5">
                    <div className="d-flex justify-content-around">
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">10</p>
                        <p className={`${Styles.timer}`}>Hrs</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">25</p>
                        <p className={`${Styles.timer}`}>Min</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">45</p>
                        <p className={`${Styles.timer}`}>Sec</p>
                      </div>
                    </div>
                  </td>
                  <td>15 Hrs</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>True / False</td>
                  <td className="pb-5">
                    <div className="d-flex justify-content-around">
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">10</p>
                        <p className={`${Styles.timer}`}>Hrs</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">25</p>
                        <p className={`${Styles.timer}`}>Min</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">45</p>
                        <p className={`${Styles.timer}`}>Sec</p>
                      </div>
                    </div>
                  </td>
                  <td>15 Hrs</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>1-on-1 Chat Session</td>
                  <td className="pb-5">
                    <div className="d-flex justify-content-around">
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">10</p>
                        <p className={`${Styles.timer}`}>Hrs</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">25</p>
                        <p className={`${Styles.timer}`}>Min</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">45</p>
                        <p className={`${Styles.timer}`}>Sec</p>
                      </div>
                    </div>
                  </td>
                  <td>15 Hrs</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>Assignment Writing</td>
                  <td className="pb-5">
                    <div className="d-flex justify-content-around">
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">10</p>
                        <p className={`${Styles.timer}`}>Hrs</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">25</p>
                        <p className={`${Styles.timer}`}>Min</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">45</p>
                        <p className={`${Styles.timer}`}>Sec</p>
                      </div>
                    </div>
                  </td>
                  <td>15 Hrs</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>Definition</td>
                  <td className="pb-5">
                    <div className="d-flex justify-content-around">
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">10</p>
                        <p className={`${Styles.timer}`}>Hrs</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">25</p>
                        <p className={`${Styles.timer}`}>Min</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">45</p>
                        <p className={`${Styles.timer}`}>Sec</p>
                      </div>
                    </div>
                  </td>
                  <td>15 Hrs</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>Article Writting</td>
                  <td className="pb-5">
                    <div className="d-flex justify-content-around">
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">10</p>
                        <p className={`${Styles.timer}`}>Hrs</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">25</p>
                        <p className={`${Styles.timer}`}>Min</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">45</p>
                        <p className={`${Styles.timer}`}>Sec</p>
                      </div>
                    </div>
                  </td>
                  <td>15 Hrs</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>Essay Writing</td>
                  <td className="pb-5">
                    <div className="d-flex justify-content-around">
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">10</p>
                        <p className={`${Styles.timer}`}>Hrs</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">25</p>
                        <p className={`${Styles.timer}`}>Min</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">45</p>
                        <p className={`${Styles.timer}`}>Sec</p>
                      </div>
                    </div>
                  </td>
                  <td>15 Hrs</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>3 March 2024 | 7:56 AM</td>
                  <td>265988</td>
                  <td>1-on-1 Chat Session</td>
                  <td className="pb-5">
                    <div className="d-flex justify-content-around">
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">10</p>
                        <p className={`${Styles.timer}`}>Hrs</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">25</p>
                        <p className={`${Styles.timer}`}>Min</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">45</p>
                        <p className={`${Styles.timer}`}>Sec</p>
                      </div>
                    </div>
                  </td>
                  <td>15 Hrs</td>
                  <td className={`${Styles.text_success}`}>$50</td>
                  <td>
                    <button className={`${Styles.subject}`}>View</button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          {/* <!--------------Pagging---------------> */}
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end ">
              <li className="page-item">
                <Link className="page-link" to="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only"></span>
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  1
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link  active" to="#">
                  2
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  3
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  4
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  5
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only"></span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Tutor_profile