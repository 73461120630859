import React from 'react';
import { Button, Card, CardBody, Col, Input, InputGroup, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap';
import { FaSearch } from 'react-icons/fa';
import whatsApp from './assets/images/WhatsApp-Logo-Icon.png'

const Tutor_list = () => {

    return (
        <div>
            <div className="mt-4 mb-4">
                <div className='row'>
                    <div className='col-sm-6'>
                        <h4 className="page-title">Tutors List</h4>
                    </div>
                    <div className='col-sm-6 text-end'>
                        <Button > Working </Button>
                        <Button className='mx-2'> Suspended </Button>
                        <Button className='mx-2'>  Unverified </Button>
                    </div>
                </div>
                <Card className='mt-4'>
                    <CardBody>
                        <Row>
                            <Col md="8">
                                <InputGroup>
                                    <Input
                                        type="text"
                                        id="fname"
                                        placeholder="Order ID"
                                        name="fname"
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text wallte">
                                            <FaSearch />
                                        </span>
                                    </div>
                                </InputGroup>
                            </Col>
                            <Col md="4">
                                <Input
                                    type="text"
                                    id="inputDate"
                                    placeholder='Subject'
                                />
                            </Col>
                        </Row>

                        <Table bordered className="mt-4">
                            <thead>
                                <tr>
                                    <th className='text-center'>Name</th>
                                    <th className='text-center'>Whatsapp No</th>
                                    <th className='text-center'>Question Solved</th>
                                    <th className='text-center'>Assignment Solved</th>
                                    <th className='text-center'>Live Session Attended</th>
                                    <th className='text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='text-center'>Kaitik jain</td>
                                    <td className='text-center'><img src={whatsApp} alt='whatsApp' /><span className='mx-2'>+91 93747 44365</span></td>
                                    <td className='text-center'>2</td>
                                    <td className='text-center'>2</td>
                                    <td className='text-center'>2</td>
                                    <td className='text-center'>
                                        <Button className="action-button">
                                            View
                                        </Button>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <div className="float-end">
                            <Pagination>
                                <PaginationItem>
                                    <PaginationLink
                                        first
                                        href="#"
                                    />
                                </PaginationItem>
                                <PaginationItem>
                                    <PaginationLink
                                        href="#"
                                        previous
                                    />
                                </PaginationItem>
                                <PaginationItem>
                                    <PaginationLink href="#">
                                        1
                                    </PaginationLink>
                                </PaginationItem>
                                <PaginationItem>
                                    <PaginationLink href="#">
                                        2
                                    </PaginationLink>
                                </PaginationItem>
                                <PaginationItem>
                                    <PaginationLink href="#">
                                        3
                                    </PaginationLink>
                                </PaginationItem>
                                <PaginationItem>
                                    <PaginationLink href="#">
                                        4
                                    </PaginationLink>
                                </PaginationItem>
                                <PaginationItem>
                                    <PaginationLink href="#">
                                        5
                                    </PaginationLink>
                                </PaginationItem>
                                <PaginationItem>
                                    <PaginationLink
                                        href="#"
                                        next
                                    />
                                </PaginationItem>
                                <PaginationItem>
                                    <PaginationLink
                                        href="#"
                                        last
                                    />
                                </PaginationItem>
                            </Pagination>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}

export default Tutor_list;
