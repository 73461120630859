import React, { useState } from 'react'
import { Button, Card, CardBody, Col, FormFeedback, Input, Label, Row } from 'reactstrap'
import Styles from '../homework/assets/Form.module.css'
import { toast } from 'react-toastify'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../../axiosInterceptor'

const AddLiveSessionPrice = () => {
    const location = useLocation();
    const liveSessionPriceData = location.state;
    const [data, setData] = useState(liveSessionPriceData || { fromMinute: "", toMinute: "", studentPrice: "" })
    const [formSubmitted, setFormSubmitted] = useState(false)
    const navigator = useNavigate();
    const [error, setError] = useState("")
    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormSubmitted(true)
        if (!data?.fromMinute | !data?.toMinute | !data?.studentPrice) {
            return;
        }
        if (data.fromMinute > data.toMinute) {
            return setError(" The 'from' minute cannot be greater than the 'to' minute.")
        }
        try {
            const response = await axiosInstance.post(`admin/liveSessionPrice/addPrice`, data);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/live-session-price");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.put(`admin/liveSessionPrice/updatePrice/${liveSessionPriceData?._id}`, { fromMinute: data.fromMinute, toMinute: data.toMinute, studentPrice: data.studentPrice });
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/live-session-price");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    return (
        <div>
            <div className="mt-4 mb-4">
                <div className='row'>
                    <div className='col-sm-6'>
                        <h4 className="page-title">Add Live Session Price</h4>
                    </div>
                </div>
                <Card className='mt-4'>
                    <CardBody>
                        <Row>
                            <Col md="8">
                                <form className="ps-5 pe-5 pt-3" onSubmit={liveSessionPriceData?._id ? handleUpdate : handleSubmit}>
                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            From Minutes
                                        </Label>
                                        <div className="col-sm-7">
                                            <Input
                                                type="number"
                                                className="form-control"
                                                id="inputPassword"
                                                placeholder="Enter from minutes"
                                                invalid={!liveSessionPriceData && !data?.fromMinute && formSubmitted}
                                                defaultValue={data?.fromMinute}
                                                onChange={(e) => setData({ ...data, fromMinute: e.target.value })}
                                            />
                                            <FormFeedback>From minutes are required</FormFeedback>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            To Minutes
                                        </Label>
                                        <div className="col-sm-7">
                                            <Input
                                                type="number"
                                                className="form-control"
                                                id="inputPassword"
                                                placeholder="Enter to minutes"
                                                invalid={!liveSessionPriceData && !data?.toMinute && formSubmitted}
                                                defaultValue={data?.toMinute}
                                                onChange={(e) => setData({ ...data, toMinute: e.target.value })}
                                            />
                                            <FormFeedback>To minutes are required</FormFeedback>
                                            {error && (
                                                <div className="text-danger">{error}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Student Price
                                        </Label>
                                        <div className="col-sm-7">
                                            <div className="input-group from-control">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">$</span>
                                                </div>
                                                <Input
                                                    type="number"
                                                    className="form-control "
                                                    id="inputPassword"
                                                    placeholder="Enter student price"
                                                    invalid={!liveSessionPriceData && !data?.studentPrice && formSubmitted}
                                                    value={data?.studentPrice}
                                                    onChange={(e) => setData({ ...data, studentPrice: e.target.value })}
                                                />
                                                <FormFeedback>Price is required</FormFeedback>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            <b className="ms-2"></b>
                                        </Label>
                                        <div className="col-sm-7 d-flex">
                                            <div className={`${Styles.submit_button}`}>
                                                <Button type='submit'>{liveSessionPriceData?._id ? "Update" : "Save"}</Button>
                                            </div>
                                            <div className={`${Styles.cancel_button} me-4 mx-2`}>
                                                <Link to='/live-session-price'>
                                                    <Button type='button' className={`${Styles.cancel_button}`}>Cancel</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default AddLiveSessionPrice