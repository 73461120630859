import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import axiosInstance from '../axiosInterceptor';
import './dashboard.scss'
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {

    const [countingData, setCountingData] = useState(null);
    const [loading, setLoading] = useState(true);

    const navigator = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('admin/dashboard/counting');
                if (response.data.isSuccess) {
                    setCountingData(response.data);
                } else {
                    console.error('Failed to fetch data:', response.data.message);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const [registrationData, setRegistrationData] = useState(null);

    useEffect(() => {
        async function fetchRegistrationData() {
            try {
                const response = await axiosInstance.get('admin/dashboard/countRegistration');
                if (response.data.isSuccess) {
                    setRegistrationData(response.data);
                } else {
                    console.error('Failed to fetch registration data:', response.data.message);
                }
            } catch (error) {
                console.error('Error fetching registration data:', error);
            }
        }

        fetchRegistrationData();
    }, []);

    const [questionaskData, setQuestionaskData] = useState(null);

    useEffect(() => {
        async function fetchQuestionData() {
            try {
                const response = await axiosInstance.get('admin/dashboard/questionAsked');
                if (response.data.isSuccess) {
                    setQuestionaskData(response.data);
                } else {
                    console.error('Failed to fetch registration data:', response.data.message);
                }
            } catch (error) {
                console.error('Error fetching registration data:', error);
            }
        }

        fetchQuestionData();
    }, []);

    const [unsolvedqueData, setUnsolvedquekData] = useState(null);

    useEffect(() => {
        async function fetchUnsolvedqueData() {
            try {
                const response = await axiosInstance.get('admin/dashboard/unsolvedQuestion');
                if (response.data.isSuccess) {
                    setUnsolvedquekData(response.data);
                } else {
                    console.error('Failed to fetch registration data:', response.data.message);
                }
            } catch (error) {
                console.error('Error fetching registration data:', error);
            }
        }

        fetchUnsolvedqueData();
    }, []);

    const [anserqueData, setAnserquekData] = useState(null);

    useEffect(() => {
        async function fetchUnsolvedqueData() {
            try {
                const response = await axiosInstance.get('admin/dashboard/answerQuestion');
                if (response.data.isSuccess) {
                    setAnserquekData(response.data);
                } else {
                    console.error('Failed to fetch registration data:', response.data.message);
                }
            } catch (error) {
                console.error('Error fetching registration data:', error);
            }
        }

        fetchUnsolvedqueData();
    }, []);

    return (

        <div className="mt-4 mb-4">
            {/* <h5 className="page-title">Dashboard</h5> */}
            {loading ? (
                <div className="loader">Loading...</div>
            ) : (
                <>
                    <div className='dashboard-page'>
                        <Row>
                            <Col md="3">
                                <Card>
                                    <CardBody>
                                        <p className='dashboard-title'>
                                            Orders:
                                            <span className='mx-2' style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/orders') }}>
                                                {countingData?.totalOrderPaid} / ${countingData?.totalPaidSum}
                                            </span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="3">
                                <Card>
                                    <CardBody>
                                        <p className='dashboard-title'>
                                            Unpaid:
                                            <span className='mx-2' style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/orders') }}>
                                                {countingData?.totalOrderUnpaid} / ${countingData?.totalUnpaidSum}
                                            </span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="3">
                                <Card>
                                    <CardBody>
                                        <p className='dashboard-title'>
                                            Refunded:
                                            <span className='mx-2' style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/orders') }}>
                                                {countingData?.totalOrderRefund} / ${countingData?.totalRefundSum}
                                            </span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="3">
                                <Card>
                                    <CardBody>
                                        <p className='dashboard-title'>
                                            Tutor:
                                            <span className='mx-2' style={{ color: '#E541ED ' }}>
                                                {countingData?.totalOrderTutorPaid} / {countingData?.totalTutorPaidSum}
                                            </span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className='count-registration mt-4'>
                        <Row>
                            <Col md="6">
                                <Card>
                                    <CardBody>
                                        <div className="card-title">
                                            <div className="mt-2 font-weight-bold">Student Registration</div>
                                        </div>
                                        <div className="template-demo">
                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="pl-0">Today</th>
                                                        <th className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }}>
                                                            {registrationData && registrationData.studentRegistration.today}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="pl-0">Last 7 Days</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ' }}>
                                                            {registrationData && registrationData.studentRegistration.last7Days}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0">Last 30 Days</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ' }}>
                                                            {registrationData && registrationData.studentRegistration.last30Days}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6">
                                <Card>
                                    <CardBody>
                                        <div className="card-title">
                                            <div className="mt-2 font-weight-bold">Tutor Registration</div>
                                        </div>
                                        <div className="template-demo">
                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="pl-0">Today</th>
                                                        <th className="text-right font-weight-bold" style={{ color: '#E541ED ' }}>
                                                            {registrationData && registrationData.tutorRegistration.today}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="pl-0">Last 7 Days</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ' }}>
                                                            {registrationData && registrationData.tutorRegistration.last7Days}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0">Last 30 Days</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ' }}>
                                                            {registrationData && registrationData.tutorRegistration.last30Days}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className='question-ask mt-4'>
                        <h5 className="page-title">Question Ask</h5>
                        <Row>
                            <Col md="4">
                                <Card>
                                    <CardBody>
                                        <div className="card-title">
                                            <div className="mt-2 font-weight-bold">Homework Help</div>
                                        </div>
                                        <div className="template-demo">
                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="pl-0">Today</th>
                                                        <th className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/homework-list') }}>
                                                            {questionaskData && questionaskData.questionsAskedForHomeworkHelp.today}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="pl-0">Last 7 Days</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/homework-list') }}>
                                                            {questionaskData && questionaskData.questionsAskedForHomeworkHelp.last7Days}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0">Last 30 Days</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/homework-list') }}>
                                                            {questionaskData && questionaskData.questionsAskedForHomeworkHelp.last30Days}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0">Life Time</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/homework-list') }}>
                                                            {questionaskData && questionaskData.questionsAskedForHomeworkHelp.lifeTime}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card>
                                    <CardBody>
                                        <div className="card-title">
                                            <div className="mt-2 font-weight-bold">Assignment Help</div>
                                        </div>
                                        <div className="template-demo">
                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="pl-0">Today</th>
                                                        <th className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/assignment-list') }}>
                                                            {questionaskData && questionaskData.questionsAskedForAssignmentHelp.today}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="pl-0">Last 7 Days</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/assignment-list') }}>
                                                            {questionaskData && questionaskData.questionsAskedForAssignmentHelp.last7Days}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0">Last 30 Days</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/assignment-list') }}>
                                                            {questionaskData && questionaskData.questionsAskedForAssignmentHelp.last30Days}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0">Life Time</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/assignment-list') }}>
                                                            {questionaskData && questionaskData.questionsAskedForAssignmentHelp.lifeTime}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card>
                                    <CardBody>
                                        <div className="card-title">
                                            <div className="mt-2 font-weight-bold">Live Session Help</div>
                                        </div>
                                        <div className="template-demo">
                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="pl-0">Today</th>
                                                        <th className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/live-session-list') }}>
                                                            {questionaskData && questionaskData.questionsAskedForLiveSessionHelp.today}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="pl-0">Last 7 Days</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/live-session-list') }}>
                                                            {questionaskData && questionaskData.questionsAskedForLiveSessionHelp.last7Days}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0">Last 30 Days</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/live-session-list') }}>
                                                            {questionaskData && questionaskData.questionsAskedForLiveSessionHelp.last30Days}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0">Life Time</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/live-session-list') }}>
                                                            {questionaskData && questionaskData.questionsAskedForLiveSessionHelp.lifeTime}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className='question-subCategory mt-4'>
                        <h5 className="page-title">Category Wise Questions</h5>
                        <Row>
                            <Col md="4">
                                <Card>
                                    <CardBody>
                                        <div className="card-title">
                                            <div className="mt-2 font-weight-bold">Homework Help</div>
                                        </div>
                                        <div className="template-demo">
                                            <table className="table mb-0">
                                                <thead>
                                                    {/* <tr>
                                                <th className="pl-0">Sub Category</th>
                                                <th/>
                                            </tr> */}
                                                </thead>
                                                <tbody>
                                                    {questionaskData && questionaskData.subCategoryWiseQuestionsAskedForHomeworkHelp?.map(subCategory => (
                                                        <tr key={subCategory._id}>
                                                            <td className="pl-0">{subCategory.subCategoryName}</td>
                                                            <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/homework-list') }}>{subCategory.totalOrder}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card>
                                    <CardBody>
                                        <div className="card-title">
                                            <div className="mt-2 font-weight-bold">Assignment Help</div>
                                        </div>
                                        <div className="template-demo">
                                            <table className="table mb-0">
                                                <thead>
                                                    {/* <tr>
                                                <th className="pl-0">Sub Category</th>
                                                <th/>
                                            </tr> */}
                                                </thead>
                                                <tbody>
                                                    {questionaskData && questionaskData.subCategoryWiseQuestionsAskedForAssignmentHelp?.map(subCategory => (
                                                        <tr key={subCategory._id}>
                                                            <td className="pl-0">{subCategory.subCategoryName}</td>
                                                            <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/assignment-list') }}>{subCategory.totalOrder}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card>
                                    <CardBody>
                                        <div className="card-title">
                                            <div className="mt-2 font-weight-bold">Live Session Help</div>
                                        </div>
                                        <div className="template-demo">
                                            <table className="table mb-0">
                                                <thead>
                                                    {/* <tr>
                                                <th className="pl-0">Sub Category</th>
                                                <th/>
                                            </tr> */}
                                                </thead>
                                                <tbody>
                                                    {questionaskData && questionaskData.subCategoryWiseQuestionsAskedForLiveSessionHelp?.map(subCategory => (
                                                        <tr key={subCategory._id}>
                                                            <td className="pl-0">{subCategory.subCategoryName}</td>
                                                            <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/live-session-list') }}>{subCategory.totalOrder}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className='questionunsolved-ask mt-4'>
                        <h5 className="page-title">Unsolved Question</h5>
                        <Row>
                            <Col md="4">
                                <Card>
                                    <CardBody>
                                        <div className="card-title">
                                            <div className="mt-2 font-weight-bold">Homework Help</div>
                                        </div>
                                        <div className="template-demo">
                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="pl-0">Today</th>
                                                        <th className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/homework-list') }}>
                                                            {unsolvedqueData && unsolvedqueData.unsolvedQuestionForHomeworkHelp?.today}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="pl-0">Last 7 Days</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/homework-list') }}>
                                                            {unsolvedqueData && unsolvedqueData.unsolvedQuestionForHomeworkHelp?.last7Days}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0">Last 30 Days</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/homework-list') }}>
                                                            {unsolvedqueData && unsolvedqueData.unsolvedQuestionForHomeworkHelp?.last30Days}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0">Life Time</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/homework-list') }}>
                                                            {unsolvedqueData && unsolvedqueData.unsolvedQuestionForHomeworkHelp?.lifeTime}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card>
                                    <CardBody>
                                        <div className="card-title">
                                            <div className="mt-2 font-weight-bold">Assignment Help</div>
                                        </div>
                                        <div className="template-demo">
                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="pl-0">Today</th>
                                                        <th className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/assignment-list') }}>
                                                            {unsolvedqueData && unsolvedqueData.unsolvedQuestionForAssignmentHelp?.today}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="pl-0">Last 7 Days</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/assignment-list') }}>
                                                            {unsolvedqueData && unsolvedqueData.unsolvedQuestionForAssignmentHelp?.last7Days}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0">Last 30 Days</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/assignment-list') }}>
                                                            {unsolvedqueData && unsolvedqueData.unsolvedQuestionForAssignmentHelp?.last30Days}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0">Life Time</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/assignment-list') }}>
                                                            {unsolvedqueData && unsolvedqueData.unsolvedQuestionForAssignmentHelp?.lifeTime}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card>
                                    <CardBody>
                                        <div className="card-title">
                                            <div className="mt-2 font-weight-bold">Live Session Help</div>
                                        </div>
                                        <div className="template-demo">
                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="pl-0">Today</th>
                                                        <th className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/live-session-list') }}>
                                                            {unsolvedqueData && unsolvedqueData.unsolvedQuestionForLiveSessionHelp?.today}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="pl-0">Last 7 Days</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/live-session-list') }}>
                                                            {unsolvedqueData && unsolvedqueData.unsolvedQuestionForLiveSessionHelp?.last7Days}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0">Last 30 Days</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/live-session-list') }}>
                                                            {unsolvedqueData && unsolvedqueData.unsolvedQuestionForLiveSessionHelp?.last30Days}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0">Life Time</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/live-session-list') }}>
                                                            {unsolvedqueData && unsolvedqueData.unsolvedQuestionForLiveSessionHelp?.lifeTime}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className='questionunsolved-subCategory mt-4'>
                        <h5 className="page-title">Category Wise Unsolved Questions</h5>
                        <Row>
                            <Col md="4">
                                <Card>
                                    <CardBody>
                                        <div className="card-title">
                                            <div className="mt-2 font-weight-bold">Homework Help</div>
                                        </div>
                                        <div className="template-demo">
                                            <table className="table mb-0">
                                                <thead>
                                                    {/* <tr>
                                                <th className="pl-0">Sub Category</th>
                                                <th/>
                                            </tr> */}
                                                </thead>
                                                <tbody>
                                                    {unsolvedqueData && unsolvedqueData.subCategoryWiseUnsolvedQuestionsAskedForHomeworkHelp?.map(subCategory => (
                                                        <tr key={subCategory._id}>
                                                            <td className="pl-0">{subCategory.subCategoryName}</td>
                                                            <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/homework-list') }}>{subCategory.totalOrder}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card>
                                    <CardBody>
                                        <div className="card-title">
                                            <div className="mt-2 font-weight-bold">Assignment Help</div>
                                        </div>
                                        <div className="template-demo">
                                            <table className="table mb-0">
                                                <thead>
                                                    {/* <tr>
                                                <th className="pl-0">Sub Category</th>
                                                <th/>
                                            </tr> */}
                                                </thead>
                                                <tbody>
                                                    {unsolvedqueData && unsolvedqueData.subCategoryWiseUnsolvedQuestionsAskedForAssignmentHelp?.map(subCategory => (
                                                        <tr key={subCategory._id}>
                                                            <td className="pl-0">{subCategory.subCategoryName}</td>
                                                            <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/assignment-list') }}>{subCategory.totalOrder}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card>
                                    <CardBody>
                                        <div className="card-title">
                                            <div className="mt-2 font-weight-bold">Live Session Help</div>
                                        </div>
                                        <div className="template-demo">
                                            <table className="table mb-0">
                                                <thead>
                                                    {/* <tr>
                                                <th className="pl-0">Sub Category</th>
                                                <th/>
                                            </tr> */}
                                                </thead>
                                                <tbody>
                                                    {unsolvedqueData && unsolvedqueData.subCategoryWiseUnsolvedQuestionsAskedForLiveSessionHelp?.map(subCategory => (
                                                        <tr key={subCategory._id}>
                                                            <td className="pl-0">{subCategory.subCategoryName}</td>
                                                            <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/live-session-list') }}>{subCategory.totalOrder}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className='answer-ask mt-4'>
                        <h5 className="page-title">Answer Question</h5>
                        <Row>
                            <Col md="4">
                                <Card>
                                    <CardBody>
                                        <div className="card-title">
                                            <div className="mt-2 font-weight-bold">Homework Help</div>
                                        </div>
                                        <div className="template-demo">
                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="pl-0">Today</th>
                                                        <th className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/homework-list') }}>
                                                            {anserqueData && anserqueData.answerQuestionForHomeworkHelp?.today}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="pl-0">Last 7 Days</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/homework-list') }}>
                                                            {anserqueData && anserqueData.answerQuestionForHomeworkHelp?.last7Days}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0">Last 30 Days</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/homework-list') }}>
                                                            {anserqueData && anserqueData.answerQuestionForHomeworkHelp?.last30Days}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0">Life Time</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/homework-list') }}>
                                                            {anserqueData && anserqueData.answerQuestionForHomeworkHelp?.lifeTime}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card>
                                    <CardBody>
                                        <div className="card-title">
                                            <div className="mt-2 font-weight-bold">Assignment Help</div>
                                        </div>
                                        <div className="template-demo">
                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="pl-0">Today</th>
                                                        <th className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/assignment-list') }}>
                                                            {anserqueData && anserqueData.answerQuestionForAssignmentHelp?.today}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="pl-0">Last 7 Days</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/assignment-list') }}>
                                                            {anserqueData && anserqueData.answerQuestionForAssignmentHelp?.last7Days}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0">Last 30 Days</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/assignment-list') }}>
                                                            {anserqueData && anserqueData.answerQuestionForAssignmentHelp?.last30Days}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0">Life Time</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/assignment-list') }}>
                                                            {anserqueData && anserqueData.answerQuestionForAssignmentHelp?.lifeTime}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card>
                                    <CardBody>
                                        <div className="card-title">
                                            <div className="mt-2 font-weight-bold">Live Session Help</div>
                                        </div>
                                        <div className="template-demo">
                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="pl-0">Today</th>
                                                        <th className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/live-session-listssssss') }}>
                                                            {anserqueData && anserqueData.answerQuestionForLiveSessionHelp?.today}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="pl-0">Last 7 Days</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/live-session-listssssss') }}>
                                                            {anserqueData && anserqueData.answerQuestionForLiveSessionHelp?.last7Days}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0">Last 30 Days</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/live-session-listssssss') }}>
                                                            {anserqueData && anserqueData.answerQuestionForLiveSessionHelp?.last30Days}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pl-0">Life Time</td>
                                                        <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/live-session-listssssss') }}>
                                                            {anserqueData && anserqueData.answerQuestionForLiveSessionHelp?.lifeTime}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className='answer-subCategory mt-4'>
                        <h5 className="page-title">Category Wise Answer Questions</h5>
                        <Row>
                            <Col md="4">
                                <Card>
                                    <CardBody>
                                        <div className="card-title">
                                            <div className="mt-2 font-weight-bold">Homework Help</div>
                                        </div>
                                        <div className="template-demo">
                                            <table className="table mb-0">
                                                <thead>
                                                    {/* <tr>
                                                <th className="pl-0">Sub Category</th>
                                                <th/>
                                            </tr> */}
                                                </thead>
                                                <tbody>
                                                    {anserqueData && anserqueData.subCategoryWiseSolvedQuestionsAskedForHomeworkHelp?.map(subCategory => (
                                                        <tr key={subCategory._id}>
                                                            <td className="pl-0">{subCategory.subCategoryName}</td>
                                                            <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/homework-list') }}>{subCategory.totalOrder}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card>
                                    <CardBody>
                                        <div className="card-title">
                                            <div className="mt-2 font-weight-bold">Assignment Help</div>
                                        </div>
                                        <div className="template-demo">
                                            <table className="table mb-0">
                                                <thead>
                                                    {/* <tr>
                                                <th className="pl-0">Sub Category</th>
                                                <th/>
                                            </tr> */}
                                                </thead>
                                                <tbody>
                                                    {anserqueData && anserqueData.subCategoryWiseSolvedQuestionsAskedForAssignmentHelp?.map(subCategory => (
                                                        <tr key={subCategory._id}>
                                                            <td className="pl-0">{subCategory.subCategoryName}</td>
                                                            <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/assignment-list') }}>{subCategory.totalOrder}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card>
                                    <CardBody>
                                        <div className="card-title">
                                            <div className="mt-2 font-weight-bold">Live Session Help</div>
                                        </div>
                                        <div className="template-demo">
                                            <table className="table mb-0">
                                                <thead>
                                                    {/* <tr>
                                                <th className="pl-0">Sub Category</th>
                                                <th/>
                                            </tr> */}
                                                </thead>
                                                <tbody>
                                                    {anserqueData && anserqueData.subCategoryWiseSolvedQuestionsAskedForLiveSessionHelp?.map(subCategory => (
                                                        <tr key={subCategory._id}>
                                                            <td className="pl-0">{subCategory.subCategoryName}</td>
                                                            <td className="text-right font-weight-bold" style={{ color: '#E541ED ', cursor: 'pointer' }} onClick={() => { navigator('/live-session-list') }}>{subCategory.totalOrder}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </>
            )}
        </div>
    )
}

export default Dashboard