import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap'
import Styles from '../homework/assets/Form.module.css'
import { toast } from 'react-toastify'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../../axiosInterceptor'

const AddAssignmentPrice = () => {
    const location = useLocation();
    const editData = location.state;
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [categoryData, setCategoryData] = useState([]);
    const [subCategory, setSubcategory] = useState([]);
    const navigator = useNavigate();


    const [data, setData] = useState({
        categoryId: editData?.categoryId._id,
        subCategoryId: editData?.subCategoryId._id || '',
        minWords: editData?.minWords || '',
        maxWords: editData?.maxWords || '',
        hours: editData?.hours || '',
        assignmentPrice: editData?.assignmentPrice || '',
    })

    const handleChange = async (e) => {
        const { name, value } = e.target;
        if (name === 'categoryId') {
            setData({ ...data, [name]: value, subCategoryId: '' });
            if (value) {
                try {
                    const response = await axiosInstance.get(`admin/subcategory/getCategoryWiseSubcategory/${value}`);
                    if (response.data.isSuccess) {
                        setSubcategory(response.data.subCategory);
                    } else {
                        toast.error(response.data.message);
                    }
                } catch (error) {
                    toast.error('Something went wrong. Please try again!!');
                }
            }
        } else {
            setData({ ...data, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormSubmitted(true)
        if (!data.categoryId || !data.subCategoryId || !data.minWords || !data.maxWords || !data.hours || !data.assignmentPrice) {
            return;
        }
        try {
            const response = await axiosInstance.post(`admin/assignmentPrice/addAssignmentPrice`, {
                categoryId: data.categoryId,
                subCategoryId: data.subCategoryId,
                minWords: data.minWords,
                maxWords: data.maxWords,
                hours: data.hours,
                assignmentPrice: data.assignmentPrice,
            });
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/assignment-price");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.put(`admin/assignmentPrice/updateAssignmentPrice/${editData?._id}`, {
                categoryId: data.categoryId,
                subCategoryId: data.subCategoryId,
                minWords: data.minWords,
                maxWords: data.maxWords,
                hours: data.hours,
                assignmentPrice: data.assignmentPrice,
            });
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/assignment-price");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    const fetchCategoryData = async (page = 1) => {
        try {
            const response = await axiosInstance.get(
                `admin/category/getAllCategory`,
            );
            const { data } = response.data;
            const filteredCategoryData = data.filter(category => category.name === "Assignment Help");
            setCategoryData(filteredCategoryData);
        } catch (error) {
            console.error(error);
            toast.error('Something went wrong. Please try again!!');
        }
    };

    useEffect(() => {
        fetchCategoryData();
    }, []);


    return (
        <div>
            <div className="mt-4 mb-4">
                <div className='row'>
                    <div className='col-sm-6'>
                        <h4 className="page-title">Add Assignment Price</h4>
                    </div>
                </div>
                <Card className='mt-4'>
                    <CardBody>
                        <Row>
                            <Col md="8">
                                <form className="ps-5 pe-5 pt-3" onSubmit={editData?._id ? handleUpdate : handleSubmit}>
                                    <div className="form-group row mb-2">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Category
                                        </Label>
                                        <div className="col-sm-8">
                                            <FormGroup>
                                                <Input
                                                    id="exampleSelect"
                                                    name="categoryId"
                                                    type="select"
                                                    invalid={!editData && !data.categoryId && formSubmitted}
                                                    defaultValue={data.categoryId}
                                                    onChange={(e) => handleChange(e)} >
                                                    <option value="">
                                                        {editData ? (editData?.categoryId._id === data.categoryId ? categoryData.find(subcat => subcat._id === data.categoryId)?.name : '--- Select category ---') : '--- Select category ---'}
                                                    </option>
                                                    {categoryData?.map(cat => (
                                                        <option key={cat._id} value={cat._id}>{cat.name}</option>
                                                    ))}
                                                </Input>
                                                <FormFeedback>Category is required</FormFeedback>
                                            </FormGroup>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-2">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Sub Category
                                        </Label>
                                        <div className="col-sm-8">
                                            <FormGroup>
                                                <Input
                                                    id="exampleSelect"
                                                    name="subCategoryId"
                                                    type="select"
                                                    invalid={!data.subCategoryId && formSubmitted}
                                                    defaultValue={editData ? editData?.subCategoryId._id : ''}
                                                    onChange={(e) => handleChange(e)}
                                                >
                                                    <option value="">
                                                        {
                                                            data.subCategoryId ? editData?.subCategoryId.name : '--- Select subcategory ---'
                                                        }
                                                    </option>
                                                    {subCategory?.map(subcat => (
                                                        <option key={subcat._id} value={subcat._id}>{subcat.name}</option>
                                                    ))}
                                                </Input>
                                                <FormFeedback>Sub category is required</FormFeedback>
                                            </FormGroup>
                                        </div>
                                    </div>


                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Minimum Words
                                        </Label>
                                        <div className="col-sm-8">
                                            <Input
                                                type="number"
                                                className="form-control"
                                                id="inputPassword"
                                                placeholder="Enter your minimum word  "
                                                invalid={!editData && !data.minWords && formSubmitted}
                                                name='minWords'
                                                value={data.minWords}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <FormFeedback>Minimum word is required</FormFeedback>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Maximum Words
                                        </Label>
                                        <div className="col-sm-8">
                                            <Input
                                                type="number"
                                                className="form-control"
                                                id="inputPassword"
                                                placeholder="Enter your maximum word  "
                                                invalid={!editData && !data.maxWords && formSubmitted}
                                                name='maxWords'
                                                value={data.maxWords}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <FormFeedback>Maximun word is required</FormFeedback>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Hours
                                        </Label>
                                        <div className="col-sm-8">
                                            <Input
                                                type="number"
                                                className="form-control"
                                                id="inputPassword"
                                                placeholder="Enter your hours  "
                                                invalid={!editData && !data.hours && formSubmitted}
                                                name='hours'
                                                value={data.hours}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <FormFeedback>Hours word is required</FormFeedback>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            Assignment price
                                        </Label>
                                        <div className="col-sm-8">
                                            <Input
                                                type="number"
                                                className="form-control"
                                                id="inputPassword"
                                                placeholder="Enter your assignment price "
                                                invalid={!editData && !data.assignmentPrice && formSubmitted}
                                                name='assignmentPrice'
                                                value={data.assignmentPrice}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <FormFeedback>Assignment price is required</FormFeedback>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <Label for="inputPassword" className="col-sm-4 col-form-label">
                                            <b className="ms-2"></b>
                                        </Label>
                                        <div className="col-sm-7 d-flex">
                                            <div className={`${Styles.submit_button}`}>
                                                <Button type='submit'>{editData?._id ? "Update" : "Save"}</Button>
                                            </div>
                                            <div className={`${Styles.cancel_button} me-4 mx-2`}>
                                                <Link to='/assignment-price'> <Button className={`${Styles.cancel_button}`}>Cancel</Button></Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default AddAssignmentPrice;